import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  WIFormDropdownV2,
  WIFormInputTextV2,
  WIFormSelectButtonV2,
} from "../../../../components_v2/common/form-fields";
import { useLayoutV2 } from "../../../../context/LayoutProvider";
import { showNotification, formatDate } from "../../../../utils/logic";
import {
  CampaignCouponStatus,
  CurrencyCoupons,
  TypeCoupons,
} from "../../../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import _ from "lodash";
import { Calendar } from "primereact/calendar";
import "./create-coupon-form.scss";
import WIFormMultiSelectV2 from "../../../../components_v2/common/form-fields/wi-form-multiselect";

const CreateCouponForm = (props: any) => {
  const { onHide, coupon, campaigns, addCoupon, editCoupon, toast, coupons } =
    props;
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation("language", { keyPrefix: "partner_details" });
  const { t: globalTrans } = useTranslation("language");
  const { t: errorTrans } = useTranslation("language", { keyPrefix: "errors" });
  const {
    turnOffProgress,
    setLoadingProgress,
    setErrorProgress,
    setSuccessProgress,
  } = useLayoutV2();

  const onSubmit = async () => {
    console.log("onSubmit >>>", formikRef.current.values);
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && formikRef.current.dirty) {
      console.log("dirty >>>", formikRef.current.dirty);
      try {
        const dataCoupon = {
          // @ts-ignore: Object is possibly 'null'.
          ...formikRef?.current.values,
        };
        console.log("dataCoupon1 >>>", dataCoupon);

        // @ts-ignore: Object is possibly 'null'.
        dataCoupon.start_date = formatDate(
          formikRef?.current.values.start_date
        );
        // @ts-ignore: Object is possibly 'null'.
        dataCoupon.end_date = formatDate(formikRef?.current.values.end_date);

        console.log("dataCoupon >>>", dataCoupon);

        if (
          moment(dataCoupon.end_date).isBefore(moment(dataCoupon.start_date))
        ) {
          showNotification(
            "error",
            "End date must be later than start date",
            toast
          );
          return;
        }

        if (checkDuplicateCoupon(dataCoupon.coupon, dataCoupon.uuid) === true) {
          showNotification("error", "Coupon already exists", toast);
          return;
        }

        if (coupon) {
          editCoupon(dataCoupon);
        } else {
          addCoupon(dataCoupon);
        }
        onHide();
      } catch (error) {
        // showNotification("error", "Generate failed", toast);
      }
    }
  };

  const checkDuplicateCoupon = (coupon: string, uuid: any) => {
    return coupons.some(
      (c: any) =>
        c.coupon.toLowerCase().trim() === coupon.toLowerCase().trim() &&
        c.uuid !== uuid
    );
  };

  const donationTypes = [
    { uuid: "1", label: "Once" },
    { uuid: "2", label: "Subscription" }
  ];

  const COUPON_DEFAULT_VALUE = {
    uuid: uuidv4(),
    coupon: "",
    campaignId: "",
    couponType: TypeCoupons[0].code,
    receiptStatus: "",
    couponStatus: CampaignCouponStatus[0].key,
    start_date: moment().isValid() ? moment().toDate() : new Date(),
    end_date: moment().isValid() ? moment().toDate() : new Date(),
    currency: CurrencyCoupons[0].code,
    budget: 0,
    limit: 0,
  };

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required("This field is required."),
    campaignId: Yup.string().required("This field is required."),
    currency: Yup.string().required("This field is required."),
    couponStatus: Yup.string().required("This field is required."),
    limit: Yup.string().required("This field is required."),
    budget: Yup.string().required("This field is required."),
    start_date: Yup.date().required("This field is required."),
    end_date: Yup.date()
      .required("This field is required.")
      .min(Yup.ref("start_date"), "End date must be later than start date"),
  });

  const initialFormValues = () => {
    let initialCoupon = COUPON_DEFAULT_VALUE;
    if (coupon) {
      initialCoupon = _.pick(coupon, [
        "uuid",
        "coupon",
        "campaignId",
        "couponType",
        "receiptStatus",
        "couponStatus",
        "currency",
        "budget",
        "limit",
        "start_date",
        "end_date",
      ]);
      initialCoupon.start_date = moment(coupon.start_date).toDate();
      initialCoupon.end_date = moment(coupon.end_date).toDate();
    }

    return initialCoupon;
  };

  const onKeyPress = (evt: any) => {
    return (
      ["+", "-", ".", "{", "}", "[", "]"].includes(evt.key) &&
      evt.preventDefault()
    );
  };

  const commonSelectedItemsTemplate = (option: any) => {
    const selectedItem = donationTypes.find((r: any) => r.uuid === option);
    if (selectedItem) {
      return (
          <span className="wi-tag-role mr-8">{selectedItem?.label ? globalTrans(selectedItem?.label) : ''} </span>
      );
    }
    return <></>;
  };
  
  const commonItemTemplate = (option: any) => {
    return (
        <div>{globalTrans(option?.label)} </div>
    );
  };

  return (
    <div className="create-coupon-form">
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          //console.log("values >>>", values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormInputTextV2
                    title="Coupon"
                    name="coupon"
                    isRequired={true}
                    onChange={(e: any) => {
                      const regex = /^[a-zA-Z0-9]*$/;
                      if (regex.test(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormDropdownV2
                    name="campaignId"
                    title="Campaign"
                    isRequired={true}
                    optionLabel="name.de"
                    optionValue="uuid"
                    appendTo="self"
                    options={campaigns}
                  />
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormMultiSelectV2
                    name="donationType"
                    title="Donation type"
                    isRequired={true}
                    options={donationTypes}
                    optionLabel={`label`}
                    optionValue="uuid"
                    appendTo="self"
                    selectedItemTemplate={commonSelectedItemsTemplate}
                    itemTemplate={commonItemTemplate}
                  />
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-6">
                  <WIFormSelectButtonV2
                    title="Currency"
                    name="currency"
                    isRequired={true}
                    options={CurrencyCoupons}
                    onChange={(item: any) => {
                      if (item.value) {
                        setFieldValue("currency", item.value, false);
                      }
                    }}
                    optionLabel="name"
                    optionValue="code"
                  />
                </div>
                <div className="col-md-6">
                  <WIFormSelectButtonV2
                    title="Coupon Type"
                    name="couponType"
                    isRequired={true}
                    options={TypeCoupons}
                    style={{ width: "auto" }}
                    onChange={(item: any) => {
                      if (item.value) {
                        setFieldValue("couponType", item.value, false);
                      }
                    }}
                    optionLabel="name"
                    optionValue="code"
                  />
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-6">
                  <WIFormInputTextV2
                    title="Budget"
                    name="budget"
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <WIFormInputTextV2
                    title="Limit"
                    name="limit"
                    isRequired={true}
                  />
                </div>
              </div>

              <div className="row mb-24">
                <div className="col-md-6">
                  <div className="element-form">
                    <label className="label mb-12">
                      Start date <span className="required-label ml-4">*</span>
                    </label>
                    <Calendar
                      id="basic"
                      name="start_date"
                      panelClassName="expire-calendar"
                      value={values.start_date}
                      onBlur={handleBlur}
                      onChange={(item) => {
                        setFieldValue("start_date", item.value, false);
                      }}
                      dateFormat="dd.mm.yy"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="element-form">
                    <label className="label mb-12">
                      End date <span className="required-label ml-4">*</span>
                    </label>
                    <Calendar
                      id="basic"
                      name="end_date"
                      panelClassName="expire-calendar"
                      value={values.end_date}
                      onBlur={handleBlur}
                      onChange={(item) => {
                        setFieldValue("end_date", item.value, false);
                      }}
                      dateFormat="dd.mm.yy"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <Button
                      className="wi-danger-button-v2 mr-16 h48 flex-1"
                      type="submit"
                      label={`${t("txt_cancel")}`}
                      onClick={() => onHide()}
                    />
                    <Button
                      className="wi-primary-button-v2 ml-16 h48 flex-1"
                      type="submit"
                      label={`${t("txt_continue")}`}
                      onClick={() => onSubmit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCouponForm;
