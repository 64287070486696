import React from "react";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from "formik";

const WIFormInputTextV2 = (props: any) => {
  const { name, title, isRequired, disabled, maxLength, onKeyDown } = props;
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title}{" "}
        {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <InputText
        className={`${_.get(errors, name) && _.get(touched, name) ? "has-error" : ""}`}
        type="text"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={_.get(values, name) || ''}
        maxLength={maxLength}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      <div className={`${_.get(errors, name) && _.get(touched, name) ? "error" : ""}`}>
        {_.get(touched, name) && _.get(errors, name)}
      </div>
    </div>
  )
}

export default WIFormInputTextV2;