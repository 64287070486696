import UsersManager from "../pages/users-manager/users-manager";
import PageComponent from '../components/common/page';
import React from "react";
import { PERMISSIONS } from "../components_v2/utils/utils";
import DonationManager from "../components/donation-manager/donation-manager";
import RegionManager from "../components/region/region-manager/region-manager";
import UserDetails, { ProfileDetails } from "../pages/user-detail/user-detail";
import DonationDetail from "../components/donation-manager/components/donation-detail/donation-detail";
import ImportPage from "../components/import-page/import-page";
import ImportDetail from "../components/import-detail/import-detail";
import EditDonationDetail from "../components/donation-manager/components/edit-donation-detail/edit-donation-detail";
import EditDonationDetailV2 from "../components/donation-manager/components/edit-donation-detail/edit-donation-detail-v2";
import SubscriptionsManager from "../components/subscription/subscription-component";
import RecurringYearlyManager from "../components/recurring-yearly-manager/recurring-yearly-manager";
import CreateRegionManager from "../components/region/components/create-region-component";
import DonorManager from "../components/donor-manager/donor-manager";
import LandManagerComponent from "../components/land-manager/land-manager";
import CreateLandManager from "../components/land-manager/components/create-land-manager";
import CreateCampaignManager from "../components/campaign/components/create-campaign-component";
import CampaignManager from "../components/campaign/campaign-manager/campaign-manager";
import PartnerManagerV2 from "../pages/partner-manager";
import CreatePartnerManager from "../components/partner-manager/components/create-partner-manager";
import CreateCouponManager from "../components/coupon-manager/components/create-coupon-manager";
import CouponManagerComponent from "../components/coupon-manager/coupon-manager";
import CouponDetailPage from "../components/coupon-detail/coupon-detail";
import PageManager from "../components/pages/page-manager/page-manager";
import BuilderPage from '../components/pages/builder-page';
import PageDetails from '../components/pages/page-details';
import MediaManager from "../components/global-contents/media-manager/media-manager";
import RunnersDetails from "../components/runners-details/runners-details";
import TeamsDetails from "../components/teams-details/teams-details";
import WildernessRunDetails from "../components/wilderness-run-details/wilderness-run-details";
import WildernessRunManager from "../components/wilderness-run-manager/wilderness-run-manager";
import ReceiptManager from "../components/receipts/receipt-manager";
import GoalManager from "../components/goal-manager/goal-manager";
import CouponManager from "../components/campaign/coupon-manager/coupon-manager";
import FAQsManager from "../components/global-contents/faqs/faqs-manager";
import HeadersManager from "../components/global-contents/header-manager/headers-manager";
import QuoteManager from "../components/global-contents/quote-manager/quote-manager";
import CreateContactManager from "../components/global-contents/contact/components/create-contact-manager";
import ContactCardManager from "../components/global-contents/contact/contact-manager";
import GlobalBlockManager from "../components/global-contents/global-block/global-block";
import CMSPagePreview from "../components/pages/cms-page-preview/preview-page";
import AcceptTermPage from "../components/login/accept-term/accept-term";
import FootersManager from "../components/global-contents/footer-manager/footers-manager";
import DonationExamplesManager from "../components/global-contents/donation-example/donation-examples-manager";
import CampaignDetails from "../pages/campaign-details";

export const AdminRoutes = [
    {
        path: "/users", 
        element: <PageComponent component={UsersManager} title="Users" />,
        allowedPermissions: [PERMISSIONS.USER_READ, PERMISSIONS.USER_WRITE]
    },
    {
        path: "/users/:id", 
        element: <PageComponent component={UserDetails} title="User Details" />,
        allowedPermissions: [PERMISSIONS.USER_READ, PERMISSIONS.USER_WRITE]
    },
    {
        path: "/donors", 
        element: <PageComponent component={DonorManager} title="Donors" />,
        allowedPermissions: [PERMISSIONS.DONOR_READ, PERMISSIONS.DONOR_WRITE]
    },
    {
        path: "/donations", 
        element: <PageComponent component={DonationManager} title="Donations" />,
        allowedPermissions: [PERMISSIONS.DONATION_READ, PERMISSIONS.DONATION_WRITE, PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/donations/:id", 
        element: <PageComponent component={DonationDetail} title="Donation Details" />,
        allowedPermissions: [PERMISSIONS.DONATION_READ, PERMISSIONS.DONATION_WRITE, PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/edit-donations/:id", 
        element: <PageComponent component={EditDonationDetailV2} title="Donations" />,
        allowedPermissions: [PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/edit-donation/:id", 
        element: <PageComponent component={EditDonationDetail} title="Donations" />,
        allowedPermissions: [PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/import", 
        element: <PageComponent component={ImportPage} title="Import Donations" />,
        allowedPermissions: [PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/import-detail/:id", 
        element: <PageComponent component={ImportDetail} title="Import Details" />,
        allowedPermissions: [PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/subscriptions", 
        element: <PageComponent component={SubscriptionsManager} title="Subscriptions" />,
        allowedPermissions: [PERMISSIONS.SUBSCRIPTION_READ, PERMISSIONS.SUBSCRIPTION_WRITE]
    },
    {
        path: "/subscriptions/geo", 
        element: <PageComponent component={RecurringYearlyManager} title="Subscriptions" />,
        allowedPermissions: [PERMISSIONS.SUBSCRIPTION_WRITE]
    },
    {
        path: "/regions", 
        element: <PageComponent component={RegionManager} title="Regions" />,
        allowedPermissions: [PERMISSIONS.REGION_READ, PERMISSIONS.REGION_WRITE]
    },
    {
        path: "/regions/create", 
        element: <PageComponent component={CreateRegionManager} title="Create Region" />,
        allowedPermissions: [PERMISSIONS.REGION_WRITE]
    },
    {
        path: "/regions/:id", 
        element: <PageComponent component={CreateRegionManager} title="Region Details" />,
        allowedPermissions: [PERMISSIONS.REGION_READ, PERMISSIONS.REGION_WRITE]
    },
    {
        path: "/areas", 
        element: <PageComponent component={LandManagerComponent} title="Areas" />,
        allowedPermissions: [PERMISSIONS.AREA_READ, PERMISSIONS.AREA_WRITE]
    },
    {
        path: "/areas/create", 
        element: <PageComponent component={CreateLandManager} title="Create Area" />,
        allowedPermissions: [PERMISSIONS.AREA_WRITE]
    },
    {
        path: "/areas/:id", 
        element: <PageComponent component={CreateLandManager} title="Area Details" />,
        allowedPermissions: [PERMISSIONS.AREA_READ, PERMISSIONS.AREA_WRITE]
    },
    {
        path: "/partners", 
        element: <PageComponent component={PartnerManagerV2} title="Partners" />,
        allowedPermissions: [PERMISSIONS.PARTNER_READ, PERMISSIONS.PARTNER_WRITE]
    },
    {
        path: "/partners/create", 
        element: <PageComponent component={CreatePartnerManager} title="Create Partner" />,
        allowedPermissions: [PERMISSIONS.PARTNER_WRITE]
    },
    {
        path: "/partners/:id", 
        element: <PageComponent component={CreatePartnerManager} title="Partner Details" />,
        allowedPermissions: [PERMISSIONS.PARTNER_READ, PERMISSIONS.PARTNER_WRITE]
    },
    {
        path: "/codes", 
        element: <PageComponent component={CouponManagerComponent} title="Code Batches" />,
        allowedPermissions: [PERMISSIONS.CODE_READ, PERMISSIONS.CODE_WRITE]
    },
    {
        path: "/codes/create", 
        element: <PageComponent component={CreateCouponManager} title="Create Code Batch" />,
        allowedPermissions: [PERMISSIONS.CODE_WRITE]
    },
    {
        path: "/codes/:id", 
        element: <PageComponent component={CouponDetailPage} title="Code Batch Details" />,
        allowedPermissions: [PERMISSIONS.CODE_READ, PERMISSIONS.CODE_WRITE]
    },
    {
        path: "/wilderness-runs", 
        element: <PageComponent component={WildernessRunManager} title="Wilderness Runs" />,
        allowedPermissions: [PERMISSIONS.WI_RUN_READ, PERMISSIONS.WI_RUN_WRITE]
    },
    {
        path: "/wilderness-runs/:id", 
        element: <PageComponent component={WildernessRunDetails} title="Wilderness Run Details" />,
        allowedPermissions: [PERMISSIONS.WI_RUN_READ, PERMISSIONS.WI_RUN_WRITE]
    },
    {
        path: "/wilderness-runs/:runId/teams/:id", 
        element: <PageComponent component={TeamsDetails} title="Wilderness Run Team Details" />,
        allowedPermissions: [PERMISSIONS.WI_RUN_READ, PERMISSIONS.WI_RUN_WRITE]
    },
    {
        path: "/wilderness-runs/:runId/teams/:teamId/runners/:id", 
        element: <PageComponent component={RunnersDetails} title="Wilderness Runner Details" />,
        allowedPermissions: [PERMISSIONS.WI_RUN_READ, PERMISSIONS.WI_RUN_WRITE]
    },
    {
        path: "/utilities/manual-receipts", 
        element: <PageComponent component={ReceiptManager} title="Manual Receipts" />,
        allowedPermissions: [PERMISSIONS.DONATION_READ, PERMISSIONS.DONATION_WRITE, PERMISSIONS.DONATION_ADVANCED]
    },
    {
        path: "/utilities/targets", 
        element: <PageComponent component={GoalManager} title="Targets" />,
        allowedPermissions: [PERMISSIONS.GOAL_READ, PERMISSIONS.GOAL_WRITE]
    },
    {
        path: "/coupons", 
        element: <PageComponent component={CouponManager} title="Coupons" />,
        allowedPermissions: [PERMISSIONS.COUPON_READ, PERMISSIONS.COUPON_WRITE]
    },
    {
        path: "/global-contents/faqs", 
        element: <PageComponent component={FAQsManager} title="FAQs" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/cms-headers", 
        element: <PageComponent component={HeadersManager} title="CMS Headers" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/cms-footers", 
        element: <PageComponent component={FootersManager} title="CMS Footers" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/cms-quotes", 
        element: <PageComponent component={QuoteManager} title="CMS Quotes" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/donation-examples", 
        element: <PageComponent component={DonationExamplesManager} title="Donation Examples" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/people", 
        element: <PageComponent component={ContactCardManager} title="People" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/people/create", 
        element: <PageComponent component={CreateContactManager} title="Create Person" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/people/:id", 
        element: <PageComponent component={CreateContactManager} title="Person Details" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_WRITE]
    },
    {
        path: "/global-contents/cms-global-blocks", 
        element: <PageComponent component={GlobalBlockManager} title="CMS Global Blocks" />,
        allowedPermissions: [PERMISSIONS.GLOBAL_BLOCK_READ, PERMISSIONS.GLOBAL_BLOCK_WRITE]
    },
];

export const CommonRoutes = [
    {
        path: "/terms-and-conditions", 
        element: <PageComponent component={AcceptTermPage} title="Terms and Conditions" />,
        allowedPermissions: []
    },
    {
        path: "/profile/settings", 
        element: <PageComponent component={ProfileDetails} title="User Profile" />,
        allowedPermissions: []
    },
    {
        path: "/campaigns", 
        element: <PageComponent component={CampaignManager} title="Campaigns" />,
        allowedPermissions: [PERMISSIONS.CAMPAIGN_READ, PERMISSIONS.CAMPAIGN_WRITE]
    },
    {
        path: "/campaigns/create", 
        element: <PageComponent component={CreateCampaignManager} title="Create Campaign" />,
        allowedPermissions: [PERMISSIONS.CAMPAIGN_WRITE]
    },
    {
        path: "/campaigns/:id", 
        element: <PageComponent component={CreateCampaignManager} title="Campaign Details" />,
        allowedPermissions: [PERMISSIONS.CAMPAIGN_READ, PERMISSIONS.CAMPAIGN_WRITE]
    },
    {
        path: "/cms-pages", 
        element: <PageComponent component={PageManager} title="CMS Pages" />,
        allowedPermissions: [PERMISSIONS.CMS_PAGE_READ, PERMISSIONS.CMS_PAGE_WRITE]
    },
    {
        path: "/cms-pages/create", 
        element: <PageComponent component={PageDetails} title="Create CMS Page" />,
        allowedPermissions: [PERMISSIONS.CMS_PAGE_WRITE]
    },
    {
        path: "/cms-pages/:id", 
        element: <PageComponent component={PageDetails} title="CMS Page Details" />,
        allowedPermissions: [PERMISSIONS.CMS_PAGE_READ, PERMISSIONS.CMS_PAGE_WRITE]
    },
    {
        path: "/cms-pages/:id/content", 
        element: <PageComponent component={BuilderPage} title="CMS Page Content" />,
        allowedPermissions: [PERMISSIONS.CMS_PAGE_WRITE]
    },
    {
        path: "/cms-pages/:id/preview", 
        element: <PageComponent component={CMSPagePreview} title="CMS Page Content" />,
        allowedPermissions: [PERMISSIONS.CMS_PAGE_READ, PERMISSIONS.CMS_PAGE_WRITE]
    },
    {
        path: "/media", 
        element: <PageComponent component={MediaManager} title="Media" />,
        allowedPermissions: [PERMISSIONS.MEDIA_READ, PERMISSIONS.MEDIA_WRITE]
    }
];