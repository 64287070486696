import * as React from "react";
import moment from "moment";
import * as _ from 'lodash';
import { Tag } from "primereact/tag";
import { DONATION_STATUS_LIST, FOOTERS, HEADERS, MAILING_STATUS, TypeLandCode } from "../../../utils/utils";
import { ProgressBar } from "primereact/progressbar";
import { formatCurrency, onNavigateToByURL } from "../../../utils/logic";
import { Tooltip } from "primereact/tooltip";
import './column-template.scss';
import { Link } from "react-router-dom";

export const customMenuItemsDot = (item: any, options: any) => {
  const buildChild = (item: any, options: any) => <>
    <span className={options.iconClassName}></span>
    <div className="p-menuitem-containers">
      <span className={`${options.labelClassName} headline`}>{item.label}</span>
      {
        item.description && (<>
          <span className={`${options.labelClassName} description`}>{item.description}</span>
        </>)
      }
    </div>
  </>;

  if (item.url) {
    return <>
      <Link to={item.url} className={"p-menuitem-link wi-menuitem-3-dot" + (item.disabled ? ' p-disabled' : '')} role="menuitem" onClick={(e) => options.onClick(e)}>
        {buildChild(item, options)}
      </Link>
    </>
  } else {
    const propList = item.externalUrl ? {href: item.externalUrl} : {onClick: (e: any) => options.onClick(e)};
    return <>
      <a data-testid="menudotItem" {...propList} className={`p-menuitem-link wi-menuitem-3-dot ${item.dangerous ? 'color-red-warning' : ''} ${item.disabled ? ' p-disabled' : ''}`} role="menuitem" >
       { buildChild(item, options)}
      </a>
    </>
  }
}

export const formatDateBodyTemplate = (formatDate: string, lang = 'de') => (rowData: any, column: any) => {
  return rowData[column.field] ? moment(new Date(rowData[column.field]), 'MMM DD, YYYY HH:mm:ss').locale(lang).utc().add(1, 'hour').format(formatDate || "MMM DD, YYYY HH:mm:ss") : '';
};

export const formatDateByFormatBodyTemplate = (formatDate: string) => (rowData: any, column: any) => {
  return rowData[column.field] ? moment(new Date(rowData[column.field]), formatDate).utc().add(1, 'hour').format(formatDate) : '-';
};

export const formatDateForBodyTemplate = (date: any, format: any) => {
  return moment(new Date(date), format).utc().add(1, 'hour').format(format);
}

export const formatInnerHtmlDataByLanguageBodyTemplate = (lang: any = "", partnerTypes: any = []) => ( rowData: any, column: any ) => {
  let filterPartner;
  if (partnerTypes.length > 0) {
    filterPartner = partnerTypes?.find((partner: any) => partner.uuid === rowData[column.field])?.name;
  }
  if(rowData[column.field]) {
    const value = filterPartner || rowData[column.field];
    return (
      <div
        className="wi-html-content"
          dangerouslySetInnerHTML={{
          __html: !lang || _.isString(value) ? value : value[lang.code.toLowerCase()],
          }}
      >
      </div>
    );
  }
  return;
};

export const formatLogoBodyTemplate = (url: any) => (rowData: any, column: any) => {
  const fieldCol = rowData[column.field];
  if (fieldCol) {
    return (
      <img
        src={`${url}/${
          fieldCol
        }?u=${rowData.updated_at}`}
        alt={fieldCol}
        className="logo-image"
      />
    );
  } else {
    if (column.field === "profile_picture") {
     return (<img  src={`/contact.jpeg`}  alt={"Image Not Found"}  className="logo-image" />)
    } else {
      return (<img src={`/image-not-found.png`} alt={"Image Not Found"} className="logo-image not-found" />)
    }
  }
};

export const formatStatusBodyTemplate = (statusDisplay: any) => (rowData: any, column: any) => {
  const status = _.isString(rowData[column.field]) ? rowData[column.field] : rowData[column.field] ? 'deactivate' : 'active';
  const value = status;

  try {
    return (
    <>
    {
      status ? 
      <React.Fragment>
        <Tag
          className={`mr-2 wi-tag tag-status-${status.toLowerCase()}`}
          // @ts-ignore
          icon={statusDisplay[status]?.icon}
          // @ts-ignore
          severity={statusDisplay[status]?.severity}
          value={value}
        />
      </React.Fragment> : ''
    }
    </>
      
    );
  } catch (error) {
    return (
      <React.Fragment>
        <Tag
          className="mr-2"
          icon="pi pi-check"
          severity="success"
          value={value}
        />
      </React.Fragment>
    );
  }
}


export const formatDonationStatusBodyTemplate = (statusDisplay: any) => (rowData: any, column: any) => {
  const status = _.isString(rowData[column.field]) ? rowData[column.field] : rowData[column.field] ? 'deactivate' : 'active';
  const value = DONATION_STATUS_LIST.find(c => c.code === status.toLowerCase())?.name.de || status;

  try {
    return (
      <React.Fragment>
        <Tag
          className={`mr-2 wi-tag tag-status-${status.toLowerCase()}`}
          // @ts-ignore
          icon={statusDisplay[status]?.icon}
          // @ts-ignore
          severity={statusDisplay[status]?.severity}
          value={value}
        />
      </React.Fragment>
    );
  } catch (error) {
    return (
      <React.Fragment>
        <Tag
          className="mr-2"
          icon="pi pi-check"
          severity="success"
          value={value}
        />
      </React.Fragment>
    );
  }
}

export const formatMailStatusBodyTemplate = (statusDisplay: any) => (rowData: any, column: any) => {
  const status = _.isString(rowData[column.field]) ? rowData[column.field] : 'not_yet';
  const value = MAILING_STATUS.find(c => c.code === status.toLowerCase())?.name.de || status;

  try {
    return (
      <React.Fragment>
        <Tag
          className={`mr-2 wi-tag tag-status-${status.toLowerCase()}`}
          // @ts-ignore
          icon={statusDisplay[status]?.icon}
          // @ts-ignore
          severity={statusDisplay[status]?.severity}
          value={value}
        />
      </React.Fragment>
    );
  } catch (error) {
    return (
      <React.Fragment>
        <Tag
          className="mr-2"
          icon="pi pi-check"
          severity="warning"
          value={value}
        />
      </React.Fragment>
    );
  }
}

export const formatNameBodyTemplate = (blockName: string) => (rowData: any, column: any) => {
  const enumBlock = blockName === 'footer' ? FOOTERS : HEADERS;
  return (
    <React.Fragment>
      <div className="row">
        <span className="image-text px-2">{_.isString(rowData[column.field]) ? rowData[column.field] : rowData.is_primary ? enumBlock.PRIMARY.value : enumBlock.SECONDARY.value}</span>
      </div>
    </React.Fragment>
  );
};

export const buildCodeBodyTemplate = (couponData: any) => (rowData: any) => ( 
  `${couponData.prefix_coupon}-${rowData.code}`
);

export const buildProgressTemplate = (fieldName: string, fieldTotal: string, fieldCal: string) => (rowData: any, column: any) => {
  const total = parseInt(rowData[fieldTotal]);
  const calculated = fieldName === "calculator" ? total - parseInt(rowData[fieldCal]) : rowData[fieldCal];
  const value = 100 * calculated / total;

  return (
    <div style={{ width: '100%' }}>
      <ProgressBar value={value} displayValueTemplate={displayValueTemplate(total, calculated)}></ProgressBar>
    </div>
  )
}

export const formatCurrencyBodyTemplate = (value: any, unit: string) => (rowData: any) => {
  return (
    <React.Fragment>
        {value ? formatCurrency(rowData[value]) : ""} {unit ? rowData[unit].toUpperCase() : ""}
    </React.Fragment>
  );
};

const displayValueTemplate = (total: number, value: number) => () => {
  return (
      <React.Fragment>
          {(value || 0).toLocaleString("de")} / {(total || 0).toLocaleString("de")}
      </React.Fragment>
  );
}

const displayCouponValueTemplate = (total: number, value: number) => () => {
  return (
    <React.Fragment>
      <>
        <span className="label-value">
          {(value || 0).toLocaleString("de")} / {(total || 0).toLocaleString("de")}
        </span>
      </>
    </React.Fragment>
  );
}

export const buildNumberBodyTemplate = (rowData: any, column: any, lang = 'de') => {
  return (
    <React.Fragment>
      {parseInt(rowData[column.field]?.toString()) ? parseInt(rowData[column.field]?.toString()).toLocaleString("de") : '0' || 0}
    </React.Fragment>
  )
}

export const formatNumberColV2 = (data: any, lang = 'de') => {
  return (
    <>
      {data ? parseInt(data?.toString()).toLocaleString(lang) : '0' || 0}
    </>
  )
}

export const buildNumberTemplate = (rowData: any, column: any, lang = 'en') => {
  return (
    <React.Fragment>
      {parseFloat(rowData[column.field]?.toString()) ? parseFloat(rowData[column.field]?.toString()).toLocaleString(lang) : '0' || 0}
    </React.Fragment>
  )
}

export const formatTypeBodyTemplate = (lang: any, pageTypes: any = []) => (rowData: any, column: any) => {
  let type = rowData[column.field];
  let pageType = pageTypes.find((f: any) => f.code.toLocaleLowerCase() === type.toLocaleLowerCase())?.name;
  return pageType[lang.code.toLowerCase()];
};

export const formatNamePageTemplate = (rowData: any, column: any) => {
  return (
    <React.Fragment>
        <div className={"page-name-wrapper" + (rowData.special_type && ` special-type`)}>{rowData[column.field]}</div>
    </React.Fragment>
  );
}

 export const buildActionBtnTemplate = (rowData: any, listBtn: any, toast?: any, displayBtn: any = true, disable: any = false) => {
  
  return (
    <React.Fragment>
      { 
        listBtn.length > 0
        ? 
          displayBtn && ( listBtn.map((item: any, index: number) => 
            <div key={index} className="btn-action">
              {
                item.btnFunc ?
                  <i className={`${item.className} ${disable ? "disabled" : "" }`} onClick={(e) => item.btnFunc(e, rowData, toast)}></i>
                : 
                <i className={`${item.className}`}></i>
              }
            </div>
          ))
        : <></>
      }
    </React.Fragment>
  );
}

export const buildParseJsonBodyTemplate = (lang: any) => (rowData: any, column: any) => JSON.parse(rowData[column.field])[lang.code.toLowerCase()];

export const buildBooleanTemplate = (rowData: any, column: any) => rowData[column.field] || rowData[column.field] === '1' ? "Yes" : "No";

export const buildDefaultBodyTemplate = (rowData: any, column: any) => rowData[column.field];

export const buildCountryBodyTemplate = (countries: any) => (rowData: any, column: any) => {
  if(rowData[column.field]) {
      const country: any = countries.find((countryItem: any) => countryItem.ISO2 === rowData[column.field]);
      return country?.name || rowData[column.field]
  }
}

export const buildTooltipManageTemplate = (title: string, label: string, tooltipLabel: string, url: string, isDisabled = false, isHideNavigateButton = false) => {
  return (
    <div className="title-content">
      {label ? ( <>
        <Tooltip target=".fa-arrow-up-right-from-square" mouseTrack mouseTrackTop={15} position="bottom">{tooltipLabel}</Tooltip>
        <label className="title-header">{title}:</label>
        <span className="me-3">{label}</span>
        { isHideNavigateButton ? null : <i className={`${ isDisabled ? "has-disable-navigate" : "" } fa-solid fa-arrow-up-right-from-square`}  onClick={() => onNavigateToByURL(url)}></i>}
      </>) : (
        <label className="title-header">New</label>
      )}
    </div>
  )
}

export const buildCouponQuantityTemplate = (rowData: any, column: any) => (parseInt(rowData[column.field]) || 0)?.toLocaleString("de")

export const buildCouponProgressTemplate = (fieldName: string, fieldTotal: string, fieldCal: string) => (rowData: any, column: any) => {
  const total = parseInt(rowData[fieldTotal]);
  const calculated = fieldName === "calculator" ? total - parseInt(rowData[fieldCal]) : rowData[fieldCal];
  const value = 100 * calculated / total;

  return (
    <div style={{ width: '100%' }} className="coupon-progress-bar-field">
      <ProgressBar value={value} displayValueTemplate={displayCouponValueTemplate(total, calculated)}></ProgressBar>
    </div>
  )
}

export const buildCouponStatusColumnTemplate = (rowData: any, column: any) => {
  const status = _.isString(rowData[column.field]) ? rowData[column.field] : rowData[column.field] ? 'finished' : 'active';
  const value = status;

  try {
    return (
      <React.Fragment>
        <Tag
          className={`mr-2 wi-tag tag-status-${status.toLowerCase()}`}
          value={value}
        />
      </React.Fragment>
    );
  } catch (error) {
    return (
      <React.Fragment>
        <Tag
          className="mr-2"
          value={value}
        />
      </React.Fragment>
    );
  }
}

export const buildAreaRefLink = (rowData: any) => {
  return rowData.land_id ? <Link className="table-ref-link-cell" to={`/areas/${rowData.land_id}`}>{rowData.land_code}</Link> : <div>{rowData.land_code}</div>
}

export const buildCampaignRefLink = (rowData: any) => {
  return rowData.campaign_id ? <Link className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>{rowData.campaign_code}</Link> : <div>{rowData.campaign_code}</div>
}

export const formatSourceOptionTemplate = (option: any, available_size: any, key: any = TypeLandCode.campaign) => {
  return (
    <div>
      {
        key === TypeLandCode.region ? 
        <>
          <div className="t-bold">{option?.region?.name?.de}</div>
          <div style={{fontWeight: 500}}>{option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString("de-DE")} m<sup>2</sup>]</div>
        </> : <></>
      }
      {
        key === TypeLandCode.land ? 
        <>
          <div className="t-bold">{option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString("de-DE")} m<sup>2</sup>]</div>
          <div style={{fontWeight: 500}}>{option?.master_region?.name?.de}</div>
        </> : <></>
      }
      {
        key === TypeLandCode.campaign ? 
        <>
          <div className="t-bold">{option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString("de-DE")} m<sup>2</sup>]</div>
          <div style={{fontWeight: 500}}>{option?.master_region?.name?.de} / {option?.land?.name.de || option?.land?.name}</div>
        </> : <></>
      }
    </div>
  );
}