import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useRef, useState } from "react";
import CardItem from "./components/card-item/card-item";
import MediaInfo from "./components/media-details/media-info";
import Directory from "./components/directory/directory";
import "./media-manager.scss";
import { FIELDS_SEARCH, removeEmpty } from "../../../utils/utils";
import { MediaFolderManagerAPI, MediaV2ManagerAPI } from "../../../services";
import { DataScroller } from "primereact/datascroller";
import { UploadMediaDialogComponent } from "./components/upload-media-dialog/upload-media-dialog";
import { Sidebar } from "primereact/sidebar";
import _ from "lodash";
import { env } from "../../../environment";
import {
  getBase64,
  getFileType,
  getImageDimensions,
  getFolderByFolderParentId,
  IMAGE_EXTENSIONS,
  isFileOverSize,
  isFileTypeNotAllowed,
  parseImageMetadata,
  getFileExtension,
  requestBase64StringFromImageURL,
  formatImageFileURL,
  uploadPhysicalFileToS3,
  getCompressedImage,
  getCompressedThumbnail,
  PNG_MAZ_SIZE,
} from "../../../utils/mediaUtils";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ListItem from "./components/list-item/list-item";
import moment from "moment";
import ImageEditorDialogComponent from "./components/image-editor/editor-dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { ReactComponent as UploadSvg } from '../../../assets/images/icons/upload.svg';
import { ReactComponent as WarningSvg } from '../../../assets/images/icons/warning-symbol.svg';
import Layout from "../../../components_v2/common/layout/layout";
import WISearchField from "../../../components_v2/common/search/wi-search-field";
import MenuDot from "../../../components_v2/common/menu-dot/menu-dot";
import WIConfirmDialog from "../../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import useAuth from '../../../context/useAuth';
import { PERMISSIONS_V2 } from '../../../components_v2/utils/utils';

const getFullPath = (selected_folder: any, folders: any[]) => {
  if (!selected_folder?.uuid) {
    return null;
  }

  let folderId = selected_folder.uuid;
  let nameArray = [];
  let i = 0;
  do {
    i++;
    const folder = folders.find((f: any) => f.uuid === folderId);
    if (!folder) {
      break;
    }

    nameArray.unshift(folder.name);
    if (!folder.parentId) {
      break;
    }

    folderId = folder.parentId;
  } while (i < 1000)

  return nameArray.join('/');
}

const MediaManager = () => {
  const { auth } = useAuth();
  const permissions = {
    canCreateMedia: auth?.permissions?.includes(PERMISSIONS_V2.MEDIA_CREATE),
    canUpdateMedia: auth?.permissions?.includes(PERMISSIONS_V2.MEDIA_UPDATE),
    canViewMedia: auth?.permissions?.includes(PERMISSIONS_V2.MEDIA_VIEW)
  };
  
  const rootFolderGuid = '00000000-0000-0000-0000-000000000000';
  const [isLoading, setIsLoading] = useState(false);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isSidebarLoading, setIsSidebarLoading] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

  const [folders, setFolders] = useState<any>(null);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [mediaFiles, setMediaFiles] = useState<any>({
    data: [],
    totalPage: 0,
  });
  const ds = useRef<any>(null);
  const directoryRef = useRef<any>(null);
  const selectedFolder = useRef<any>(null);
  const [search, setSearch] = useState<any>(null);
  const [nodes, setNodes] = useState<any>(null);
  const [uploadActive, setUploadActive] = useState(true);
  const [layout, setLayout] = useState("grid");
  const [isShowEditor, setIsShowEditor] = useState(false);
  const [editingPhoto, setEditingPhoto] = useState<any>(null);
  const [fileBase64, setFileBase64] = useState<any>(null);
  const { t } = useTranslation('language', { keyPrefix: 'media_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();

  const onSearch = () => {
    refresh();
  };

  const deleteMedia = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowDeleteDialog(true);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setIsShowDeleteDialog(isVisible);
  };

  const onCreateMedia = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowCreate(true);
    setSelectedMedia(null);
  };

  const onEditMedia = async (isCreating: boolean, e: any, rowData: any) => {
    e.preventDefault();
    setIsShowCreate(isCreating);
    setSelectedMedia(rowData);
  };

  const itemTemplate = (file: any) => {
    if (layout === "list")
      return <ListItem selectedMedia={selectedMedia} mediaFile={file} onEditMedia={onEditMedia} />;
    else if (layout === "grid")
      return <CardItem selectedMedia={selectedMedia} mediaFile={file} onEditMedia={onEditMedia} />;
  };

  const buildMenu = () => {
    const defaultActions = [
      {
        label: t('txt_select'),
        icon: "wi-double-check",
      },
      {
        label: t('txt_add_folder'),
        icon: "wi-add-folder",
        className: "wi-active",
        command: (e: any) => {
          directoryRef.current?.addFolder()
        },
        hidden: !permissions.canCreateMedia
      },
      {
        label: t('txt_expand_folders'),
        icon: "wi-expand",
        command: (e: any) => {
          directoryRef.current?.expandAll()
        }
      },
      {
        label: t('txt_collapse_folders'),
        icon: "wi-collapse",
        command: (e: any) => {
          directoryRef.current?.collapseAll()
        }
      },
      {
        label: t('txt_delete_folder'),
        icon: "wi-delete-folder",
        className: "wi-danger",
        disabled: uploadActive,
        command: (e: any) => {
          directoryRef.current?.deleteFolder()
        },
        hidden: !permissions.canUpdateMedia
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const fetchMediaByFolderId = async (folder_id: any, valueSearch?: string) => {
    try {
      setIsLoading(true);
      const data = removeEmpty({
        search_text: valueSearch || undefined,
        search_fields: valueSearch ? FIELDS_SEARCH.MEDIA : undefined,
      });

      const response = await MediaV2ManagerAPI.getFilesInsideFolder(folder_id, data);
      if (response && response.status === 200) {
        const mediaData: any = response.data.files || [];
        setMediaFiles({
          data: [...mediaData, ..._.fill(Array(6), {})],
          totalPage: mediaData.total || 0,
        });

        return mediaData;
      }

      return [];
    } catch (error) {
      setMediaFiles({ data: [], totalPage: 0 });
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = () => {
    // @ts-ignore: Object is possibly 'null'.
    if (selectedFolder.current && selectedFolder.current.data !== "Root") {
      const node: any = selectedFolder.current;
      fetchMediaByFolderId(node.uuid, search);
    } else {
      // fetchMediaData(search);
      fetchMediaByFolderId(rootFolderGuid, search);
    }
  };

  useEffect(() => {
    fetchMediaFolder().then(() => refresh());
  }, []);

  useEffect(() => {
    if (selectedMedia && isShowCreate) {
      const foundMedia = mediaFiles.data.find(
        (f: any) => f.uuid === selectedMedia.uuid
      );
      if (foundMedia) {
        const refreshData = _.cloneDeep(foundMedia);
        setSelectedMedia(refreshData);
      }
    }
  }, [mediaFiles]);

  const onDeleteConfirm = async () => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      if (selectedMedia) {
        await MediaV2ManagerAPI.deleteFile(selectedMedia.uuid);
        onShowDeleteDialog(false);
        refresh();
        setSelectedMedia(null);
        setIsShowCreate(false);
        setSuccessProgress(t('txt_delete_file_success'));
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message || 'txt_delete_file_fail'));
    }
  };

  /**
   * Upload image to S3
   * @param s3ObjectKey: S3 Object Key (File Full Path, ex: media/animals/bird.png)
   * @param s3PresignedUrl: S3 Uploading Presigned URL
   * @param base64: Image Base64
   * @param fileType: Image Type
   * @param fileSize: Image Size
   * @returns Size of image (whether compressed or not), otherwise NULL if error(s)
   */
  const uploadImageToS3 = async (s3ObjectKey: string, s3PresignedUrl: string, base64: any, fileType: string, fileSize: number = 0) => {
    try {
      const newImageSize = await uploadPhysicalFileToS3(s3PresignedUrl, base64, fileType, fileSize > PNG_MAZ_SIZE);
      return { s3ObjectKey, s3PresignedUrl, newImageSize };
    } catch (ex) {
      console.log('Cannot upload image to S3: ', ex);
      return null;
    }
  }

  // Operator Replace Image 
  const handleFileChange = async (event: any) => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));

      let file = event.target.files && event.target.files[0];
      if (!file) {
        return;
      }

      if (isFileOverSize(file)) {
        event.target.value = null;
        throw new Error('txt_file_size_exceeded');
      }

      if (isFileTypeNotAllowed(file)) {
        event.target.value = null;
        throw new Error('txt_file_type_not_supported');
      }

      const fileExtension = getFileExtension(file.name || '')?.toLowerCase();
      if (fileExtension !== selectedMedia.file_extension?.toLowerCase()) {
        event.target.value = null;
        throw new Error('txt_replace_file_type_not_same');
      }

      let imageFullPath: string = `media/${selectedMedia.fullPath}`;
      let thumbnailBase64: any = null;
      let thumbnailFullPath: string;
      const objectKeys: string[] = [];
      let fileMetaInfo;
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        fileMetaInfo = await parseImageMetadata(file);
      }

      const isJpgImage = IMAGE_EXTENSIONS.some((item) => item === fileExtension) && !/^svg$/ig.test(fileExtension) && !/^png$/ig.test(fileExtension);
      if (isJpgImage) {
        file = await getCompressedImage(file);
        const thumbnailFile = await getCompressedThumbnail(file);
        thumbnailBase64 = await getBase64(thumbnailFile);
        thumbnailFullPath = imageFullPath.replace(/.jpg$/ig, '_thumbnail.webp').replace(/.jpeg$/ig, '_thumbnail.webp');
        objectKeys.push(thumbnailFullPath);
      }

      let imageBase64 = await getBase64(file);
      objectKeys.push(imageFullPath);
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        const dimensions = await getImageDimensions(imageBase64);
        fileMetaInfo = { ...fileMetaInfo, dimensions };
      }

      // Get Uploading Presigned URL(s) for image and thumbnail (if any)
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({ object_keys: objectKeys });
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        console.log('Cannot get S3 Presigned URL!');
        throw new Error('txt_update_file_fail');
      }
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        if (/_thumbnail.webp$/ig.test(presignedUrlObject.object_key)) {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, thumbnailBase64, 'image/webp'));
        } else {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, imageBase64, file.type, file.size));
        }
      });
      /* Execute this S3 Uploading Task List */
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        const imageUploadingResult = results?.find((result) => result.s3ObjectKey === imageFullPath);
        if (imageUploadingResult) {
          /* Update Image Metadata */
          let fileInfoData = {
            uuid: selectedMedia.uuid,
            alternativeText: JSON.stringify(fileMetaInfo.alternative_text),
            description: JSON.stringify(fileMetaInfo.description),
            folderId: selectedFolder?.current?.uuid,
            fullPath: selectedMedia.fullPath,
            metadata: JSON.stringify(fileMetaInfo),
            name: selectedMedia.name,
            size: imageUploadingResult.newImageSize || file.size,
            type: getFileType(fileExtension),
            title: JSON.stringify(fileMetaInfo.title),
          }
          const res = await MediaV2ManagerAPI.updateFile(fileInfoData);
          if (res && res.status === 200) {
            setSuccessProgress(t('txt_update_media_success'));
            refresh();
          } else {
            throw new Error('txt_update_file_fail');
          }
        } else {
          throw new Error('txt_update_file_fail');
        }
      }
    } catch (ex: any) {
      setErrorProgress(errorTrans(ex.message));
    } finally {
    }
  };

  const fetchMediaFolder = async () => {
    try {
      const root: any = {
        key: "root",
        label: "/",
        data: "Root",
        icon: "pi pi-fw pi-folder",
        children: [],
      };

      const res = await MediaFolderManagerAPI.getAllV2();
      if (res && res.status === 200) {
        const folderData = res.data.records || [];
        setFolders(folderData);

        const folderTree = getFolderByFolderParentId(null, folderData);
        root.children = folderTree;
        setNodes([root]);
        return;
      }
      setNodes([]);
    } catch (error) { }
  };

  useEffect(() => {
    if (isShowEditor && selectedMedia) {
      setEditingPhoto(selectedMedia);
    }
  }, [isShowEditor, selectedMedia]);

  const editPhoto = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSidebarLoading(true);

    const fileURL = encodeURI(
      `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(selectedMedia.fullPath)}?v=${moment().seconds()}`
    );
    const imageBase64 = await requestBase64StringFromImageURL(fileURL);
    if (imageBase64) {
      setFileBase64(imageBase64);
    }

    setIsShowEditor(true);
    // setIsShowCreate(false);
    setIsSidebarLoading(false);
  };

  const updateSelectedFolder = (folder: any) => {
    selectedFolder.current = folder?.node || null;
    // @ts-ignore: Object is possibly 'null'.
    if (!selectedFolder.current || selectedFolder.current?.data === "Root") {
      setUploadActive(true);
    } else setUploadActive(false);

    setIsShowCreate(false);
    setSelectedMedia(null);
  };

  // Operator Edit Image = Image Editor 
  const saveChangesPhoto = async (editedPhoto: any) => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));

      const fileExtension = getFileExtension(editedPhoto.name).toLowerCase();
      const isJpgImage = IMAGE_EXTENSIONS.some((item) => item === fileExtension) && !/^svg$/ig.test(fileExtension) && !/^png$/ig.test(fileExtension);
      let imageBase64: any = null;
      let imageFullPath: string = `media/${selectedMedia.fullPath}`;
      let thumbnailBase64: any = null;
      let thumbnailFullPath: string;
      let file: any = editedPhoto.file;
      const objectKeys: string[] = [];
      if (isJpgImage) {
        file = await getCompressedImage(editedPhoto.file);
        const thumbnailFile = await getCompressedThumbnail(file);
        thumbnailBase64 = await getBase64(thumbnailFile);
        thumbnailFullPath = imageFullPath.replace(/.jpg$/ig, '_thumbnail.webp').replace(/.jpeg$/ig, '_thumbnail.webp');
        objectKeys.push(thumbnailFullPath);
      }
      imageBase64 = await getBase64(file);
      objectKeys.push(imageFullPath);
      // Get Uploading Presigned URL(s) for image and thumbnail (if any)
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({ object_keys: objectKeys });
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        throw new Error('Cannot get S3 Presigned URL!');
      }
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        if (/_thumbnail.webp$/ig.test(presignedUrlObject.object_key)) {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, thumbnailBase64, 'image/webp'));
        } else {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, imageBase64, file.type, file.size));
        }
      });
      /* Execute this S3 Uploading Task List */
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        if (results && results.length > 0) {
          const imageUploadingResult = results.find((result) => result.s3ObjectKey === imageFullPath);
          if (imageUploadingResult) {
            /* Update Image Metadata */
            let updateInfoData = {
              uuid: selectedMedia.uuid,
              size: imageUploadingResult.newImageSize || file.size,
              // @ts-ignore: Object is possibly 'null'.
              folderId: selectedFolder?.current?.uuid,
              fullPath: selectedMedia.fullPath,
              name: selectedMedia.name,
              type: getFileType(fileExtension),
            }
            const res = await MediaV2ManagerAPI.updateFile(updateInfoData);
            if (res && res.status === 200) {
              setSuccessProgress(t('txt_update_media_success'));
              // setSelectedMedia(null);
              setFileBase64(null);
              setIsShowEditor(false);
              refresh();
            } else {
              throw new Error('txt_update_file_fail');
            }
          }
        } else {
          console.log(`Cannot upload ${selectedMedia.name} to S3!`);
          throw new Error('txt_update_file_fail');
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    } finally {
    }
  };

  return (
    <Layout title={t('txt_title')} className="media-manager-container-v2">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={"pi pi-search"}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
          </div>
          <div className="d-flex">
            {buildMenu()}
            <Button
              className="wi-primary-button-v2 ml-24"
              type={"button"}
              label={t('txt_btn_add')}
              disabled={uploadActive}
              onClick={(e: any) => onCreateMedia(e)}
              hidden={!permissions.canCreateMedia}
            >
              <UploadSvg className="icon-svg"></UploadSvg>
            </Button>
          </div>
        </div>
      </div>
      <div className="media-content">
        <Splitter>
          <SplitterPanel size={25} minSize={25} className="tree-panel">
            <Directory
              ref={directoryRef}
              refresh={refresh}
              fetchMediaByFolderId={fetchMediaByFolderId}
              fetchMediaFolder={fetchMediaFolder}
              updateSelectedFolder={updateSelectedFolder}
              data={{ nodes }}
            />
          </SplitterPanel>
          <SplitterPanel size={75} minSize={75} className="file-panel-items max-height-none">
            {isLoading ? (
              <div className="media-loading-component">
                <ProgressSpinner className="progress-spinner-v2" />
              </div>
            ) :
              (<DataScroller
                ref={ds}
                className={`${layout === "grid" ? "custom-style-grid" : "custom-style-list"}`}
                emptyMessage={t('txt_no_records')}
                value={mediaFiles.data}
                itemTemplate={itemTemplate}
                rows={40}
                inline
              // scrollHeight="300px"
              // header={header}
              // loader
              />)}
          </SplitterPanel>
        </Splitter>
      </div>
      <Sidebar
        className="wi-sidebar-v2 p-sidebar-md sidebar-right sidebar-no-padding"
        style={{ width: '600px' }}
        position="right"
        visible={selectedMedia && isShowCreate}
        onHide={() => {
          setIsShowCreate(false);
          setSelectedMedia(null);
        }}
      >
        {
          isSidebarLoading ? (
            <div className="sidebar-loading-wrapper">
              <ProgressSpinner className="progress-spinner-v2" />
            </div>) : <></>
        }
        <MediaInfo
          data={{ selectedMedia, isShowCreate }}
          refresh={refresh}
          editPhoto={editPhoto}
          handleFileChange={handleFileChange}
          deleteMedia={deleteMedia}
          onHide={() => {
            setIsShowCreate(false);
            setSelectedMedia(null);
          }}
        ></MediaInfo>
      </Sidebar>
      <Sidebar
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        position="right"
        visible={!selectedMedia && isShowCreate}
        onHide={() => setIsShowCreate(false)}
      >
        <div className="sidebar-content h-100 d-flex flex-column">
          <div className="headline pt-24 pb-32">
            <h6>{t('txt_upload')}</h6>
          </div>
          <UploadMediaDialogComponent
            refresh={refresh}
            onHide={() => setIsShowCreate(false)}
            selectedFolder={selectedFolder}
            fullPath={getFullPath(selectedFolder?.current, folders)}
          />
        </div>
      </Sidebar>
      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => {
          onShowDeleteDialog(false);
        }}
        onConfirm={onDeleteConfirm}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
        message={
          <>
            <div className="text-center mb-16"><b>{t('txt_delete_item')}</b></div>
            <div className="text-center"> {t('txt_delete_description')} </div>
          </>
        }
        icon={<WarningSvg className="icon-svg"></WarningSvg>}
      />
      <ImageEditorDialogComponent
        visible={editingPhoto && isShowEditor}
        onHide={() => setIsShowEditor(false)}
        data={editingPhoto}
        fileBase64={fileBase64}
        saveChangesPhoto={saveChangesPhoto}
      />
    </Layout>
  );
};

export default MediaManager;


// const footer = <Button className="wi-outline-button" label="Load More" onClick={() => ds?.current?.load()} />;

// const renderHeader = () => {
//   return (
//     <>
//       <div className="row">
//           {layout === "list" && (
//             <>
//               <div className="col-1 text-start">
//               </div>
//               <div className="col-4 text-start">
//                 <span style={{ fontSize: "0.75rem" }}>Name</span>
//               </div>
//               <div className="col-2 text-start">
//                 <span style={{ fontSize: "0.75rem" }}>Type</span>
//               </div>
//               <div className="col-2 text-start">
//                 <span style={{ fontSize: "0.75rem" }}>Size</span>
//               </div>
//               <div className="col-1 text-start">
//                 <span style={{ fontSize: "0.75rem" }}>Date</span>
//               </div>
//             </>
//           )}
//         <div className={`${layout === "list" ? 'col-2' : 'col-12'} text-end`}>
//           <DataViewLayoutOptions
//             layout={layout}
//             onChange={(e) => setLayout(e.value)}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// const header = renderHeader();