import * as React from "react";
import { useState, useEffect } from "react";
import "./users-manager.scss";
import Layout from "../../components_v2/common/layout/layout";
import WISearchField from "../../components_v2/common/search/wi-search-field";
import { Button } from "primereact/button";
import UserTable from './components/user-table/user-table';
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { Sidebar } from "primereact/sidebar";
import InviteUserForm from "./components/invite-user-form/invite-user-form";
import FilterUserForm from "./components/filter-user-form/filter-user-form";
import { PartnerManagerAPI } from "../../services";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { getObjectWithValues, getPayloadConditions } from "./users-manager.util";
import { PERMISSIONS_V2, STATUS_LIST } from "../../components_v2/utils/utils";
import { UserManagerAPI } from "../../services/v2";
import { useLayoutV2 } from "../../context/LayoutProvider";
import useAuth from "../../context/useAuth";

export type UserFilterParameters = {
  pageNo: number;
  range: number;
  where: {
    searchText?: string;
    status: string[],
    roles: string[],
    partnerIds: string[],
  }
  order: []
}

const UsersManager = (props: any) => {
  const { setErrorProgress } = useLayoutV2();
  const { auth } = useAuth();
  
  const [search, setSearch] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'system_users' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [partners, setPartners] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const ACTIVE_STATUS = 'active';
  const [isLoading, setIsLoading] = useState(false);
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'created_at';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const [paginator, setPaginator] = useState({
    page: pageNumber,
    first: pageNumber * noRows,
    rows: noRows,
  });
  const searchField = searchParams?.get('search') || '';
  const filterStatus = (searchParams?.get('filterStatus') || STATUS_LIST.map((c) => c.code).join(',')).split(',');
  const filterRoles = searchParams?.get('filterRoles') ? searchParams?.get('filterRoles')?.split(',') || [] : [];
  const filterPartners = searchParams?.get('filterPartners') ? searchParams?.get('filterPartners')?.split(',') || [] : [];
  const [users, setUsers] = useState({
    data: [],
    totalPage: 0
  });
  const [filterValues, setFilterValues] = useState<UserFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    where: {
      searchText: searchField,
      status: filterStatus,
      roles: filterRoles,
      partnerIds: filterPartners,
    },
    order: []
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder
  });

  const permissions = {
    canCreateUser: auth?.permissions?.includes(PERMISSIONS_V2.USER_CREATE),
  };

  useEffect(() => {
    const searchParams: any = {
      pageNumber: (paginator.page + 1).toString(),
      noRows: paginator.rows.toString(),
      searchText: filterValues.where.searchText,
      filterStatus: filterValues.where.status?.join(',') || '',
      filterRoles: filterValues.where.roles?.join(',') || '',
      filterPartners: filterValues.where.partnerIds?.join(',') || '',
    };
    setSearchParams(_.omitBy(searchParams, (p) => !p), { replace: true });
    fetchUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    if (typeof paginator.page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: paginator.page,
        range: paginator.rows,
      });
      setSearchParams({
        pageNumber: (paginator.page + 1).toString(),
        noRows: paginator.rows.toString(),
      }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator]);

  const onFilterChange = (values: any, fields: any) => {
    let where_conditions = getObjectWithValues(filterValues.where);

    fields.forEach((field: any) => {
      const data = values?.get(field);
      where_conditions[field] = data;

    });

    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.where["status"] = [...STATUS_LIST.map((c) => c.code)];
    p.where.searchText = "";
    setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    where_conditions["searchText"] = searchText || null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
    if (paginator.page !== 0) {
      setPaginator({
        ...paginator,
        page: 0,
        first: 0,
      });
    }
  };

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const fetchMasterData = async () => {
    try {
      const [resPartner, resRoles] = await Promise.all([
        PartnerManagerAPI.getAllPartners(),
        UserManagerAPI.getRoles()
      ]);

      for (let res of [resPartner, resRoles]) {
        if (res.status !== 200) {
          throw new Error('txt_unknown_load_data_fail');
        }
      }

      const roles: any[] = _.sortBy(resRoles?.data?.result.map((r: any) => ({...r, label: `roles.txt_${r.name}`})), r => !r.is_host_only) || [];

      setPartners(resPartner?.data?.records.filter((r: any) => r.status === ACTIVE_STATUS) || []);
      setRoles(roles);
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  }

  const fetchUsersData = async () => {
    setIsLoading(true);
    const payload = getPayloadConditions(filterValues.where, sortConfig);

    try {
      const resUsers = await UserManagerAPI.getUsersBySearch({
        pageNo: paginator.page,
        range: paginator.rows,
        ...payload
      });
      if (resUsers && resUsers.status === 200) {
        setIsLoading(false);
        setUsers({
          data: resUsers.data.records ? resUsers.data.records : [],
          totalPage: resUsers.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setUsers({
        data: [],
        totalPage: 0
      });
    }
  }

  useEffect(() => {
    fetchUsersData();
    fetchMasterData();
  }, [])

  const refreshData = () => {
    onSearchFunc(search);
  }

  return (
    <Layout title={t('txt_user')} className="users-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={"pi pi-search"}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={() => onSearchFunc(search)}
              globalValue={search}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-10 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg ></FilterSvg>
              </div>
            </div>
          </div>
          <Button
            className="wi-primary-button-v2"
            type={"button"}
            label={t('txt_invite_user')}
            onClick={(e: any) => setIsShowSidebar(true)}
            hidden={!permissions.canCreateUser}
          >
            <PlusSvg className="icon-svg"></PlusSvg>
          </Button>
        </div>
      </div>
      <UserTable
        users={users.data}
        onBasicPageChange={onBasicPageChange}
        paginator={paginator}
        isLoading={isLoading}
        partners={partners}
        refreshData={refreshData}
      />
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-32">
            <h6>{t('txt_invite_user')}</h6>
          </div>
          <InviteUserForm
            fetchCallBack={() => {
              fetchUsersData();
              setIsShowSidebar(false)
            }}
            partners={partners}
            roles={roles}
            onHide={() => setIsShowSidebar(false)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-32">
            <h6>{t('txt_filter')}</h6>
          </div>
          <FilterUserForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onSearchFunc={onSearchFunc}
            onHide={() => setIsShowSidebarFilter(false)}
            // fields={['roles', 'status', 'partnerIds']}
            fields={['status', 'partnerIds']}
            partners={partners}
            roles={roles}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default UsersManager;
