import { Toast } from "primereact/toast";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "./donation-detail.scss";
import { Button } from "primereact/button";
import _ from "lodash";
import Layout from "../../../layout/layout";
import { Timeline } from 'primereact/timeline';
import { DonationManagerAPI, DonorManagerAPI, GoalManagerAPI, SubscriptionManagerAPI } from "../../../../services";
import { onDownloadReceipt, onDownloadReceiptDonationByYear, onDownloadCertificateDonationByYear } from "../../../../utils/donationManagerLogic";
import GenerateCertificateDonationDialogComponent from "../generate-certificate-donation-dialog/generate-certificate-donation-dialog";
import { WIConfirmDialog, WITag } from "../../../common";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as Yup from "yup";
import { IDonation, IEvent } from "../../../pages/interfaces/IComponent";
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { 
    SALUTATION_LIST, 
    RHYTHM_LIST, 
    DONATION_TYPE_LIST, 
    DONATION_STATUS, 
    StatusDisplay, 
    SUBSCRIPTION_STATUS, 
    showEvents, 
    showMessageEvent, 
    showIconEvents 
} from "../../../../utils/utils";
import { Menu } from 'primereact/menu';
import { env } from "../../../../environment";
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import CountryService from "../../../../services/country-service";
import m from 'moment';
import { showNotification, generateMapUrl, compareDate, parseJSON, formatStringToNumber } from '../../../../utils/logic';
import { NO_ACTION_LIST, updateDonationData, disabledEditAction, isValidDonorInfo } from "../../donation-manager.util";
import { Link } from 'react-router-dom';
import { formatDateBodyTemplate, formatDonationStatusBodyTemplate } from "../../../common/column-template-table/column-template";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { MenuDot } from "../../../common";
import useAuth from "../../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../../components_v2/utils/utils";
import { Sidebar } from "primereact/sidebar";
import AddDonorFormComponent from "../../../donor-manager/components/add-donor-form/add-donor-form";
import ChangeDonorForm from "./components/change-donor-form/change-donor-form";

const DonationDetail = (props: any) => {
    const { auth } = useAuth();
    const permissions = {
        canCreateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_CREATE),
        canUpdateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_UPDATE),
        canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
        canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
        canViewCampaign: auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
        canViewCode: auth?.permissions?.includes(PERMISSIONS_V2.CODE_VIEW),
        canViewGoal: auth?.permissions?.includes(PERMISSIONS_V2.GOAL_VIEW),
        canCreateDonationWithArea: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_X),
        canUpdateDonor: auth?.permissions?.includes(PERMISSIONS_V2.DONOR_UPDATE),
    }

    const donationID = window.location.pathname ? window.location.pathname.split("/")[2] : '';
    const [isLoading, setIsLoading] = useState(false);
    const [donation, setDonation] = useState<IDonation>();
    const [parentDonation, setParentDonation] = useState<IDonation>();
    const [code, setCode] = useState<any>(null);
    const menu = useRef(null);
    const toast = useRef(null);
    const [isShowCreateCertificateDialog, setIsShowCreateCertificateDialog] = useState(false);
    const [isReceiptLoading, setIsReceiptLoading] = useState(false);
    const [isEditDonation, setIsEditDonation] = useState(false);
    const [isDisabledEditDonation, setIsDisabledEditDonation] = useState(false);
    const formikRef = useRef(null);
    const [salutationList, setSalutationList] = useState(SALUTATION_LIST.DE);
    const [eventsPayment, setEventsPayment] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isError, setIsError] = useState({
        paymentDate: false,
        paymentReceviedDate: false
    });
    const [childrendonation, setChildrendonation] = useState({
        data: [],
        totalPage: 0,
        totalCharged: 0
    });
    const [subscriptionData, setSubscriptionData] = useState<any>();
    const [batchCode, setBatchCode] = useState<any>();
    const [goal, setGoal] = useState<any>();
    const [donationReceipts, setDonationReceipts] = useState<any>([]);
    const [isShowConfirm, setIsShowConfirm] = useState<any>(false);
    const [isShowSidebarDonor, setIsShowSidebarDonor] = useState(false);
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [donors, setDonors] = useState([]);
    const [selectedDonor, setSelectedDonor] = useState<any>({
        name: "",
        item: null,
      });
    const handleDisableIcon = () => {
        return NO_ACTION_LIST.some((item) => item === donation?.status?.toLowerCase())
    };

    const handleDisableOldData = () => {
        if(donation?.old_donation_id)
        {
            const paymentDate = new Date(donation?.payment_date || "");
            const year = paymentDate.getFullYear();
            const dateValid = [2021,2022,2023];
            return  !dateValid.includes(year);
        }
        
        return false;
        
    };

    const disabledChangeDonor = () => { 
        return donation?.rhythm !== 0 || (donation?.status !== DONATION_STATUS.SUCCEEDED && donation?.status !== DONATION_STATUS.RESERVED);
    }

    const itemsDonationAction = [
        {
            disabled: isDisabledEditDonation || isEditDonation,
            label: 'Edit',
            icon: 'pi pi-file-edit',
            command: () => setIsEditDonation(true),
            className: 'item-menu',
            hidden: !permissions.canUpdateDonation
        },
        {
            hidden: !permissions.canUpdateDonor,
            label: 'Update Donor Information',
            icon: 'pi pi-file-edit',
            command: () => setIsShowSidebarDonor(true),
            className: 'item-menu'
        },
        {
            hidden: !permissions.canUpdateDonation,
            disabled: disabledChangeDonor(),
            label: 'Change Donor',
            icon: 'pi pi-file-edit',
            command: () => setIsShowSidebar(true),
            className: 'item-menu'
        },
        {
            hidden: !permissions.canUpdateDonation || donation?.status !== DONATION_STATUS.RESERVED || donation.donation_type === 'code',
            disabled: isEditDonation,
            label: 'Mark as paid',
            icon: 'pi pi-file-edit',
            command: () => setIsShowConfirm(true),
            className: 'item-menu',
        },
        {
            disabled: donation ? handleDisableIcon() : false,
            className: 'item-menu',
            label: 'Download Certificate',
            icon: 'pi pi-download',
            command: (e: any) => onGenerateCertificateDialog(e)
        },
        {
            disabled: donation ? donation.disabled_receipt || handleDisableIcon() || !isValidDonorInfo(donation) || handleDisableOldData() : false,
            className: 'item-menu',
            label: 'Download Receipt',
            icon: 'pi pi-download',
            command: () => {
                setIsReceiptLoading(true);
                onDownloadReceipt(toast, donation).then(() => {
                    setIsReceiptLoading(false);
                });
            }
        },
        {
            disabled: true,
            className: 'item-menu',
            label: 'Cancel',
            icon: 'pi pi-times',
            hidden: !permissions.canUpdateDonation
        },
        {
            disabled: true,
            className: 'item-menu',
            label: 'Refund',
            icon: 'pi pi-times',
            hidden: !permissions.canUpdateDonation
        }
    ].filter(c => !c.hidden);

    const customizedMarker = (item: any) => {
        return (
            <span className="custom-marker shadow-1" style={{ backgroundColor: item?.color }}>
                <i className={item?.icon}></i>
            </span>
        );
    };

    const customizedContent = (item: any) => {
        return (
            <div className="custome-content-timeline">
                <div className="title-event">
                    {item?.name}
                </div>
                <div className="message-event">
                    {item?.message}
                </div>
                <div className="date-event">
                    {item?.updated_at}
                </div>
            </div>
        );
    };

    const onHideChangeDonor = () => {
        setSelectedDonor({
            name: `${donation?.payment_first_name} ${donation?.payment_last_name} - ${donation?.payment_email}`,
            item: donation
        });
        setIsShowSidebar(false);
    }

    const onGenerateCertificateDialog = (e: any) => {
        setIsShowCreateCertificateDialog(true);
    };

    const updateFilterPaymentsDonation = (events: IEvent[]) => {
        return events.filter((item: IEvent) => {
            if(showEvents.some(itemEvent => itemEvent === item.event_type)) {
                return item;
            }
        })
    }

    const convertNameEvents = (events: IEvent[]) => {
        if(events) {
            return events.map((itemEvent: IEvent) => {
                switch(itemEvent.event_type) {
                    case 'donation.started': itemEvent.name = 'Initiated'; break; 
                    case 'payment_intent.created': itemEvent.name = 'Payment Initiated'; break; 
                    case 'payment_intent.succeeded': itemEvent.name = 'Payment - Received'; break; 
                    case 'checkout.session.completed': itemEvent.name = 'Payment - Succeeded'; break; 
                    case 'charge.succeeded': itemEvent.name = 'Payment - Succeeded'; break; 
                    case 'certificate.sent.succeeded': itemEvent.name = 'Certificate - Sent'; break; 
                    case 'certificate.sent.failed': itemEvent.name = 'Certificate - Not sent'; break; 
                    case 'payment_intent.payment_failed': itemEvent.name = 'Payment - Failed'; break; 
                    case 'payment_intent.canceled': itemEvent.name = 'Payment - Cancelled'; break; 
                    case 'checkout.session.async_payment_failed': itemEvent.name = 'Payment - Failed'; break; 
                    case 'receipt.sent.succeeded': itemEvent.name = 'Receipt - Sent'; break; 
                    case 'receipt.sent.failed': itemEvent.name = 'Receipt - Not sent'; break; 
                    case 'charge.refund.updated': itemEvent.name = 'Payment - Refunded'; break; 
                    case 'payment.refunded': itemEvent.name = 'Payment - Refunded'; break;
                    case 'charge.dispute.created': itemEvent.name = 'Payment - Refunded'; break;
                    case 'payment.refund.failed': itemEvent.name = 'Payment - Not refunded'; break;
                    case 'charge.refunded': itemEvent.name = 'Payment - Refunded'; break;
                    case 'donation.generated': itemEvent.name = 'Generated'; break; 
                    case 'donation.redeemed': itemEvent.name = 'Redeemed'; break;
                    case 'invoice.created': itemEvent.name = 'Payment - Initiated'; break;
                    case 'invoice.payment_succeeded': itemEvent.name = 'Payment - Succeeded'; break;
                    case 'invoice.payment_failed': itemEvent.name = 'Payment - Failed'; break;
                    case 'customer.subscription.created': itemEvent.name = 'Subscription - Started'; break;
                    case 'customer.subscription.deleted': itemEvent.name = 'Subscription - Finished'; break;
                    case 'customer.subscription.updated': itemEvent.name = 'Subscription - Updated'; break;
                    default: itemEvent.name = itemEvent.event_type?.split(".").join(" ") || itemEvent.event_type; break;
                }
                return {
                    ...itemEvent
                }
            })
        }
    }

    const updateDateEvents = (itemEvent: any, donationData: any) => {
        if(itemEvent && donationData) {
            if(donationData?.is_imported && donationData?.events) {
                if(itemEvent.event_type === 'checkout.session.completed' || itemEvent.event_type === 'donation.started') {
                    return formDate(donationData?.payment_date);
                }
                if(itemEvent.event_type === 'payment_intent.succeeded') {
                    return formDate(donationData?.payment_received_date);
                }
            }
            return moment(new Date(itemEvent.updated_at), 'MMM DD, YYYY HH:mm:ss').utc().add(1, 'hour').format("MMM DD, YYYY HH:mm A");
        }
    }

    const updateFlowPaymentDonation = (donationData: IDonation) => {
        if(donationData) {
            let sorted: any = donationData.events || [];
            if(donationData?.status === DONATION_STATUS.CANCELLED) {
                const isPaymentIntentCancelled = sorted.find((event: any) => event.event_type === 'payment_intent.canceled');
                const isCheckoutSessionExpired = sorted.find((event: any) => event.event_type === 'checkout.session.expired');

                if(!isPaymentIntentCancelled && isCheckoutSessionExpired) {
                    sorted = sorted.map((event: any) => {
                        if(event.event_type === 'checkout.session.expired') {
                            return {
                                ...event,
                                event_type: 'payment_intent.canceled'
                            }
                        }
                        return event;
                    })
                }
            }

            if(donationData?.status === DONATION_STATUS.FAILED) {
                const isPaymentIntentCancelled = sorted.find((event: any) => event.event_type === 'payment_intent.canceled');
                if(isPaymentIntentCancelled) {
                    sorted = sorted.filter((event: any) => event.event_type !== 'payment_intent.canceled');
                }
            }

            sorted =  updateFilterPaymentsDonation(sorted);
            sorted = _.sortBy(sorted, 'updated_at').reverse().map((itemEvent) => {
                return {
                    ...itemEvent,
                    icon: showIconEvents.some(event => event === itemEvent.event_type) ? 'pi pi-times' : 'pi pi-check', 
                    color: showIconEvents.some(event => event === itemEvent.event_type) ? '#E52322' : '#94C24A',
                    updated_at: updateDateEvents(itemEvent, donationData),
                    message : showMessageEvent.some(event => event === itemEvent.event_type) ? itemEvent.message : ''
                }
            });

            sorted = convertNameEvents(sorted);

            const paymentSucceeded = sorted.findIndex((c: any) => c.event_type === 'checkout.session.completed'); // Payment - Succeeded
            const paymentReceived = sorted.findIndex((c: any) => c.event_type === 'payment_intent.succeeded'); // Payment - Received
            if((paymentSucceeded && paymentSucceeded === 0) && paymentReceived) {
                if (paymentSucceeded < paymentReceived) {
                    const temp = sorted[paymentSucceeded];
                    sorted[paymentSucceeded] = sorted[paymentReceived];
                    sorted[paymentReceived] = temp;
                }
            }

            setEventsPayment(sorted.filter((event: IEvent) => event));            
        }
    }

    const fetchCountryData = async () => {
        try {
            const res = await CountryService.getCountries();
            if (res.status === 200 && res.data.data) {
              setCountries(res.data.data);
            } else {
                setCountries([]);
            }
        } catch (error) {
            setCountries([]);
        }
      };

    const groupDonationByYear = (donations: any) => {
        let donationReceiptsByYears:any = [];
        const today = moment();
        const january31 = moment().month(0).date(31);
        const currentYear = today.year();

        const groupedByYear = donations.reduce((result: any, item: any) => {
            const year = new Date(item.payment_date).getFullYear();
            
            if (!result[year]) {
              result[year] = [];
            }
            if(year < currentYear) {
                if(!(year === currentYear - 1 && today.isBefore(january31))) {
                    result[year].push(item);
                }
            }
            return result;
          }, {});
        for (const key in groupedByYear) {
            if (groupedByYear.hasOwnProperty(key)) {
                const value = groupedByYear[key];
                if(value && value.length > 0) {
                    const filterValue = value.filter((item: any) => item.status === 'completed');
                    const receiptByYear = {
                        payment_date: `31.01.${parseInt(key) + 1}`,
                        total_amount: _.sumBy(filterValue, (d: any) => +d.donation_money) || 0,
                        total_protected_forest: _.sumBy(filterValue, (d: any) => +d.area) || 0,
                        year: key
                }
                    donationReceiptsByYears.push(receiptByYear);
                }
            }
        }
        
        setDonationReceipts(donationReceiptsByYears);
    }

    const getChildrenDonation = async (dataDonation: any) => {
        setIsLoading(true);
        try {
            const res = await DonationManagerAPI.getDonationsBySearch({
                pageNo: 0,
                range: 500,
                where: {
                    parent_donation_id: dataDonation.uuid
                }
            });
            if (res && res.status === 200) {
                groupDonationByYear(res.data.records)
                const updateChildrenDonation: any = _.sortBy(res.data.records, 'payment_date').reverse();
                const donationCompleted = res.data.records.filter((item: any) => item.status === 'completed');
                const totalSubscriptionMoney = _.sumBy(donationCompleted, (d: any) => formatStringToNumber(d.donation_money)) || 0;
                const donationMoney = dataDonation && dataDonation.status === 'completed' ? formatStringToNumber(dataDonation.donation_money) : 0;
                setIsLoading(false);
                setChildrendonation({
                    totalPage: res.data.total || 0,
                    data: updateChildrenDonation,
                    totalCharged: totalSubscriptionMoney + donationMoney
                });
            }
          } catch (error) {
            setIsLoading(false);
            setChildrendonation({
              data: [],
              totalPage: 0,
              totalCharged: 0
            })
          }
    }

    const fetchSubscriptionsByID = async (subscriptionId: any) => {
        try {
            const res = await SubscriptionManagerAPI.getSubscriptionsByID(subscriptionId);
            if (res && res.status === 200) {
                setSubscriptionData(res.data);
            } else {
                setSubscriptionData(null);
            }
        } catch (error) {
            setSubscriptionData(null);
        }
    };

    const fetchBachCodeByID = async (boostedCodeID: any) => {
        try {
            const res = await DonationManagerAPI.getCodeById(boostedCodeID);
            if (res && res.status === 200) {
                setBatchCode(res.data);
            } else {
                setBatchCode(null);
            }
        } catch (error) {
            setBatchCode(null);
        }
    };


    const getGoalDetails = async (goal_id: string) => {
        try {
            const res = await GoalManagerAPI.getGoalDetails(goal_id);
            if (res && res.status === 200) {
                setGoal(res.data.record);
            } else {
                setGoal(null);
            }
        } catch (error) {
            setGoal(null);
        }
    }

    const getDonors = async (donationData: any) => {
        try {
            if(donationData) {
                setSelectedDonor({
                    name: `${donationData.payment_first_name} ${donationData.payment_last_name} - ${donationData.payment_email}`,
                    item: donationData
                });
            }
          const res = await DonorManagerAPI.searchDonors('');
          if (res && res.status === 200) {
              const sortDonors: any = _.sortBy(res.data, 'joined_date').reverse();
              setDonors(res.data?.length > 0 ? sortDonors : []);
          }
        } catch (error) {
          console.log("error >>>", error);
        }
      }

    const fetchDonationDetail = async () => {
        setIsLoading(true);
        try {
            const res = await DonationManagerAPI.getDonation(donationID);
            if (res && res.status === 200) {
                const donationData = updateDonationData(res.data);
                setIsDisabledEditDonation(disabledEditAction(donationData));
                setDonation(donationData);
                updateFlowPaymentDonation(donationData);
                if(res.data?.subscription_id) {
                    fetchSubscriptionsByID(res.data?.subscription_id);
                }
                if(res.data?.boosted_code_id) {
                    fetchBachCodeByID(res.data?.boosted_code_id);
                }
                if(res.data?.goal_id) {
                    getGoalDetails(res.data?.goal_id);
                }
                getDonors(donationData);
                try {
                    if(res.data.parent_donation_id) {
                        const resParentDonation = await DonationManagerAPI.getDonation(res.data.parent_donation_id);
                        if (resParentDonation && resParentDonation.status === 200) {
                            const parentDonationData = updateDonationData(resParentDonation.data);
                            setParentDonation(parentDonationData);
                        }
                    } else {
                        getChildrenDonation(res.data)
                    }
                    if (res.data.code_id) {
                        const resCode = await DonationManagerAPI.getCodeById(res.data.code_id);
                        setCode(resCode.data);
                    }
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const removeFirstWord = (value: string) => {
        const indexOfSpace = value.indexOf(' ');
      
        if (indexOfSpace === -1) {
          return '';
        }
      
        return value.slice(indexOfSpace + 1);
      }

    const formDate = (value: any) => {
        if (value) {
            const date = m(new Date(value), 'MMM DD, YYYY HH:mm:ss').utc().add(1, 'hour').format("MMM DD, YYYY HH:mm A");
            return date.replace("01:00:00", "00:00:00");
        }
        return '-';
    }

    const renderParentName = () => {
        return parentDonation ? `${parentDonation.donation_serial}` : '';
    }

    const renderPaymentMethod = (paymentData: any) => {
        if(paymentData) {
            return paymentData?.payment_method ? paymentData?.payment_method.includes('Stripe') ? removeFirstWord(paymentData?.payment_method) : paymentData?.payment_method : '-';
        }
        return '-'
    }

    const navigateToParentName = () => {
        if(donation) {
            return donation?.parent_donation_id || '#';
        }
    }

    const navigateToStripe = () => {
        return subscriptionData?.id ? `${env.BASE_STRIPE_URL}/subscriptions/${subscriptionData?.id}` : '#';
    }

    const renderDonationType = () => {
        const donationType: any = DONATION_TYPE_LIST.find(item => item.code === donation?.donation_type);
        return donationType?.name?.de || '-'
    }

    // const renderSalutationName = () => {
    //     const salutation = SALUTATION_LIST.DE.find(item => item.key === donation?.payment_salutation);
    //     return salutation?.value || '-'
    // }

    const renderCountryName = () => {
        if(donation) {
            const country: any = countries.find((countryItem: any) => countryItem.ISO2 === donation.payment_country);
            return country?.name || donation?.payment_country
        }
    }

    const renderRhythm = (rhythmData: any) => {
        if(rhythmData) {
            const rhythm = RHYTHM_LIST.find(c => c.code === donation?.rhythm)?.name;
            if (rhythm && +rhythmData.rhythm > 0 && donation?.is_first_time) {
                return <span>{rhythm} <i className="fa-solid fa-clock-rotate-left rhythm-icon" title='First time donation'></i></span>
            }
    
            if (rhythm && +rhythmData.rhythm > 0 && !donation?.is_first_time) {
                return <span>{rhythm} <i className="fa-solid fa-arrows-rotate rhythm-icon" title='Subsequent donation'></i></span>
            }
    
            return RHYTHM_LIST.find(c => c.code === rhythmData.rhythm)?.name || 'Once';
        }
    }

    const renderOccasion = () => {
        if(donation?.donation_type === 'code') {
            const occasionBatch = parseJSON(code?.batch?.certificate_occasion);
            return occasionBatch ? occasionBatch : parentDonation?.personalize_certificate_reason || donation?.personalize_certificate_reason || '-';
        }
        return parentDonation?.personalize_certificate_reason || donation?.personalize_certificate_reason || '-';
    }

    const navigateBaseTransactionID = (donationData: any) => {
        if(donationData) {
            if(donationData.payment_transaction_id && donationData.payment_transaction_id.includes("pi_")) {
                return `${env.STRIPE_URL}${donationData.payment_transaction_id}`;
            }
            return `${env.PAYAL_URL}${donationData.payment_transaction_id}`;
        }
        return '#';
    }

    const getGeoCoordinate = (donation: any) => {

        if (donation && !donation.is_first_time && parentDonation && parentDonation.subDonations) {
            const year = moment(donation.payment_date).year();
            const oldest = _.first(_.sortBy(parentDonation.subDonations.filter(s => moment(s.payment_date).year() === year), 'payment_date'));
            return oldest?.nw_geo_coordinate ?
                <a className="wi-form-displayed-value" href={navigatgeToMap({...oldest, region: donation.region, land: donation.land, campaign: donation.campaign})} target="_blank" rel="noreferrer" >{ oldest?.nw_geo_coordinate || ''}</a>
                : <div className="wi-form-displayed-value">-</div>;
        }

        return donation?.nw_geo_coordinate ?
            <a className="wi-form-displayed-value" href={navigatgeToMap(donation)} target="_blank" rel="noreferrer" >{ donation?.nw_geo_coordinate || ''}</a>
            : <div className="wi-form-displayed-value">-</div>;
    }

    const navigatgeToMap = (donation: any) => {
        const parentInfo: any = {};
        if (donation && !donation.is_first_time && parentDonation && parentDonation.subDonations && donation.nw_geo_coordinate) {
            parentInfo.pdf_cerfiticate_display_name = parentDonation.pdf_cerfiticate_display_name;
            parentInfo.area = _.sumBy(parentDonation.subDonations, (c: any) => +c.area);
        }

        if(donation) {
            return  `${env.CMS_URL || 'http://wild.appbisweb.com'}/map?id=${generateMapUrl({...donation, ...parentInfo})}`;
        }
        return '#';
    }

    const formatAmountBodyTemplate = (rowData: any) => {
        return <div><b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.donation_money?.toString())?.toLocaleString("de-DE") : '-'}</b> <span>{rowData.payment_currency_code?.toUpperCase()}</span></div>;
      };
    
    const formatSerialBodyTemplate = (rowData: any) => {
        return <a className="btn-serial" href={`/donations/${rowData.uuid}`} >{rowData.land_id ? rowData.donation_serial : `00-${rowData.serial}`}</a>
    };

    const formatTransactionIDBodyTemplate = (rowData: any) => {
        if(rowData?.is_imported) {
            return <span>{rowData?.payment_transaction_id || '-'}</span>
        } else {
            return rowData.payment_transaction_id ? <a className="btn-serial"  href={navigateBaseTransactionID(rowData)} target="_blank" rel="noreferrer" >{ rowData?.payment_transaction_id || ''}</a> : '-';
        }
    };

    const buildStatusSubscription = (statusData: any) => {
        const status = statusData || "pending";
        const value = SUBSCRIPTION_STATUS.find((c) => c.code === status.toLowerCase())?.name.de || status;
    
        try {
          return (
            <React.Fragment>
              <Tag
                className={`mr-2 wi-status-tag tag-status-${status.toLowerCase()}`}
                value={value}
              />
            </React.Fragment>
          );
        } catch (error) {
          return (
            <React.Fragment>
              <Tag className="mr-2" value={value} />
            </React.Fragment>
          );
        }
      };
    
    const markAsPaid = async () => {
        try {
            setIsLoading(true);
            const resUpdate = await DonationManagerAPI.updateDonation(donationID, {
                payment_first_name: donation?.payment_first_name.trim(),
                payment_last_name: donation?.payment_last_name.trim(),
                payment_email: donation?.payment_email.trim(),
                status: DONATION_STATUS.SUCCEEDED,
                payment_date: m.utc(m(new Date()).format("MM/DD/YYYY HH:mm:ss")).add(-1, "hour"),
                payment_received_date: m.utc(m(new Date()).format("MM/DD/YYYY HH:mm:ss")).add(-1, "hour"),
            });
            if (resUpdate && resUpdate.status === 200) {
                await fetchDonationDetail();
                showNotification("success", "Update donation details successfully", toast);
            } else {
                showNotification("error", "Failed to update donation details", toast);
            }
        } catch (e) {
            showNotification("error", "Failed to update donation details", toast);
        } finally {
            setIsLoading(false);
        } 
    }

    const updateDonationDetail = async () => {
        try {
            // @ts-ignore: Object is possibly 'null'.
            const { values, dirty, errors } = formikRef.current;
            
            if(dirty && _.isEmpty(errors) && !isError.paymentDate && !isError.paymentReceviedDate) {
                setIsLoading(true);

                const paymentDate = new Date(m(values.payment_date).format("MM/DD/YY 00:00:00"))
                const paymentReceived_date = new Date(m(values.payment_received_date).format("MM/DD/YY 00:00:00"))

                const donationUpdate = {
                    ...values,
                    disabled_receipt: !values.enable_receipt,
                    payment_date: m.utc(m(new Date(paymentDate)).format("MM/DD/YYYY HH:mm:ss")).add(-1, "hour"),
                    payment_received_date: m.utc(m(new Date(paymentReceived_date)).format("MM/DD/YYYY HH:mm:ss")).add(-1, "hour"),
                    updated_at: m.utc(m(new Date()).format("MM/DD/YYYY HH:mm:ss")),
                    personalize_certificate: values.personalize_certificate_display_name ? true : false,
                    payment_first_name: values.payment_first_name.trim(),
                    payment_last_name: values.payment_last_name.trim(),
                    payment_email: values.payment_email.trim(),
                    payment_address: values.payment_address.trim(),
                    payment_additional_address:values.payment_additional_address.trim(),
                    payment_post_code: values.payment_post_code.trim(),
                    payment_city: values.payment_city.trim(),
                    payment_country: values.payment_country.trim(),
                }
    
                if(!donation?.is_imported) {
                    delete donationUpdate.payment_date;
                    delete donationUpdate.payment_received_date;
                }
    
                setIsError({
                    paymentDate: false,
                    paymentReceviedDate: false
                })
                
                const resUpdate = await DonationManagerAPI.updateDonation(donationID, donationUpdate);
                if (resUpdate && resUpdate.status === 200) {
                    await fetchDonationDetail();
                    setIsEditDonation(false);
                    setIsLoading(false);
                    showNotification("success", "Update donation details successfully", toast);
                } else {
                    setIsLoading(false);
                    showNotification("error", "Failed to update donation details", toast);
                }
            } 
        } catch (error) {
            setIsLoading(false);
            showNotification("error", "Failed to update donation details", toast);
        }
    }

    const initialDonationFormValues = () => {
        return {
            // personalize_certificate: donation && donation.personalize_certificate !== null ? donation.personalize_certificate : false,
            personalize_certificate_display_name: donation && donation.personalize_certificate_display_name !== null ? donation.personalize_certificate_display_name : '',
            personalize_certificate_reason: donation && donation.personalize_certificate_reason !== null ? donation.personalize_certificate_reason : '',
            note: donation && donation.notes !== null ? donation?.notes?.length > 0 ? donation?.notes[0].message : '' : '',
            // payment_salutation: donation && donation.payment_salutation !== null ? donation.payment_salutation : '',
            payment_first_name: donation && donation.payment_first_name !== null ? donation.payment_first_name.trim() : '',
            payment_last_name: donation && donation.payment_last_name !== null ? donation.payment_last_name.trim() : '',
            payment_email: donation && donation.payment_email !== null ? donation.payment_email.trim() : '',
            payment_address: donation && donation.payment_address !== null ? donation.payment_address.trim() : '',
            payment_additional_address: donation && donation.payment_additional_address !== null ? donation.payment_additional_address.trim() : '',
            payment_post_code: donation && donation.payment_post_code !== null ? donation.payment_post_code.trim() : '',
            payment_city: donation && donation.payment_city !== null ? donation.payment_city.trim() : '',
            payment_country: donation && donation.payment_country !== null ? donation.payment_country.trim() : '',
            // as_company: donation && donation.as_company !== null ? donation.as_company : false,
            payment_company_name: donation && donation.payment_company_name !== null ? donation.payment_company_name : '',
            payment_date: donation && donation.payment_date !== null ? new Date(donation.payment_date) : new Date(moment.tz(new Date(), "Europe/Berlin").format("MMM DD, YYYY HH:mm:ss")),
            payment_received_date: donation && donation.payment_received_date !== null  ? new Date(donation.payment_received_date) : new Date(moment.tz(new Date(), "Europe/Berlin").format("MMM DD, YYYY HH:mm:ss")),
            is_anonymous: donation && donation.is_anonymous !== null ? donation.is_anonymous : false,
            disabled_receipt: donation && donation.disabled_receipt !== null ? donation.disabled_receipt : false,
            enable_receipt: donation && donation.disabled_receipt !== null ? !donation.disabled_receipt : true,
        }
      };

    const validationDonationSchema = Yup.object().shape({
        // personalize_certificate: Yup.string()
        //   .required('This field is required.'),
        // personalize_certificate_display_name: Yup.string().when('personalize_certificate', (personalize_certificate: any, field: any) =>
        // personalize_certificate === 'true' ? field.required('This field is required.') : field),
        // payment_salutation: Yup.string()
        //   .required('This field is required.'),
        payment_first_name: Yup.string()
          .required('This field is required.'),
        payment_email: Yup.string().email('This field must be a valid email')
          .required('This field is required.'),
        // payment_address: Yup.string()
        //   .required('This field is required.'),
        // as_company: Yup.string()
        //   .required('This field is required.'),
        // payment_company_name: Yup.string().when('as_company', (as_company: any, field: any) =>
        // as_company === 'true' ? field.required('This field is required.') : field),
        payment_last_name: Yup.string()
          .required('This field is required.')
      });

      const tableColumns = [
        {
          field: "donation_serial",
          header: "ID",
          width: "10%",
          align: "left",
          bodyTemPlate: formatSerialBodyTemplate,
        },
        {
          field: "donation_money",
          header: "Amount",
          width: "10%",
          align: "right",
          bodyTemPlate: formatAmountBodyTemplate,
        },
        {
          field: "status",
          header: "",
          width: "10%",
          align: "center",
          bodyTemPlate: formatDonationStatusBodyTemplate(StatusDisplay),
          disabledSort: true
        },
        {
          field: "rhythm",
          header: "Rhythm",
          width: "15%",
          align: "left",
          bodyTemPlate: (rowData: any) => {
            const rhythm = RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name;
            if (rhythm && +rowData.rhythm > 0 && rowData.is_first_time) {
              return <span>{rhythm}</span>
            }
    
            if (rhythm && +rowData.rhythm > 0 && !rowData.is_first_time) {
              return <span>{rhythm}</span>
            }
    
            return RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'Once';
          }
        },
        {
            field: "payment_transaction_id",
            header: "Transaction ID",
            width: "20%",
            align: "left",
            bodyTemPlate: formatTransactionIDBodyTemplate,
          },
        {
          field: "payment_date",
          header: "Date",
          width: "20%",
          align: "left",
          bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
        },
        {
          field: "payment_method",
          header: "Method",
          width: "15%",
          align: "left",
        }
      ];
    
      const dynamicColumns = tableColumns.map((col, i) => {
        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{
              width: col.width,
            }}
            body={col.bodyTemPlate}
            align={col.align as ColumnAlignType}
            sortable={!col.disabledSort}
          />
        );
      });

      const tableColumnsReceipt = [
        {
          field: "payment_date",
          header: "Date",
          width: "30%",
          align: "left",
          bodyTemPlate: (rowData: any) => {
            return rowData.payment_date
          },
        },
        {
            field: "total_amount",
            header: "Amount",
            width: "30%",
            align: "left",
            bodyTemPlate: (rowData: any) => {
                return <div><b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.total_amount?.toString())?.toLocaleString("de-DE") : '-'}</b> <span>{donation?.payment_currency_code?.toUpperCase()}</span></div>;
              },
        },
        {
            field: "total_protected_forest",
            header: "Protected forest",
            width: "30%",
            align: "left",
            bodyTemPlate: (rowData: any) => {
                return <div><b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.total_protected_forest?.toString())?.toLocaleString("de-DE") : '-'}</b> <span>m²</span></div>;
              },
        },
      ];

      const dynamicColumnsReceipt = tableColumnsReceipt.map((col, i) => {
        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{
              width: col.width,
            }}
            body={col.bodyTemPlate}
            align={col.align as ColumnAlignType}
          />
        );
      });

    useEffect(() => {
        fetchCountryData();
        fetchDonationDetail();
    }, []);
    
  return (
    <Layout customLayout={true} customCopyRight={false}>
      <Toast ref={toast} />
      {isLoading ? (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        ) : (
      <div className="donation-detail">
        {/* <div className="donation-detail__header-fixed"></div> */}
        <div className="donation-detail__header">
            <div className="header-container">
                <h1 className="h1">Donation Details</h1>
                <div className="btn-action-container">
                    <h3 className="h3 donation-serial mb-0">
                        {donation?.land_id ? donation?.donation_serial || '' : `00-${donation?.serial}`}
                    </h3>
                    <Menu model={itemsDonationAction} popup ref={menu} id="popup_menu" />
                    { donation?.is_first_time ?
                        <Button 
                            icon="pi pi-ellipsis-v" 
                            // @ts-ignore: Object is possibly 'null'.
                            onClick={(event) => menu.current.toggle(event)} 
                            aria-controls="popup_menu" 
                            aria-haspopup 
                        /> : ''
                    }
                </div>
            </div>
            <div className="row row-amount">
                        <div className="amount">
                            <div className="amount-container">
                                <div className="value-amount"> { donation ? parseFloat(donation.donation_money?.toString())?.toLocaleString('de-DE') : ''} </div>
                                <div className="currency-amount">{ donation?.payment_currency_code?.toUpperCase() || ''} </div>
                                <div className="status-amount"> <WITag value={donation?.status}></WITag></div>
                            </div>
                            {
                                isEditDonation &&
                                <div className="edit-action">
                                    <div className="save-action">
                                        <Button
                                            type="submit"
                                            icon="pi pi-check"
                                            className="p-button-success"
                                            onClick={() => updateDonationDetail()}
                                        />
                                        </div>
                                    <div className="cancel-action">
                                        <Button
                                            icon="pi pi-times"
                                            className="p-button-danger"
                                            onClick={() => {
                                                // @ts-ignore: Object is possibly 'null'.
                                                formikRef.current.resetForm();
                                                setIsEditDonation(false)
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                    </div>
                </div>
            </div>
        <div className="donation-detail__content">
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialDonationFormValues()}
                validationSchema={validationDonationSchema}
                onSubmit={(values, actions) => {
                // nothing to do
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                }) => (
            <form onSubmit={handleSubmit}>
                <div className="donation-detail__container">
                    <div className="row first-row">
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h3 className="h3 wi-form-section-title">Donation Information</h3>
                                </div>
                                <div className="donation-detail__information-content">
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Forest size</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value">{ donation ? parseFloat(donation.area.toString())?.toLocaleString('de-DE') : '-' } m²</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Compensated CO2</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value">{ donation ? parseFloat(donation.amount_kg_co2.toString())?.toLocaleString('de-DE') : '-' } kg</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Region</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                donation?.region?.uuid ?
                                                <Link className={`wi-form-displayed-value ${permissions.canViewRegion  ? '' : 'disabled'}`} 
                                                    to={`/regions/${donation?.region?.uuid}` || '#'} target="_blank" >{ donation?.region?.name['de'] || '' } </Link>
                                                : <div className="wi-form-displayed-value">-</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Area</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                donation?.land?.uuid ?
                                                <Link className={`wi-form-displayed-value ${permissions.canViewArea  ? '' : 'disabled'}`} 
                                                    to={`/areas/${donation?.land?.uuid}` || '#'} target="_blank" >{ `${donation?.land?.name?.de ? donation?.land?.name?.de : donation?.land?.name}` } </Link>
                                                : <div className="wi-form-displayed-value">-</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        donation && donation?.campaign && <div className="row content-row">
                                            <div className="col-md-4">
                                                <label className="wi-form-title text-secondary-gray">Campaign</label>
                                            </div>
                                            <div className="col-md-8">
                                                {
                                                    donation?.campaign?.uuid ?
                                                    <Link className={`wi-form-displayed-value ${permissions.canViewCampaign  ? '' : 'disabled'}`}
                                                        to={`/campaigns/${donation?.campaign?.uuid}` || '#'} target="_blank" >{ donation?.campaign?.name['de'] || '' } </Link>
                                                    : <div className="wi-form-displayed-value">-</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        code && <>
                                            <div className="row content-row">
                                                <div className="col-md-4">
                                                    <label className="wi-form-title text-secondary-gray">Code</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="wi-form-displayed-value">{`${code.prefix_code}-${code.code}` || '' }</div>
                                                </div>
                                            </div>
                                            <div className="row content-row">
                                                <div className="col-md-4">
                                                    <label className="wi-form-title text-secondary-gray">Set</label>
                                                </div>
                                                <div className="col-md-8">
                                                    {
                                                        code.batch_id ?
                                                        <Link className={`wi-form-displayed-value ${permissions.canViewCode  ? '' : 'disabled'}`}
                                                            to={`/codes/${code.batch_id}` || '#'} target="_blank" >{ `${code?.batch?.name}` || '' } </Link>
                                                        : <div className="wi-form-displayed-value">-</div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        donation?.boosted_code_id && batchCode ? 
                                        <div className="row content-row">
                                            <div className="col-md-4">
                                                <label className="wi-form-title text-secondary-gray">Boosted by</label>
                                            </div>
                                            <div className="col-md-8">
                                                {
                                                    <Link className={`wi-form-displayed-value ${permissions.canViewCode  ? '' : 'disabled'}`} 
                                                        to={`/codes/${batchCode?.batch_id}?pageNumber=1&noRows=20&search=${batchCode?.code}&sortField=code&filterStatus=active%2Credeemed%2Cinactive%2Cinvalid` || '#'} target="_blank" >{batchCode?.prefix_code} - {batchCode?.code}</Link>
                                                }
                                            </div>
                                        </div> : <div/>
                                    }
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Geo-coordinate (DMS)</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                [DONATION_STATUS.SUCCEEDED, DONATION_STATUS.RESERVED].includes(donation?.status) ?
                                                getGeoCoordinate(donation)
                                                : <div className="wi-form-displayed-value">-</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Payment method</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value">{ renderPaymentMethod(donation) }</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Transaction ID</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                donation?.is_imported ? 
                                                <div className="wi-form-displayed-value">{donation?.payment_transaction_id || '-'}</div> :
                                                donation?.payment_transaction_id ?
                                                <a className="wi-form-displayed-value" href={navigateBaseTransactionID(donation)} target="_blank" rel="noreferrer" >{ donation?.payment_transaction_id || ''}</a>
                                                :
                                                <div className="wi-form-displayed-value">-</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Donated date</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation && donation?.is_imported ?
                                                <div className="edit-donation-field">
                                                    <Calendar
                                                        className={`${touched.payment_date && errors.payment_date ? 'has-error' : ''}`}
                                                        name="payment_date"
                                                        value={values.payment_date}
                                                        onChange={(e) => {
                                                            setFieldValue('payment_date', e.value || values.payment_date, false);
                                                            const isValidate = compareDate(e.value, values.payment_received_date);
                                                            setIsError ({
                                                                paymentDate: isValidate,
                                                                paymentReceviedDate: isValidate && false
                                                            })
                                                        }}
                                                    />
                                                    {isError.paymentDate ? (<small className="error">Donated date must be earlier than payment received date.</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{formDate(donation?.payment_date)}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Payment received date</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation && donation?.is_imported ?
                                                <div className="edit-donation-field">
                                                    <Calendar
                                                        className={`${touched.payment_received_date && errors.payment_received_date ? 'has-error' : ''}`}
                                                        name="payment_received_date"
                                                        value={values.payment_received_date}
                                                        onChange={(e) => {
                                                            setFieldValue('payment_received_date', e.value || values.payment_received_date, false);
                                                            const isValidate = compareDate(values.payment_date, e.value);
                                                            setIsError ({
                                                                paymentDate: isValidate && false,
                                                                paymentReceviedDate: isValidate
                                                            })
                                                        }}
                                                    />
                                                    {isError.paymentReceviedDate ? (<small className="error">Payment received date must be later than donated date.</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{formDate(donation?.payment_received_date)}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Last update date</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value">{ formDate(donation?.updated_at)}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Donation type</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value textDecorator">{ renderDonationType() }</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Rhythm</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value">{ renderRhythm(donation) || 'Once'}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Goal</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="wi-form-displayed-value">{ goal?.code || '-'}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label className="wi-form-title text-secondary-gray">Parent donation</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                parentDonation?.donation_serial ? 
                                                <a className="wi-form-displayed-value" href={navigateToParentName()} target="_blank" rel="noreferrer" >{renderParentName()} </a>
                                                :
                                                <div className="wi-form-displayed-value">-</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h3 className="h3 wi-form-section-title">Donor Information</h3>
                                </div>
                                <div className="donation-detail__information-content">
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Name</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ donation?.payment_first_name  || ''} { donation?.payment_last_name  || ''}</div>
                                        </div>
                                    </div>

                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Email</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ donation?.payment_email || '-'}</div>
                                        </div>
                                    </div>

                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Address</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_address && errors.payment_address ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_address"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_address}
                                                        maxLength={100}
                                                    />
                                                    {errors.payment_address && touched.payment_address ? (<small className="error">{errors.payment_address}</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ donation?.payment_address || '-'}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Additional address</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_additional_address && errors.payment_additional_address ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_additional_address"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_additional_address}
                                                        maxLength={100}
                                                    />
                                                    {errors.payment_additional_address && touched.payment_additional_address ? (<small className="error">{errors.payment_additional_address}</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ donation?.payment_additional_address || '-'}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Postal code</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_post_code && errors.payment_post_code ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_post_code"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_post_code}
                                                        maxLength={10}
                                                    />
                                                    {errors.payment_post_code && touched.payment_post_code ? (<small className="error">{errors.payment_post_code}</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ donation?.payment_post_code || '-'}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">City</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_city && errors.payment_city ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_city"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_city}
                                                        maxLength={85}
                                                    />
                                                    {errors.payment_city && touched.payment_city ? (<small className="error">{errors.payment_city}</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ donation?.payment_city || '-'}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Country</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <Dropdown
                                                        id="dropdown_countries"
                                                        value={values.payment_country}
                                                        options={countries}
                                                        onChange={(item: any) => {
                                                            setFieldValue("payment_country", item.value, true);
                                                        }}
                                                        optionLabel="name"
                                                        optionValue="ISO2"
                                                        appendTo="self"
                                                        filter
                                                        filterBy="name"
                                                    />
                                                    {errors.payment_country && touched.payment_country ? (<small className="error">{errors.payment_country}</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ renderCountryName() }</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        isEditDonation ? 
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Company name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_company_name && errors.payment_company_name ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_company_name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_company_name}
                                                        maxLength={80}
                                                    />
                                                    {errors.payment_company_name && touched.payment_company_name ? (<small className="error">{errors.payment_company_name}</small>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Company name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <div className="wi-form-displayed-value">{ donation?.payment_company_name || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-container">
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h3 className="h3 wi-form-section-title">Timeline</h3>
                                </div>
                                    <div className="donation-detail__information-content">
                                        <Timeline 
                                            value={eventsPayment.filter(e => !!e)} 
                                            className="customized-timeline"
                                            marker={customizedMarker}
                                            content={customizedContent}
                                        />
                                    </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h3 className="h3 wi-form-section-title">Other Information</h3>
                                </div>
                                <div className="donation-detail__information-content">
                                    {/* <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Personalize certificate</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <Checkbox 
                                                        inputId="binary" 
                                                        name="personalize_certificate"
                                                        checked={values.personalize_certificate} 
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            if(!e.value) {
                                                                setFieldValue('personalize_certificate_display_name', '', false);
                                                                setFieldValue('personalize_certificate_reason', '', false);
                                                            }
                                                        }}
                                                        disabled={donation?.donation_type === 'code'}
                                                    />
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ ((!donation?.is_first_time && parentDonation?.personalize_certificate) || donation?.personalize_certificate ) ? 'Yes' : 'No' || 'No'}</div>
                                            }
                                        </div>
                                    </div> */}
                                    {
                                        isEditDonation ?
                                        // <AnimateHeight
                                        //     duration={300}
                                        //     height={values?.personalize_certificate ? `auto` : 0}
                                        // >
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Certificate name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.personalize_certificate_display_name && errors.personalize_certificate_display_name ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="personalize_certificate_display_name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.personalize_certificate_display_name}
                                                        maxLength={80}
                                                    />
                                                    {errors.personalize_certificate_display_name && touched.personalize_certificate_display_name ? (<small className="error">{errors.personalize_certificate_display_name}</small>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        // </AnimateHeight>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Certificate name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="wi-form-displayed-value">{ parentDonation?.personalize_certificate_display_name || donation?.personalize_certificate_display_name || '-'}</div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        isEditDonation && !parseJSON(code?.batch?.certificate_occasion) ?
                                        // <AnimateHeight
                                        //     duration={300}
                                        //     height={values?.personalize_certificate ? `auto` : 0}
                                        // >
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Occasion</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.personalize_certificate_reason && errors.personalize_certificate_reason ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="personalize_certificate_reason"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.personalize_certificate_reason}
                                                        maxLength={80}
                                                        disabled={parseJSON(code?.batch?.certificate_occasion)}
                                                    />
                                                    {errors.personalize_certificate_reason && touched.personalize_certificate_reason ? (<small className="error">{errors.personalize_certificate_reason}</small>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        // </AnimateHeight>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Occasion</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="wi-form-displayed-value">{renderOccasion()}</div>
                                            </div>
                                        </div>
                                    }

                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Subscribe to the newsletter</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ (parentDonation?.subscribe_newsletter || donation?.subscribe_newsletter) ? 'Yes' : 'No'}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Is manually added</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ donation?.is_imported ? 'Yes' : 'No' || 'No'}</div>
                                        </div>
                                    </div>
                                    {
                                        isEditDonation ?
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Anonymous</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <Checkbox
                                                    name="is_anonymous"
                                                    onChange={handleChange}
                                                    checked={values.is_anonymous} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Anonymous</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="wi-form-displayed-value">{ donation?.is_anonymous ? 'Yes' : 'No' || 'No'}</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        isEditDonation ?
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Receipt</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <Checkbox
                                                        name="enable_receipt"
                                                        onChange={handleChange}
                                                        checked={values.enable_receipt} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Receipt</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="wi-form-displayed-value">{ !donation?.disabled_receipt ? 'Yes' : 'No' }</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !!donation?.old_donation_id ? 
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Old Donation Id</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="wi-form-displayed-value">{ donation?.old_donation_id }</div>
                                            </div>
                                        </div> : 
                                        <div></div>
                                    }
                                    {
                                        !!donation?.old_parent_donation ? 
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label className="wi-form-title text-secondary-gray">Old Parent Donation</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="wi-form-displayed-value">{ donation?.old_parent_donation }</div>
                                            </div>
                                        </div> : 
                                        <div></div>
                                    }
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Note</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <InputTextarea 
                                                        className={`${touched.note && errors.note ? 'has-error' : ''}`}
                                                        name="note"
                                                        value={values.note}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        rows={5} 
                                                        autoResize 
                                                    />
                                                    {errors.note && touched.note ? (<small className="error">{errors.note}</small>) : null}
                                                </div>
                                                :
                                                <div className="wi-form-displayed-value">{ donation && donation?.notes?.length > 0 ? donation?.notes[0].message : '-' }</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        donation && donation.rhythm !== 0 && !donation?.parent_donation_id ?
                    <div className="row">
                        <div className="col-12 col-md-12 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h3 className="h3 wi-form-section-title">Subscription Information</h3>
                                </div>
                                <div className="donation-detail__information-content">
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Total</label>
                                        </div>
                                        <div className="col-md-7 status-subscription">
                                            <div className="wi-form-displayed-value">{ parseFloat(childrendonation.totalCharged?.toString())?.toLocaleString('de-DE') || '-' } { subscriptionData?.currency?.toUpperCase()}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Stripe Subscription ID</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                subscriptionData?.id ? 
                                                <a className="wi-form-displayed-value" href={navigateToStripe()} target="_blank" rel="noreferrer" >{subscriptionData?.id || ''} </a>
                                                :
                                                <div className="wi-form-displayed-value">-</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Start date</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ formDate(subscriptionData?.created_at || donation?.payment_date)}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">End date</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ formDate(subscriptionData?.finished_date)}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Next collection date</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ subscriptionData?.status === 'finished' ? '-' : formDate(subscriptionData?.collection_date)}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Next collection amount</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                subscriptionData?.status === 'finished' ? <div className="wi-form-displayed-value">-</div> : 
                                                <div className="wi-form-displayed-value">{ subscriptionData?.amount ? parseFloat(subscriptionData?.amount?.toString())?.toLocaleString('de-DE') : '-'} { subscriptionData?.currency?.toUpperCase()} </div>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Next collection payment method</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ subscriptionData?.status === 'finished' ? '-' : renderPaymentMethod(childrendonation.data.length > 0 ?childrendonation.data[0] : donation) }</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Current rhythm</label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="wi-form-displayed-value">{ renderRhythm(subscriptionData) || '-'}</div>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label className="wi-form-title text-secondary-gray">Status</label>
                                        </div>
                                        <div className="col-md-7 status-subscription">
                                            <div className="wi-form-displayed-value">{ subscriptionData?.status ? buildStatusSubscription(subscriptionData?.status || 'pending') : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div/>
                    }

                    {
                        donation && donation.rhythm !== 0 && !donation?.parent_donation_id ?
                    <div className="row" style={{marginTop: '20px'}}>
                         <div className="col-12 col-md-12 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h3 className="h3 wi-form-section-title">Yearly Donation Receipt / Certificates</h3>
                                </div>
                            </div>
                            <div className="table-detail-container">
                                <div className="table-children">
                                    <DataTable 
                                    loading={isLoading}
                                    value={donationReceipts}
                                    scrollable 
                                    scrollHeight="calc(100vh - 166px)"
                                    scrollDirection="both"
                                    responsiveLayout="scroll">
                                        {dynamicColumnsReceipt}
                                        <Column
                                        frozen
                                        alignFrozen="right"
                                        align="right"
                                        style={{ width: "10%" }}
                                        body={(rowData) => {
                                            return <MenuDot items={[
                                                {
                                                    label: 'Download Receipt',
                                                    icon: 'pi pi-download',
                                                    command:(e: any) => onDownloadReceiptDonationByYear(toast, donation, rowData.year, donation?.payment_currency_code, setIsLoading),
                                                    description: 'Download receipt as PDF'
                                                },
                                                {
                                                    label: 'Download Certificate',
                                                    icon: 'pi pi-download',
                                                    command:(e: any) => onDownloadCertificateDonationByYear(toast, donation, rowData.year, setIsLoading),
                                                    description: 'Download certificate as PDF'
                                                }
                                            ]}></MenuDot>;
                                            }}></Column>
                                    </DataTable>
                                </div>
                            </div>
                         </div>
                    </div> : <div/>
                    }
            </div>
            <GenerateCertificateDonationDialogComponent
                visible={isShowCreateCertificateDialog}
                onHide={() => setIsShowCreateCertificateDialog(false)}
                donation={donation}
            />
             </form>
            )}   
            </Formik>
        {
            childrendonation && childrendonation.data.length > 0 ?
            <div className="table-donation-detail">
                <div className="headline-children">
                    <h3 className="h3 wi-form-section-title">Subscription History</h3>
                </div>
                <div className="table-detail-container">
                        <div className="table-children">
                            <DataTable 
                                loading={isLoading}
                                value={childrendonation.data}
                                scrollable 
                                scrollHeight="calc(100vh - 166px)"
                                scrollDirection="both"
                                responsiveLayout="scroll"
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} 
                                rowsPerPageOptions={[5,10,20,50]}
                            >
                                {dynamicColumns}
                            </DataTable>
                        </div>
                </div>
            </div> : <div/>
            }
        </div>
      </div>
       )}
       <WIConfirmDialog
          visible={isShowConfirm}
          onHide={() => setIsShowConfirm(false)}
          onConfirm={() => {
            setIsShowConfirm(false);
            markAsPaid();
          }}
          hideLabel='No'
          confirmLabel='Yes'
          className="info-dialog"
          message={
            <>
                <span style={{ fontSize: '18px', fontWeight: '700' }}>This will mark the donation as <b style={{color: '#13735f'}}>succeeded</b> and make it valid in statistics?</span>
            </>
          }
          classIcon="fa-solid fa-info mr-3 dialog-icon"
        />
        <Sidebar 
            visible={isShowSidebarDonor} 
            position="right" 
            className="wi-sidebar-v2 p-sidebar-md sidebar-right" 
            style={{width:'30em'}}
            onHide={() => setIsShowSidebarDonor(false)}>
                <div className="sidebar-content">
                    <div className="headline pt-24 pb-32">
                        <h6>Update donor information</h6>
                    </div>
                    <AddDonorFormComponent
                        countries={countries}
                        fetchCallBack={((donorItem:any) => fetchDonationDetail())}
                        toast={toast}
                        donor={donation}
                        id={donation?.donor_id}
                        onHide={() => setIsShowSidebarDonor(false)}
                    />
                </div>
            </Sidebar>
        <Sidebar
            visible={isShowSidebar} 
            position="right" 
            className="wi-sidebar-v2 p-sidebar-md sidebar-right" 
            style={{width:'35em'}}
            onHide={() => onHideChangeDonor()}>
            <div className="sidebar-content">
                <div className="headline  pt-24 pb-32">
                <h6> Change Donor </h6>
                </div>
                <ChangeDonorForm 
                    donors={donors} 
                    setDonors={setDonors}
                    donationID={donationID}
                    onHide={() => onHideChangeDonor()}
                    donation={donation}
                    fetchDonationDetail={fetchDonationDetail}
                    selectedDonor={selectedDonor}
                    setSelectedDonor={setSelectedDonor}
                    toast={toast}
                />
            </div>
        </Sidebar>
    </Layout>
  );
};

export default DonationDetail;
