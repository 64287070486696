import Axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { env } from '../environment';
import moment from 'moment';
import { setupCache } from 'axios-cache-adapter'
import localforage from 'localforage';
import { buildLoginLink, getExpireTime, resetToken, saveToken } from '../utils/logic';
import AuthManagerAPI from './authManager';

const AXIOS_URL = env.AXIOS_URL;
const AXIOS_DRUAPL_URL = env.DRUPAL_URL;

const paramsSerializer = (params: any) => {
  const searchParams = new URLSearchParams()
  for (const key of Object.keys(params)) {
    const param = params[key]
    if (Array.isArray(param)) {
      for (const p of param) {
        searchParams.append(key, p)
      }
    } else {
      searchParams.append(key, param)
    }
  }
  return searchParams.toString()
}


const forageStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB, 
    localforage.LOCALSTORAGE,
  ],
  name: 'wild-cache',
});


const customCaches = setupCache({
  maxAge: 0, // 0 mean no cache
  exclude: { 
    query: false,
  },
  store: forageStore,
  invalidate: async (config: any, request: any) => {
    if (request.clearCacheEntry) {
      // @ts-ignore
      await config.store.removeItem(config.uuid);
    }
  },
});

export const cacheAge: any = {
  maxAge: 2 * 60 * 1000 // cache data in 2 mins
};

export const AxiosServerInstance = Axios.create({
  baseURL: `${AXIOS_URL}/`,
  timeout: 1800000,
  headers: {
    'Content-Type': 'application/json',
    'wd-signature': env.SIGNATURE,
    'auth-api-key': env.AUTH_KEY
  },
  adapter: customCaches.adapter,
  paramsSerializer
})

export const AxiosDrupalInstance = Axios.create({
  baseURL: `${AXIOS_URL}/`,
  timeout: 1800000,
  headers: {
    'Content-Type': 'application/json',
    // 'wd-signature': '23Live02Slow86-TEST'
  },
  paramsSerializer
})

export class HttpClient {
  constructor(private axiosInstance: AxiosInstance) { }

  request(config: AxiosRequestConfig) {
    config.params = {
      ...config.params,
    }

    return this.axiosInstance.request(config)
  }

  get(url: string, config?: any, headers?: any) {
    const maxAge = config?.maxAge || 0;
    return this.axiosInstance.get(url, { 
      params: config, 
      headers, 
      cache: { 
        maxAge // Override `maxAge` for this request only
      } 
    });
  }

  post(url: string, data: {}, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }
    }

    return this.axiosInstance.post(url, data, config)
  }

  put(url: string, data = {}, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }
    }

    return this.axiosInstance.put(url, data, config)
  }

  patch(url: string, data = {}, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }
    }

    return this.axiosInstance.patch(url, data, config)
  }

  delete(url: string, config?: AxiosRequestConfig) {
    if (config) {
      config.params = {
        ...config.params,
      }

      config.data = {
        ...config.data,
      }
    }

    return this.axiosInstance.delete(url, config)
  }

  all(requests: any[]) {
    return Axios.all(requests)
  }
}

export const AxiosClient = new HttpClient(AxiosServerInstance);
export const AxiosDrupalClient = new HttpClient(AxiosDrupalInstance);

export const refreshJWT = (token: string) => {
  return AxiosDrupalClient.get(`/jwt/token`, undefined, { 'Authorization': 'Bearer ' + token });
}

AxiosServerInstance.interceptors.request.use(
  async config => {
    let accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token') || '';
    let expTime = getExpireTime(accessToken || '');
    let isExpire = !expTime || expTime < moment();
    
    const ignoredUrl = [
      '/auth-v2-service/signin',
      '/auth-v2-service/challenge/accept',
      '/auth-v2-service/token/refresh'
    ];
    if (isExpire && !ignoredUrl.includes(config.url || '')) {
      const res: any = await AuthManagerAPI.refreshAccessToken(refreshToken);
      const result = res && res.status === 200 ? res.data?.result : null;
      if (result) {
        saveToken({...result, refresh_token: refreshToken});
        accessToken = result.access_token;
      } else {
        return Promise.reject({ response: { status: 401 } });
      }
    }

    const newHeaders: typeof config.headers = {
      'Content-Type': 'application/json',
      ...config.headers,
    };

    if(accessToken) {
      newHeaders['Authorization'] = `Bearer ${accessToken}`
    }

    config.headers = newHeaders;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

AxiosServerInstance.interceptors.response.use(
  async response => {
    if(response && (response.status === 200 || response.status === 201)) {
      // refreshJWTToken(); // TODO
    }
    return response;
  },
  async error => {
    const { response } = error;
    if (response && response.status === 401) {
      resetToken();
      if (!window.location.pathname?.includes('/login')) {
        window.location.href = buildLoginLink(`${window.location.pathname || ''}${window.location.search || ''}`);
      }
    }
    return Promise.reject(error);
  }
);