import React from "react";
import _ from "lodash";
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from "formik";
import { Dropdown } from "primereact/dropdown";

const WIFormDropdownV2 = (props: any) => {
  const { name, title, isRequired, disabled, placeholder, filterPlaceholder,
    options, optionLabel, optionValue, appendTo, panelClassName = 'dropdown-panel-bottom-v2',
    filter, filterBy, valueTemplate, itemTemplate, onChange } = props;
  const { values, handleChange, errors, touched } = useFormikContext();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title}{" "}
        {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <Dropdown
        className={`dropdown-component-v2 ${_.get(errors, name) && _.get(touched, name) ? "has-error" : ""}`}
        panelClassName={["dropdown-panel-v2", panelClassName].filter(c => !!c).join(' ')}
        name={name}
        value={_.get(values, name)}
        onChange={onChange || handleChange}
        options={options}
        optionLabel={optionLabel}
        optionValue={optionValue || "uuid"}
        appendTo={appendTo}
        disabled={disabled}
        filter={filter}
        filterBy={filterBy}
        valueTemplate={valueTemplate} 
        itemTemplate={itemTemplate} 
        placeholder={placeholder}
        filterPlaceholder={filterPlaceholder}
      />
      <div className={`${_.get(errors, name) && _.get(touched, name) ? "error" : ""}`}>
        {_.get(touched, name) && _.get(errors, name)}
      </div>
    </div>
  )
}

export default WIFormDropdownV2;