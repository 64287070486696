/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./sidebar-menu.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import left from "../../../assets/images/icons/chevrons-left.svg";
import { buildMenuItems, PARTNER_TYPES, PERMISSIONS_V2, USER_PARTIES } from "../../utils/utils";
import useAuth from "../../../context/useAuth";

const MenuChildrenTemplate = (props: any) => {
  const { children, t, isExpand, onCollapseMenu } = props;

  return (
    <>
    <ul className={`dropdown-menuitem ${isExpand ? "show" : "hidden"} m-0 pl-12 pr-12 `}>
      <div className="dropdown-menuitem-content">
        {children.filter((m: any) => !m.hidden).map((item: any, index: number) => (
          <div key={`dropdown-menuitem-${index}`} className="p-menuitem" style={{minWidth: '240px'}}>
            <Link to={item.url} className="p-menuitem-link" role="menuitem">
              <div className="icon-content">
                {item.icon}
              </div>
              <div className="p-menuitem-text ml-12">{t(item.label)}</div>
            </Link>
          </div>
        ))}
      </div>
      {
      isExpand &&
      <div className="collapse-content">
        <div className="collapse-menu" onClick={() => onCollapseMenu(false)}>
          <img src={left} alt="menu-icon" className="collapse-icon p-10 m-0"/>
        </div>
      </div> 
      }
    </ul>
    </>
  );
};

const MenuItem = (props: any) => {
  const { item_menu, index, t } = props;
  const [isExpand, setIsExpand] = useState(false);
  const path_url = window ? window.location.pathname : "";
  const isMatched = path_url === item_menu.url || (item_menu.url !== "/" && path_url.includes(item_menu.url));

  const onCollapseMenu = (isCollapse: boolean) => {
    setIsExpand((prev: any) => isCollapse)
  }

  return (
      <div 
        className={`p-menuitem ${item_menu?.className} ${isMatched && 'active-menu'}`} 
        key={index}
        onClick={() => onCollapseMenu(!isExpand)}
      >
        {
          item_menu.items ? (
            <>
              <a className="p-menuitem-link" role="menuitem" aria-haspopup="menu"
                aria-expanded={isExpand ? "true" : "false"}>
                <div className="icon-content">
                  {item_menu.icon}
                </div>
                <div className="p-menuitem-text ml-12">{t(item_menu.label)}</div>
              </a>
              <MenuChildrenTemplate children={item_menu.items} t={t} isExpand={isExpand} onCollapseMenu={onCollapseMenu} />
            </>
          ) : 
          <Link to={item_menu.url} className="p-menuitem-link" role="menuitem">
            <div className="icon-content">
              {item_menu.icon}
            </div>
            <div className="p-menuitem-text ml-12">{t(item_menu.label)}</div>
          </Link>
        }
      </div>
  );
};

const Menubar = (props: any) => {
  const { auth } = useAuth();
  const permissions = {
    canViewDashboard: auth?.permissions?.includes(PERMISSIONS_V2.STATISTIC_VIEW),
    canViewUsers: auth?.permissions?.includes(PERMISSIONS_V2.USER_VIEW),
    canViewDonations: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_VIEW),
    canViewDonors: auth?.permissions?.includes(PERMISSIONS_V2.DONOR_VIEW),
    canViewSubscriptions: auth?.permissions?.includes(PERMISSIONS_V2.SUBSCRIPTION_VIEW),
    canViewRegions: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewAreas: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
    canViewPartners: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
    canViewCampaigns: auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
    canViewCodes: auth?.permissions?.includes(PERMISSIONS_V2.CODE_VIEW),
    canViewCMSPages: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_VIEW) && auth?.partner?.type !== PARTNER_TYPES.FUNDRAISER,
    canViewMedia: auth?.permissions?.includes(PERMISSIONS_V2.MEDIA_VIEW),
    canViewGoals: auth?.permissions?.includes(PERMISSIONS_V2.GOAL_VIEW),
    canViewCoupons: auth?.permissions?.includes(PERMISSIONS_V2.COUPON_VIEW),
    canViewUtilities: auth?.permissions?.includes(PERMISSIONS_V2.UTILITY_VIEW),
    canViewGlobalContents: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_VIEW),
    canViewGlobalBlocks: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_BLOCK_VIEW),
  };
  
  const { t } = useTranslation('language', { keyPrefix: 'side_bar_menu' });
  const menuItems = buildMenuItems(permissions);
  
  return (
    <div className="sidebar-menu">
      <div className={`sidebar-menu-content`}>
        <div className="sidebar-menu-container pt-40 pb-40">
          <div className="header-menu mb-32">
            <div className="logo">
              <div className="logo-menu m-0" />
            </div>
          </div>

          <div className="content-menu pl-24 pr-24">
            {menuItems.filter((m: any) => !m.hidden).map((item: any, index: number) => <MenuItem key={index} item_menu={item} index={index} t={t} /> )}
          </div>
          {/* <div className="footer-menu pl-24 pr-24">
            <div className="footer-content">
              <div className="avatar pl-0 pt-8 pb-8 pr-8">
                <img src={avatar} alt="avatar" className="avatar-menu m-0" loading="lazy" />
              </div>
              <div className="name">
                <p className="m-0">Tino K.</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Menubar;