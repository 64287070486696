import * as React from "react"
import { useEffect, useRef, useState } from "react";
import './coupon-manager.scss';
import Layout from '../layout/layout';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { PartnerManagerAPI, LandManagerAPI, CouponsManagerAPI } from '../../services';
import { Toast } from 'primereact/toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { onExportCoupon } from "../../utils/logic";
import { MenuDot, WIPaginator } from "../common";
import { COUPON_STATUS_LIST, LanguageList } from "../../utils/utils";
import {
    buildCouponProgressTemplate,
    buildDefaultBodyTemplate,
    buildCouponQuantityTemplate,
    formatInnerHtmlDataByLanguageBodyTemplate
} from "../common/column-template-table/column-template";
import _ from "lodash";
import moment from "moment";
import CouponFilterComponent from "./components/coupon-filter/coupon-filter-component";
import {
    getCouponObjectSearchValue,
    getCouponObjectWithValues,
    getCouponPayloadConditions
} from "./coupon-manager-util";
import useAuth from "../../context/useAuth";
import { PERMISSIONS_V2 } from "../../components_v2/utils/utils";

export type CouponFilterParameters = {
    pageNo: number;
    range: number;
    search?: string;
    where: {
        or: any[];
        land_id: string[];
        partner_id: string[];
        status: string[];
        dates: string[];
    };
    order: [];
};

const CouponManagerComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
    const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
    const sortField = searchParams?.get('sortField') || 'serial';
    const sortOrder = +(searchParams?.get('sortOrder') || -1);
    const filterLands = searchParams?.get('filterLands') ? searchParams?.get('filterLands')?.split(',') || [] : [];
    const filterPartners = searchParams?.get('filterPartners') ? searchParams?.get('filterPartners')?.split(',') || [] : [];
    const filterStatus = (searchParams?.get('filterStatus') || COUPON_STATUS_LIST.map((c) => c.code).join(',')).split(',');
    const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
    const searchField = searchParams?.get('search') || '';

    const statusTypeAll = 'all';
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lands, setLands] = useState([]);
    const [partners, setPartners] = useState([]);
    const [coupons, setCoupons] = useState({
        data: [],
        totalPage: 0
    });
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [selectedStatusCoupon, setSelectedStatusCoupon] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
    const [language] = useState(LanguageList[0]);
    const [sortConfig, setSortConfig] = useState<any>({
        sortField: sortField,
        sortOrder: sortOrder
    });
    const [filterValues, setFilterValues] = useState<CouponFilterParameters>({
        pageNo: pageNumber,
        range: noRows,
        search: searchField,
        where: {
            or: searchField ? getCouponObjectSearchValue(searchField) : [],
            land_id: filterLands,
            partner_id: filterPartners,
            status: filterStatus,
            dates: filterDates
        },
        order: []
    });
    const navigate = useNavigate();
    const { auth } = useAuth();

    const permissions = {
        canCreateCode: auth?.permissions?.includes(PERMISSIONS_V2.CODE_CREATE),
        canViewPartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
        canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
        canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
      };

    const onBasicPageChange = (event: any) => {
        setPage(event.page);
        setFirst(event.first);
        setRows(event.rows);
    }

    const fetchLandsData = async () => {
        const resLands = await LandManagerAPI.getAllLands();
        if (resLands && resLands.status === 200) {
          setLands(resLands.data.records || []);
        }
    }

    const fetchPartnerData = async () => {
        const resPartner = await PartnerManagerAPI.getAllPartners(false);
        if (resPartner && resPartner.status === 200) {
          setPartners(resPartner.data.records || []);
        }
    }

    const fetchCouponsData = async () => {
        setIsLoading(true);
        const payload = getCouponPayloadConditions(filterValues.where, sortConfig);

        try {
            const resCoupons = await CouponsManagerAPI.getCoupons({
                pageNo: page,
                range: rows, 
                ...payload
            });
          if (resCoupons && resCoupons.status === 200) {
            setIsLoading(false);
            setCoupons({
              data: resCoupons.data.records || [],
              totalPage: resCoupons.data.total || 0,
            });
          }
        } catch (error) {
            setIsLoading(false);
            setCoupons({
                data: [],
                totalPage: 0
            });
        }
    }

    useEffect(() => {
        fetchLandsData();
        fetchPartnerData();
        fetchCouponsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof page === "number") {
            setFilterValues({
                ...filterValues,
                pageNo: page,
                range: rows,
            });
            setSearchParams({
                pageNumber: (page + 1).toString(),
                noRows: rows.toString(),
            }, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page,rows]);

    useEffect(() => {
        if (typeof selectedStatusCoupon === "string") {
          let p = _.cloneDeep({ ...filterValues });
          if (selectedStatusCoupon === statusTypeAll) {
          } else {
            p.where["status"] = [selectedStatusCoupon];
          }
          setFilterValues(p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStatusCoupon]);

    useEffect(() => {
        const searchParams = { 
            pageNumber: (page + 1).toString(),
            noRows: rows.toString(),
            search: filterValues.search,
            sortField: sortConfig.sortField,
            sortOrder: sortConfig.sortOrder,
            filterLands: filterValues.where.land_id?.join(',') || '',
            filterPartners: filterValues.where.partner_id?.join(',') || '',
            filterStatus: filterValues.where.status?.join(',') || '',
            filterDates: filterValues.where.dates?.join(',') || ''
        }
        setSearchParams(_.omitBy(searchParams, (p) => !p), { replace: true });
        fetchCouponsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, sortConfig]);

    const onFilterChange = (values: any) => {
        const { field, value } = values;
        const where_conditions = getCouponObjectWithValues(filterValues.where);
        if (field !== "dates") {
            where_conditions[field] = value;
        } else {
            const dates = value.map((c: any) => moment(c).format("DD/MM/YYYY"));
            where_conditions[field] = dates;
        }
        setFilterValues({
            pageNo: filterValues.pageNo,
            range: filterValues.range,
            where: where_conditions,
            order: filterValues.order,
        });
    };

    const onFilterRemove = async () => {
        let p = _.cloneDeep({ ...filterValues });
        p.where = Object.create({});
        if (selectedStatusCoupon === statusTypeAll) {
          p.where["status"] = [...COUPON_STATUS_LIST.map((c) => c.code)];
        } else {
          p.where["status"] = [selectedStatusCoupon];
        }
        p.search = '';
        setFilterValues(p);
    };

    const onSearchFunc = (searchText: string) => {
        const where_conditions = getCouponObjectWithValues(filterValues.where);
        const or_conditions = getCouponObjectSearchValue(searchText);
        where_conditions["or"] = searchText ? or_conditions : null;
        setFilterValues({
            pageNo: filterValues.pageNo,
            range: filterValues.range,
            where: where_conditions,
            search: searchText,
            order: filterValues.order,
        });
        if (page !== 0) {
            setPage(0);
            setFirst(0);
        }
    };

    const navigateToDetail = (rowData: any) => {
        navigate(`/codes/${rowData.uuid ? rowData.uuid : rowData.data.uuid}`)
    };

    const tableColumns = [
        { field:'serial', header: 'ID', width: "90px", align: 'left' },
        { field: 'name', header: 'Name', width: "calc(15% + 40px)", bodyTemPlate: buildDefaultBodyTemplate, align: 'left' },
        { 
            field: 'partner_name', header: 'Partner', width: "calc(10% + 50px)", align: 'left', disabledSort: false,
            bodyTemPlate: (rowData: any, column: any) =>  {
                const child = rowData[column.field];
                return permissions.canViewPartner && rowData.partner_id && rowData.partner_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/partners/${rowData.partner_id}`}>{child}</Link> : child;
            }
        },
        { 
            field: 'land_name', header: 'Area', width: "calc(10% + 40px)", align: 'left', disabledSort: false,
            bodyTemPlate: (rowData: any, column: any) =>  {
                const child = formatInnerHtmlDataByLanguageBodyTemplate(language)(rowData, column);
                return permissions.canViewArea && rowData.land_id && rowData.land_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/areas/${rowData.land_id}`}>{child}</Link> : child;
            }
        },
        { 
            field: 'region_name', header: 'Region', width: "calc(10% + 20px)", align: 'left', disabledSort: false,
            bodyTemPlate: (rowData: any, column: any) =>  {
                const child = formatInnerHtmlDataByLanguageBodyTemplate(language)(rowData, column);
                return permissions.canViewRegion && rowData.region_id && rowData.region_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/regions/${rowData.region_id}`}>{child}</Link> : child;
            }
        },
        { field: 'no_valid_coupons', header: 'Quantity', width: "140px", bodyTemPlate: buildCouponQuantityTemplate, align: 'left' },
        { field: 'area', header: 'Value (m²)', width: "170px", type:"numeric", bodyTemPlate: buildCouponQuantityTemplate, align: 'left' },
        { field: 'no_valid_coupons', header: 'Progress', width: "calc(55% - 600px)", minWidth: "250px", bodyTemPlate: buildCouponProgressTemplate("normal", "no_valid_coupons", "no_used_coupons"), align: 'center', disabledSort: true },
        // { field: 'expire_date', header: 'EXPIRY DATE', width: "160px", bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY"), align: 'left' },
    ];

    const dynamicColumns = tableColumns.map((col, i) => {
        return <Column
            key={`${col.field}_${i}`}
            field={col.field}
            header={col.header}
            style={{ 
                minWidth: col.minWidth || "none",
                width: col.width 
            }}
            dataType={col.type}
            body={col.bodyTemPlate}
            align={col.align as ColumnAlignType}
            sortable={!col?.disabledSort}
        />;
    });

    const buildMenu = (rowData: any) => {
        const defaultActions = [
        {
            label: "Open",
            description: "Open and manage the codes details",
            icon: "fa-solid fa-arrow-up-right-from-square",
            url: `/codes/${rowData.uuid ? rowData.uuid : rowData.data.uuid}`,
        },
        {
            label: "Export to CSV",
            description: "Export the collection of codes to CSV file",
            icon: "fa-solid fa-file-export",
            command: (e: any) => onExportCoupon(e, rowData, toast),
        }];
        return <MenuDot items={[...defaultActions]}></MenuDot>;
    }

    const onSelectedCell = (values: any) => {
        const { field, rowData } = values;
        if (field.includes("field")) {
            return;
        }
        navigateToDetail(rowData);
    };

    return (
        <Layout>
            <Toast ref={toast} />
            <div className="coupon-manager">
                <div className="manager-container">
                    {/* <h3 className="tilte-headline">Codes</h3> */}
                    <div className="header-containers">
                        <div className="coupon-header-panel">
                            <CouponFilterComponent
                                lang={language.code}
                                filterValues={filterValues}
                                fields={["land_id", "partner_id", "region_id"]}
                                onChange={onFilterChange}
                                onRemove={onFilterRemove}
                                onSearchFunc={onSearchFunc}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Link className="wi-outline-button" to="/codes/create" hidden={!permissions.canCreateCode} >
                                <div className="wi-btn-label">Generate <i className="fa-solid fa-circle-plus ml-2"></i></div>
                            </Link>
                        </div>
                    </div>
                    <div className="table-coupon">
                    <DataTable
                        loading={isLoading}
                        value={coupons.data}
                        scrollable
                        dataKey="uuid"
                        scrollHeight="calc(100vh - 180px)"
                        scrollDirection="both"
                        lazy
                        onSort={(e: any) => {
                            setSortConfig({
                                sortField: e.sortField,
                                sortOrder: e.sortOrder,
                            });
                        }}
                        sortField={sortConfig.sortField}
                        sortOrder={sortConfig.sortOrder}
                        // onRowClick={navigateToDetail}
                        cellSelection
                        selectionMode="single"
                        onSelectionChange={(e) => onSelectedCell(e.value)}
                    >
                        {dynamicColumns}
                        <Column
                            frozen
                            alignFrozen="right"
                            style={{ width: "50px" }}
                            body={buildMenu}
                        ></Column>
                    </DataTable>
                    <WIPaginator
                        first={first}
                        rows={rows}
                        totalRecords={coupons.totalPage}
                        onPageChange={onBasicPageChange}
                    />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CouponManagerComponent;
