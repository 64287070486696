import * as React from "react";
import "./create-land-form.scss";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import { InputText } from 'primereact/inputtext'; 
import { InputNumber } from "primereact/inputnumber";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import {
  validateGeoCoordinate,
  isValidForm,
  showNotification,
  parseStringToFloat,
  parseStringToInt,
  isValidateSpecialCharacter
} from "../../../../utils/logic";
import { createEmptyLand, LandType } from "./landType";
import * as Yup from "yup";
import Leaf from '../../../../images/leaf.svg';
import * as _ from 'lodash';
import { buildTooltipManageTemplate } from "../../../common/column-template-table/column-template";
import LandManagerAPI from "../../../../services/landManager";
import { env } from "../../../../environment";
import { Dropdown } from "primereact/dropdown";
import { LandStatusUtils, LandUseFor } from "../../../../utils/utils";
import useAuth from "../../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../../components_v2/utils/utils";

const CreateLandFormComponent = (props: any) => {
  const { data, toast, fetchLandData, isLoading, setIsLoading } = props;
  const navigate = useNavigate(); 
  const formikRef = useRef(null); 
  const { auth } = useAuth();

  const permissions = {
    canUpdateArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_UPDATE),
  }

  const callCreateLandAPI = async (data: any) => {
    try {
      const res = await LandManagerAPI.createLand(data);
      if (res && res.status === 200) {
        if(res.data?.result?.error?.code) { 
          showNotification("error", "The area code is already existed.", toast);
        } else {
          const uuid = res.data?.result.id;
          showNotification("success", "Generate area successfully", toast);
          return uuid;
        }
        return "";
      }
    } catch (error) {
      console.log(error);
    }
    showNotification("error", "Failed to generate Area", toast);
    return "";
  };

  const callUpdateLandAPI = async (uuid: string, data: any) => {
    try {
      const res = await LandManagerAPI.updateLand(uuid, data);
      if (res && res.status === 200) {
        showNotification("success", "Update Area successfully", toast);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    showNotification("error", "Failed to update Area", toast);
    return false;
  };

  const onPressCreateLand = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && isValidForm(!data.isShowCreate, formikRef.current.dirty, formikRef.current.isValid, true)) {
      //@ts-ignore: Object is possibly 'null'.
      const landData = formikRef?.current.values;
      delete landData['protected_size'];
      landData["size"] = Number(landData.size);
      landData["code"] = landData["code"]?.toLowerCase();
      setIsLoading(true);
      if (data.isShowCreate) {
        const uuid = await callCreateLandAPI(landData);
        if (uuid) {
          navigate(`/areas/${uuid}`);
        }
      } else {
        const isSuccess = await callUpdateLandAPI(data.selectedLand.uuid, landData);
        if (isSuccess) {
          await fetchLandData(data.selectedLand.uuid);
          setIsLoading(false);
        } else {
          //landData["land_logo"] = data.selectedLand.land_logo;
          setIsLoading(false);
          return;
        }
      }
      setIsLoading(false);
    }
  }; 
  const onInitValuesLand = (): LandType => {
    if (!data.isShowCreate && data.selectedLand) {
      let land = data.selectedLand;
      
      return {
        name:  _.isString(land.name) ? land.name : land.name["de"],
        code: land.code,
        status: land.status,
        size: Number(land.size),
        amount_co2_conversion: land.amount_co2_conversion,
        amount_area_conversion: land.amount_area_conversion,
        legal_name: land.legal_name,
        protected_size: land.protected_size,
        region_id: land.region_id,
        land_type: getLandUseFor(land.land_type),
      };
    }

    const newLand = createEmptyLand();

    return {
      ...newLand,
    };
  };

  const validationLandSchema = Yup.object().shape({
    name: Yup.string().required("Area name is required"),
    code: Yup.string().required("Area code is required"),
    status: Yup.string().required("Status is required"),
    amount_co2_conversion: Yup.object().shape({
      eur: Yup.number().nullable().required("eur is require"),
      cad: Yup.number().nullable().required("cad is require"),
      chf: Yup.number().nullable().required("chf is require"),
    }),
    amount_area_conversion: Yup.object().shape({
      eur: Yup.number().nullable().required("eur is require"),
      cad: Yup.number().nullable().required("cad is require"),
      chf: Yup.number().nullable().required("chf is require"),
    }),
    size: Yup.number().required("Size is require")
      .min(1, "Size is invalid"),
    region_id: Yup.string().required("Region is required"),  
    land_type: Yup.string().required("Area Type is required"),
  });

  const onKeyPress = (evt: any) => {
    return (
      ["e", "E", "+", "-", "{", "}", "[", "]"].includes(evt.key) &&
      evt.preventDefault()
    );
  };

  const buildHeaderTemplate = () => {
    const type = "Area";
    const title = `${type} code`;
    const code = data.selectedLand?.code || "";
    const tooltip = `Click to open area page with code ${code}`;
    const URL = `${env.DONATION_URL}?${type.toLowerCase()}=${code}`;
    return buildTooltipManageTemplate(title, code, tooltip, URL, true, true);
  };

  const regionItemTemplate = (option: any) => {
    return <p className="p-0 m-0">{option.name}</p>;
  };

  const getLandUseFor = (code: string) => {
    let item = LandUseFor.find(
      (f) => f.code.toLowerCase() === code.toLowerCase()
    );
    if (item) {
      return item.code;
    }

    return LandUseFor[0].code;
  };

  return (
    <div className="land-form">
      <div className="land-form-container">
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={onInitValuesLand()}
          validationSchema={validationLandSchema}
          onSubmit={(values, { setSubmitting }) => {
            //console.log("values >>>", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            dirty,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="sticky-header-form">
                  <div className="col-12 d-flex align-items-center justify-content-between btn-submit-container">
                    {buildHeaderTemplate()}
                    <Button
                      loading={isLoading}
                      // disabled={ !isValidForm(!data.isShowCreate, dirty, isValid, isChangingDonations) }
                      id="btn-submit-coupons"
                      type="submit"
                      label={`${data.isShowCreate ? "Create" : "Update"}`}
                      className="p-button-rounded btn-submit"
                      icon="pi pi-chevron-right"
                      iconPos="right"
                      onClick={onPressCreateLand}
                      hidden={!permissions.canUpdateArea}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <Accordion activeIndex={0}>
                    <AccordionTab header="Area Information">
                      <div
                        style={{
                          margin: "-1.25rem",
                          padding: "0px 20px",
                          backgroundImage: "url(" + Leaf + ")",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right bottom",
                        }}
                      >
                        <div className="row row-content">
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Area name{" "}
                                <span className="required-label">*</span>
                              </label>
                              <InputText
                                className={`${
                                  touched.name && errors.name
                                    ? "has-error"
                                    : ""
                                }`}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                maxLength={50}
                                disabled={!permissions.canUpdateArea}
                              />
                              <div
                                className={`${
                                  touched.name && errors.name ? "error" : ""
                                }`}
                              >
                                {touched.name && errors.name}
                              </div>
                            </div>
                          </div>
                          {data.isShowCreate && (
                            <div className="col-md-6">
                              <div className="element-form">
                                <label className="label">
                                  Identifier <span className="required-label">*</span>
                                </label>
                                <InputText
                                  className={`${
                                    touched.code && errors.code ? "has-error" : ""
                                  }`}
                                  type="text"
                                  name="code"
                                  onChange={(e:any) => {
                                    const valueText = e.target.value;
                                    if(isValidateSpecialCharacter(valueText)) {
                                      handleChange(e);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.code}
                                  maxLength={20}
                                  disabled={!permissions.canUpdateArea}
                                />
                                <div
                                  className={`${
                                    touched.code && errors.code ? "error" : ""
                                  }`}
                                >
                                  {touched.code && errors.code}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Type <span className="required-label">*</span>
                              </label>
                              <Dropdown
                                className={`${errors.land_type ? "has-error" : ""}`}
                                id="dropdown_land_type"
                                name="land_type"
                                value={values.land_type}
                                options={LandUseFor}
                                onChange={(e) => {
                                  setFieldValue("land_type", e.value, true);
                                }}
                                optionLabel="name"
                                optionValue="code"
                                appendTo="self"
                                disabled={!permissions.canUpdateArea}
                              />
                              <div className={`${errors.land_type ? "error" : ""}`}>
                                {errors.land_type}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">Status <span className="required-label">*</span></label>
                              <Dropdown
                                className={`${errors.status ? "has-error" : ""}`}
                                id="dropdown_status"
                                name="status"
                                value={values.status}
                                options={LandStatusUtils}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                                optionLabel="name"
                                optionValue="code"
                                appendTo="self"
                                disabled={!permissions.canUpdateArea}
                              />
                              <div className={`${errors.status ? "error" : ""}`}>
                                {errors.status}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Region <span className="required-label">*</span>
                              </label>
                              <Dropdown
                                className={`${
                                  touched.region_id && errors.region_id
                                    ? "has-error"
                                    : ""
                                } p-dropdown-custom-search`}
                                value={values.region_id}
                                name="region_id"
                                options={data.regions || []}
                                onChange={(item: any) => {
                                  setFieldValue("region_id", item.value, false);
                                  console.log(errors, "errors");
                                  handleChange(item);
                                }}
                                itemTemplate={regionItemTemplate}
                                optionLabel="name"
                                optionValue="id"
                                appendTo="self"
                                filter
                                filterBy="name"
                                disabled={!permissions.canUpdateArea || values.protected_size > 0}
                              />
                              <div
                                className={`${
                                  touched.region_id && errors.region_id
                                    ? "error"
                                    : ""
                                }`}
                              >
                                {touched.region_id && errors.region_id}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Size{" "}
                                <span className="label-unit">
                                  (m<sup>2</sup>)
                                </span>{" "}
                                <span className="required-label">*</span>
                              </label>
                              <InputNumber 
                                className={`${
                                  touched.size && errors.size ? "has-error" : ""
                                }`}
                                name="size"
                                inputId="locale-german" 
                                value={values.size}
                                disabled={!permissions.canUpdateArea || data.selectedLand.uuid}
                                mode="decimal"
                                locale="de-DE"
                                minFractionDigits={0}
                                onBlur={(e) => {
                                  const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                                  setFieldValue('size', value, true);
                                  setTimeout(() => {
                                      handleBlur(e);
                                  }, 200);
                              }}
                              />
                              <div
                                className={`${
                                  touched.size && errors.size ? "error" : ""
                                }`}
                              >
                                {touched.size && errors.size}
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-md-6">
                              <div className="element-form">
                                  <label className="label">
                                    Legal Name
                                  </label>
                                  <InputText
                                    type="text"
                                    name="legal_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.legal_name}
                                    maxLength={100}
                                    disabled={!permissions.canUpdateArea}
                                  />
                              </div>
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab header="Amount Conversion">
                      <div className="row row-content">
                        <div className="title">C02 (KG)</div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              EUR <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              locale="de-DE"
                              mode="decimal"
                              minFractionDigits={1}
                              maxFractionDigits={3}
                              className={`${
                                touched.amount_co2_conversion?.eur &&
                                errors.amount_co2_conversion?.eur
                                  ? "has-error"
                                  : ""
                              } text-input-inline text-currency-target`}
                              value={values.amount_co2_conversion?.eur}
                              name="amount_co2_conversion.eur"
                              onBlur={(e) => {
                                const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                setFieldValue('amount_co2_conversion.eur', value, false);
                                setTimeout(() => {
                                    handleBlur(e);
                                }, 200);
                              }}
                              disabled={!permissions.canUpdateArea}
                            />
                            <div
                              className={`${
                                errors.amount_co2_conversion?.eur ? "error" : ""
                              }`}
                            >
                              {errors.amount_co2_conversion?.eur}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              CAD <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              locale="de-DE"
                              mode="decimal"
                              minFractionDigits={1}
                              maxFractionDigits={3}
                              className={`${
                                touched.amount_co2_conversion?.cad &&
                                errors.amount_co2_conversion?.cad
                                  ? "has-error"
                                  : ""
                              } text-input-inline text-currency-target`}
                              value={values.amount_co2_conversion?.cad}
                              name="amount_co2_conversion.cad"
                              onBlur={(e) => {
                                const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                setFieldValue('amount_co2_conversion.cad', value, false);
                                setTimeout(() => {
                                    handleBlur(e);
                                }, 200);
                              }}
                              disabled={!permissions.canUpdateArea}
                            />
                            <div
                              className={`${
                                errors.amount_co2_conversion?.cad ? "error" : ""
                              }`}
                            >
                              {errors.amount_co2_conversion?.cad}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              CHF <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              locale="de-DE"
                              mode="decimal"
                              minFractionDigits={1}
                              maxFractionDigits={3}
                              className={`${
                                touched.amount_co2_conversion?.chf &&
                                errors.amount_co2_conversion?.chf
                                  ? "has-error"
                                  : ""
                              } text-input-inline text-currency-target`}
                              value={values.amount_co2_conversion?.chf}
                              name="amount_co2_conversion.chf"
                              onBlur={(e) => {
                                const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                setFieldValue('amount_co2_conversion.chf', value, false);
                                setTimeout(() => {
                                    handleBlur(e);
                                }, 200);
                              }}
                              disabled={!permissions.canUpdateArea}
                            />
                            <div
                              className={`${
                                errors.amount_co2_conversion?.chf ? "error" : ""
                              }`}
                            >
                              {errors.amount_co2_conversion?.chf}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-content">
                        <div className="title">
                          Area (m<sup>2</sup>)
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              EUR <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              mode="decimal"
                              locale="de-DE"
                              minFractionDigits={1}
                              maxFractionDigits={3}
                              className={`${
                                touched.amount_area_conversion?.eur &&
                                errors.amount_area_conversion?.eur
                                  ? "has-error"
                                  : ""
                              } text-input-inline text-currency-target`}
                              value={values.amount_area_conversion?.eur}
                              name="amount_area_conversion.eur"
                              onBlur={(e) => {
                                const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                setFieldValue('amount_area_conversion.eur', value, false);
                                setTimeout(() => {
                                    handleBlur(e);
                                }, 200);
                              }}
                              disabled={!permissions.canUpdateArea}
                            />
                            <div
                              className={`${
                                errors.amount_area_conversion?.eur ? "error" : ""
                              }`}
                            >
                              {errors.amount_area_conversion?.eur}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              CAD <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              mode="decimal"
                              locale="de-DE"
                              minFractionDigits={1}
                              maxFractionDigits={3}
                              className={`${
                                touched.amount_area_conversion?.cad &&
                                errors.amount_area_conversion?.cad
                                  ? "has-error"
                                  : ""
                              } text-input-inline text-currency-target`}
                              value={values.amount_area_conversion?.cad}
                              name="amount_area_conversion.cad"
                              onBlur={(e) => {
                                const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                setFieldValue('amount_area_conversion.cad', value, false);
                                setTimeout(() => {
                                    handleBlur(e);
                                }, 200);
                              }}
                              disabled={!permissions.canUpdateArea}
                            />
                            <div
                              className={`${
                                errors.amount_area_conversion?.cad ? "error" : ""
                              }`}
                            >
                              {errors.amount_area_conversion?.cad}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              CHF <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              mode="decimal"
                              locale="de-DE"
                              minFractionDigits={1}
                              maxFractionDigits={3}
                              className={`${
                                touched.amount_area_conversion?.chf &&
                                errors.amount_area_conversion?.chf
                                  ? "has-error"
                                  : ""
                              } text-input-inline text-currency-target`}
                              value={values.amount_area_conversion?.chf}
                              name="amount_area_conversion.chf"
                              onBlur={(e) => {
                                const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                setFieldValue('amount_area_conversion.chf', value, false);
                                setTimeout(() => {
                                    handleBlur(e);
                                }, 200);
                              }}
                              disabled={!permissions.canUpdateArea}
                            />
                            <div
                              className={`${
                                errors.amount_area_conversion?.chf ? "error" : ""
                              }`}
                            >
                              {errors.amount_area_conversion?.chf}
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateLandFormComponent;
