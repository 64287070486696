import { Toast } from "primereact/toast";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "./edit-donation-detail.scss";
import { Button } from "primereact/button";
import _ from "lodash";
import Layout from "../../../layout/layout";
import { Timeline } from 'primereact/timeline';
import { DonationManagerAPI } from "../../../../services";
import { onDownloadReceipt } from "../../../../utils/donationManagerLogic";
import GenerateCertificateDonationDialogComponent from "../generate-certificate-donation-dialog/generate-certificate-donation-dialog";
import { WITag } from "../../../common";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as Yup from "yup";
import { IDonation, IEvent } from "../../../pages/interfaces/IComponent";
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { SALUTATION_LIST, DONATION_STATUS_LIST, RHYTHM_LIST, DONATION_TYPE_LIST, DONATION_PAYMENT_METHOD_LIST } from "../../../../utils/utils";
import { Menu } from 'primereact/menu';
import { env } from "../../../../environment";
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import CountryService from "../../../../services/country-service";
import m from 'moment';
import AnimateHeight from "react-animate-height";
import { showNotification, generateMapUrl } from '../../../../utils/logic';
import { updateDonationData } from "../../donation-manager.util";

const EditDonationDetail = (props: any) => {
    const NO_ACTION_LIST = ['draft', 'cancelled','refunded'];
    const donationID = window.location.pathname ? window.location.pathname.split("/")[2] : '';
    const [isLoading, setIsLoading] = useState(false);
    const [donation, setDonation] = useState<IDonation>();
    const [parentDonation, setParentDonation] = useState<IDonation>();
    const menu = useRef(null);
    const toast = useRef(null);
    const [isShowCreateCertificateDialog, setIsShowCreateCertificateDialog] = useState(false);
    const [isReceiptLoading, setIsReceiptLoading] = useState(false);
    const [isEditDonation, setIsEditDonation] = useState(false);
    const formikRef = useRef(null);
    const [salutationList, setSalutationList] = useState(SALUTATION_LIST.DE);
    const [eventsPayment, setEventsPayment] = useState([]);
    const showEvents = ['donation.started', 'payment_intent.created', 'payment_intent.succeeded', 'checkout.session.completed', 'certificate.sent.succeeded', 'certificate.sent.failed', 'payment_intent.payment_failed', 'payment_intent.canceled', 'checkout.session.async_payment_failed', 'receipt.sent.succeeded', 'receipt.sent.failed', 'charge.refund.updated', 'payment.refunded', 'charge.dispute.created', 'payment.refund.failed', 'charge.refunded'];
    const showMessageEvent = ['payment_intent.payment_failed', 'checkout.session.async_payment_failed', 'receipt.sent.failed', 'certificate.sent.failed'];
    const showIconEvents = ['payment_intent.payment_failed', 'charge.refund.updated', 'charge.dispute.created', 'checkout.session.async_payment_failed', 'payment.refunded', 'payment_intent.canceled', 'receipt.sent.failed', 'certificate.sent.failed', 'payment.refund.failed', 'charge.refunded'];
    const [countries, setCountries] = useState([]);
    const [isError, setIsError] = useState({
        paymentDate: false,
        paymentReceviedDate: false
    });

    const handleDisableIcon = () => {
        return NO_ACTION_LIST.some((item) => item === donation?.status?.toLowerCase())
    };

    const itemsDonationAction = [
        {
            disabled: isEditDonation,
            label: 'Edit',
            icon: 'pi pi-file-edit',
            command: () => setIsEditDonation(true),
            className: 'item-menu'
        },
        {
            disabled: true,
            className: 'item-menu',
            label: 'Download Certificate',
            icon: 'pi pi-download',
            command: (e: any) => onGenerateCertificateDialog(e)
        },
        {
            disabled: true,
            className: 'item-menu',
            label: 'Download Receipt',
            icon: 'pi pi-download',
            command: () => {
                setIsReceiptLoading(true);
                onDownloadReceipt(toast, donation).then(() => {
                    setIsReceiptLoading(false);
                });
            }
        },
        {
            disabled: true,
            className: 'item-menu',
            label: 'Cancel',
            icon: 'pi pi-times',
        },
        {
            disabled: true,
            className: 'item-menu',
            label: 'Refund',
            icon: 'pi pi-times',
        }
    ];

    const customizedMarker = (item: any) => {
        return (
            <span className="custom-marker shadow-1" style={{ backgroundColor: item?.color }}>
                <i className={item?.icon}></i>
            </span>
        );
    };

    const customizedContent = (item: any) => {
        return (
            <div className="custome-content-timeline">
                <div className="title-event">
                    {item?.name}
                </div>
                <div className="message-event">
                    {item?.message}
                </div>
                <div className="date-event">
                    {item?.updated_at}
                </div>
            </div>
        );
    };

    const onGenerateCertificateDialog = (e: any) => {
        setIsShowCreateCertificateDialog(true);
    };

    const updateFilterPaymentsDonation = (events: IEvent[]) => {
        return events.filter((item: IEvent) => {
            if(showEvents.some(itemEvent => itemEvent === item.event_type)) {
                return item;
            }
        })
    }

    const convertNameEvents = (events: IEvent[]) => {
        if(events) {
            return events.map((itemEvent: IEvent) => {
                switch(itemEvent.event_type) {
                    case 'donation.started': itemEvent.name = 'Initiated'; break; 
                    case 'payment_intent.created': itemEvent.name = 'Payment Initiated'; break; 
                    case 'payment_intent.succeeded': itemEvent.name = 'Payment - Received'; break; 
                    case 'checkout.session.completed': itemEvent.name = 'Payment - Succeeded'; break; 
                    case 'certificate.sent.succeeded': itemEvent.name = 'Certificate - Sent'; break; 
                    case 'certificate.sent.failed': itemEvent.name = 'Certificate - Not sent'; break; 
                    case 'payment_intent.payment_failed': itemEvent.name = 'Payment - Failed'; break; 
                    case 'payment_intent.canceled': itemEvent.name = 'Payment - Cancelled'; break; 
                    case 'checkout.session.async_payment_failed': itemEvent.name = 'Payment - Failed'; break; 
                    case 'receipt.sent.succeeded': itemEvent.name = 'Receipt - Sent'; break; 
                    case 'receipt.sent.failed': itemEvent.name = 'Receipt - Not sent'; break; 
                    case 'charge.refund.updated': itemEvent.name = 'Payment - Refunded'; break; 
                    case 'payment.refunded': itemEvent.name = 'Payment - Refunded'; break;
                    case 'charge.dispute.created': itemEvent.name = 'Payment - Refunded'; break;
                    case 'payment.refund.failed': itemEvent.name = 'Payment - Not refunded'; break;
                    case 'charge.refunded': itemEvent.name = 'Payment - Refunded'; break;
                    default: itemEvent.name = itemEvent.event_type?.split(".").join(" ") || itemEvent.event_type; break;
                }
                return {
                    ...itemEvent
                }
            })
        }
    }

    const updateDateEvents = (itemEvent: any, donationData: any) => {
        if(itemEvent && donationData) {
            if(donationData?.is_imported && donationData?.events) {
                if(itemEvent.event_type === 'checkout.session.completed') {
                    return formDate(donationData?.payment_date);
                }
                if(itemEvent.event_type === 'payment_intent.succeeded') {
                    return formDate(donationData?.payment_received_date);
                }
            }
            return moment.tz(new Date(itemEvent.updated_at), "Europe/Berlin").format("MMM DD, YYYY hh:mm A");
        }
    }

    const updateFlowPaymentDonation = (donationData: IDonation) => {
        if(donationData) {
            let sorted: any = donationData.events || [];
            if(donationData?.status === 'cancelled') {
                const isPaymentIntentCancelled = sorted.find((event: any) => event.event_type === 'payment_intent.canceled');
                const isCheckoutSessionExpired = sorted.find((event: any) => event.event_type === 'checkout.session.expired');

                if(!isPaymentIntentCancelled && isCheckoutSessionExpired) {
                    sorted = sorted.map((event: any) => {
                        if(event.event_type === 'checkout.session.expired') {
                            return {
                                ...event,
                                event_type: 'payment_intent.canceled'
                            }
                        }
                        return event;
                    })
                }
            }

            sorted =  updateFilterPaymentsDonation(sorted);
            sorted = _.sortBy(sorted, 'updated_at').reverse().map((itemEvent) => {
                return {
                    ...itemEvent,
                    icon: showIconEvents.some(event => event === itemEvent.event_type) ? 'pi pi-times' : 'pi pi-check', 
                    color: showIconEvents.some(event => event === itemEvent.event_type) ? '#E52322' : '#94C24A',
                    updated_at: updateDateEvents(itemEvent, donationData),
                    message : showMessageEvent.some(event => event === itemEvent.event_type) ? itemEvent.message : ''
                }
            });

            sorted = convertNameEvents(sorted);

            const paymentSucceeded = sorted.findIndex((c: any) => c.event_type === 'checkout.session.completed'); // Payment - Succeeded
            const paymentReceived = sorted.findIndex((c: any) => c.event_type === 'payment_intent.succeeded'); // Payment - Received
            if ((paymentSucceeded || paymentSucceeded === 0) && paymentReceived && paymentSucceeded < paymentReceived) {
                const temp = sorted[paymentSucceeded];
                sorted[paymentSucceeded] = sorted[paymentReceived];
                sorted[paymentReceived] = temp;
            }

            setEventsPayment(sorted.filter((event: IEvent) => event));            
        }
    }

    const fetchCountryData = async () => {
        try {
            const res = await CountryService.getCountries();
            if (res.status === 200 && res.data.data) {
              setCountries(res.data.data);
            } else {
                setCountries([]);
            }
        } catch (error) {
            setCountries([]);
        }
      };

    const fetchDonationDetail = async () => {
        setIsLoading(true);
        try {
            const res = await DonationManagerAPI.getDonation(donationID);
            if (res && res.status === 200) {
                const donationData = updateDonationData(res.data);

                setDonation(donationData);
                updateFlowPaymentDonation(donationData);
                try {
                    if(res.data.parent_donation_id) {
                        const resParentDonation = await DonationManagerAPI.getDonation(res.data.parent_donation_id);
                        if (resParentDonation && resParentDonation.status === 200) {
                            const parentDonationData = updateDonationData(resParentDonation.data);
                            setParentDonation(parentDonationData);
                            setIsLoading(false);
                        }
                    }
                } catch (error) {
                    setIsLoading(false);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const formDate = (value: any) => {
        if(value) {
            const date = m(new Date(value), 'MMM DD, YYYY HH:mm:ss').utc().add(1, 'hour').format("MMM DD, YYYY HH:mm:ss");
            return date.replace("01:00:00", "00:00:00");
        }
        return '-';
    }

    const renderParentName = () => {
        return parentDonation ? `${parentDonation.donation_serial}` : '';
    }

    const renderPaymentMethod = () => {
        return donation?.payment_method || '-'
    }

    const navigateToParentName = () => {
        if(donation) {
            return donation?.parent_donation_id || '#';
        }
    }

    const renderDonationType = () => {
        const donationType: any = DONATION_TYPE_LIST.find(item => item.code === donation?.donation_type);
        return donationType?.name?.de || '-'
    }

    const renderSalutationName = () => {
        const salutation = SALUTATION_LIST.DE.find(item => item.key === donation?.payment_salutation);
        return salutation?.value || '-'
    }

    const renderCountryName = () => {
        if(donation) {
            const country: any = countries.find((countryItem: any) => countryItem.ISO2 === donation.payment_country);
            return country?.name || donation?.payment_country
        }
    }

    const renderRhythm = () => {
        if(donation) {
            const rhythm: any = RHYTHM_LIST.find((rhythmItem: any) => rhythmItem.code === donation.rhythm);
            return rhythm?.name || donation?.rhythm;
        }
    }

    const navigatgeToMap = (donation: any) => {
        if(donation) {
            return  `${env.CMS_URL || 'http://wild.appbisweb.com'}/map?id=${generateMapUrl(donation)}`;
        }
        return '#'
    }


    const navigateBaseTransactionID = () => {
        if(donation) {
            if(donation.payment_transaction_id && donation.payment_transaction_id.includes("pi_")) {
                return `${env.STRIPE_URL}${donation.payment_transaction_id}`;
            }
            return `${env.PAYAL_URL}${donation.payment_transaction_id}`;
        }
        return '#';
    }

    const updateDonationDetail = async () => {
        try {
            // @ts-ignore: Object is possibly 'null'.
            const { values, dirty, errors } = formikRef.current;
            
            if(dirty && _.isEmpty(errors) && !isError.paymentDate && !isError.paymentReceviedDate) {
                setIsLoading(true);

                const paymentDate = new Date(m(values.payment_date).format("MM/DD/YY 00:00:00"))
                const paymentReceived_date = new Date(m(values.payment_received_date).format("MM/DD/YY 00:00:00"))

                const donationUpdate = {
                    ...values,
                    payment_date: m.utc(m(new Date(paymentDate)).format("MM/DD/YYYY HH:mm:ss")).add(-1, "hour"),
                    payment_received_date: m.utc(m(new Date(paymentReceived_date)).format("MM/DD/YYYY HH:mm:ss")).add(-1, "hour"),
                    updated_at: m.utc(m(new Date()).format("MM/DD/YYYY HH:mm:ss")),
                    campaign_id: values.campaign_id ? values.campaign_id : null,
                    personalize_certificate_reason: donation?.donation_type === 'code' ? JSON.stringify({de: values.personalize_certificate_reason, en : values.personalize_certificate_reason }) : values.personalize_certificate_reason
                }
    
                setIsError({
                    paymentDate: false,
                    paymentReceviedDate: false
                })
    
                const resUpdate = await DonationManagerAPI.updateDonation(donationID, donationUpdate);
                if (resUpdate && resUpdate.status === 200) {
                    await fetchDonationDetail();
                    setIsEditDonation(false);
                    setIsLoading(false);
                    showNotification("success", "Update donation details successfully", toast);
                } else {
                    setIsLoading(false);
                    showNotification("error", "Failed to update donation details", toast);
                }
            } 
        } catch (error) {
            setIsLoading(false);
            showNotification("error", "Failed to update donation details", toast);
        }
    }

    const initialDonationFormValues = () => {
        return {
            personalize_certificate: donation && donation.personalize_certificate !== null ? donation.personalize_certificate : false,
            personalize_certificate_display_name: donation && donation.personalize_certificate_display_name !== null ? donation.personalize_certificate_display_name : '',
            personalize_certificate_reason: donation && donation.personalize_certificate_reason !== null ? donation.personalize_certificate_reason : '',
            note: donation && donation.notes !== null ? donation?.notes?.length > 0 ? donation?.notes[0].message : '' : '',
            payment_salutation: donation && donation.payment_salutation !== null ? donation.payment_salutation : '',
            payment_first_name: donation && donation.payment_first_name !== null ? donation.payment_first_name : '',
            payment_last_name: donation && donation.payment_last_name !== null ? donation.payment_last_name : '',
            payment_email: donation && donation.payment_email !== null ? donation.payment_email : '',
            payment_address: donation && donation.payment_address !== null ? donation.payment_address : '',
            payment_additional_address: donation && donation.payment_additional_address !== null ? donation.payment_additional_address : '',
            payment_post_code: donation && donation.payment_post_code !== null ? donation.payment_post_code : '',
            payment_city: donation && donation.payment_city !== null ? donation.payment_city : '',
            payment_country: donation && donation.payment_country !== null ? donation.payment_country : '',
            as_company: donation && donation.as_company !== null ? donation.as_company : false,
            payment_company_name: donation && donation.payment_company_name !== null ? donation.payment_company_name : '',
            payment_date: donation && donation.payment_date !== null ? donation.payment_date : '',
            payment_received_date: donation && donation.payment_received_date !== null  ? donation.payment_received_date: '',
            status: donation && donation.status !== null ? donation?.status : '',
            payment_transaction_id: donation && donation.payment_transaction_id !== null ? donation.payment_transaction_id : '',
            area: donation && donation.area !== null ? donation.area : '',
            land_id: donation && donation.land_id !== null ? donation.land_id : '',
            campaign_id: donation && donation.campaign_id !== null ? donation.campaign_id : '',
            nw_geo_coordinate: donation && donation.nw_geo_coordinate !== null ? donation.nw_geo_coordinate : '',
            payment_method: donation && donation.payment_method_code !== null ? donation.payment_method_code : '',
            donation_type: donation && donation.donation_type !== null ? donation.donation_type : '',
            rhythm: donation && donation.rhythm !== null ? donation.rhythm : ''
        }
      };

    useEffect(() => {
        fetchCountryData();
        fetchDonationDetail();
    }, []);
    
  return (
    <Layout>
      <Toast ref={toast} />
      {isLoading ? (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        ) : (
      <div className="donation-edit-detail">
        <div className="donation-detail__header-fixed"></div>
        <div className="donation-detail__header">
            <div className="header-container">
                <h3>Donation Details</h3>
                <div className="btn-action-container">
                    <div className="donation-serial">
                        {donation?.donation_serial || ''}
                    </div>
                    <Menu model={itemsDonationAction} popup ref={menu} id="popup_menu" />
                    <Button 
                        icon="pi pi-ellipsis-v" 
                        // @ts-ignore: Object is possibly 'null'.
                        onClick={(event) => menu.current.toggle(event)} 
                        aria-controls="popup_menu" 
                        aria-haspopup 
                    />
                </div>
            </div>
            <div className="row row-amount">
                        <div className="amount">
                            <div className="amount-container">
                                <div className="value-amount"> { donation ? parseFloat(donation.donation_money?.toString())?.toLocaleString('de-DE') : ''} </div>
                                <div className="currency-amount">{ donation?.payment_currency_code?.toUpperCase() || ''} </div>
                                <div className="status-amount"> <WITag value={donation?.status}></WITag></div>
                            </div>
                            {
                                isEditDonation &&
                                <div className="edit-action">
                                    <div className="save-action">
                                        <Button
                                            type="submit"
                                            icon="pi pi-check"
                                            className="p-button-success"
                                            onClick={() => updateDonationDetail()}
                                        />
                                        </div>
                                    <div className="cancel-action">
                                        <Button
                                            icon="pi pi-times"
                                            className="p-button-danger"
                                            onClick={() => {
                                                // @ts-ignore: Object is possibly 'null'.
                                                formikRef.current.resetForm();
                                                setIsEditDonation(false)
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                    </div>
                </div>
            </div>
        <div className="donation-detail__content">
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialDonationFormValues()}
                onSubmit={(values, actions) => {
                // nothing to do
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                }) => (
            <form onSubmit={handleSubmit}>
                <div className="donation-detail__container">
                    <div className="row first-row">
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h5>Donation Information</h5>
                                </div>
                                <div className="donation-detail__information-content">
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Forest size</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.area && errors.area ? 'has-error' : ''}`}
                                                        type="number"
                                                        name="area"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.area}
                                                    />
                                                </div> :
                                            <p>{ donation ? parseFloat(donation.area.toString())?.toLocaleString('de-DE') : '-' } m²</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Area</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.land_id && errors.land_id ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="land_id"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.land_id}
                                                    />
                                                </div> :
                                                donation?.land?.uuid ?
                                                <a href={`/areas/${donation?.land?.uuid}` || '#'} target="_blank" >{ donation?.land?.name['de'] || '' } </a>
                                                : <p>-</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Campaign</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                <input
                                                    className={`${touched.campaign_id && errors.campaign_id ? 'has-error' : ''}`}
                                                    type="text"
                                                    name="campaign_id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.campaign_id}
                                                />
                                            </div> :
                                                donation?.campaign?.uuid ?
                                                <a href={`/campaigns/${donation?.campaign?.uuid}` || '#'} target="_blank" >{ donation?.campaign?.name['de'] || '' } </a>
                                                : <p>-</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Geo-coordinate (DMS)</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ?
                                                    <div className="edit-donation-field">
                                                        <input
                                                            className={`${touched.nw_geo_coordinate && errors.nw_geo_coordinate ? 'has-error' : ''}`}
                                                            type="text"
                                                            name="nw_geo_coordinate"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.nw_geo_coordinate}
                                                            maxLength={320}
                                                        />
                                                    {errors.nw_geo_coordinate && touched.nw_geo_coordinate ? (<small className="error">{errors.nw_geo_coordinate}</small>) : null}
                                                </div> 
                                            :
                                                donation?.nw_geo_coordinate ?
                                                <a href={navigatgeToMap(donation)} target="_blank" >{ donation?.nw_geo_coordinate || ''}</a>
                                                : <p>-</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Payment method</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ? 
                                                <div className="edit-donation-field">
                                                <Dropdown
                                                    id="dropdown_payment_method"
                                                    value={values.payment_method}
                                                    options={DONATION_PAYMENT_METHOD_LIST}
                                                    onChange={(item) => {
                                                        if(item && item.value) {
                                                            const paymentMethodItem: any = DONATION_PAYMENT_METHOD_LIST.find(payItem => payItem.code === item.value);
                                                            setFieldValue('payment_method', paymentMethodItem.code, false);
                                                        }
                                                        setFieldValue('payment_method', item.value, false);
                                                    }}
                                                    optionLabel="name.de"
                                                    optionValue="code"
                                                    appendTo="self"
                                                    showClear
                                                />
                                            </div> 
                                            :
                                            <p>{ renderPaymentMethod() }</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Status</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ? 
                                                <div className="edit-donation-field">
                                                    <Dropdown
                                                        id="dropdown_status"
                                                        value={values.status}
                                                        options={DONATION_STATUS_LIST}
                                                        onChange={(item) => {
                                                            if(item && item.value) {
                                                                const statusItem: any = DONATION_STATUS_LIST.find(status => status.code === item.value);
                                                                setFieldValue('status', statusItem.code, false);
                                                            }
                                                            setFieldValue('status', item.value, false);
                                                        }}
                                                        optionLabel="name.de"
                                                        optionValue="code"
                                                        appendTo="self"
                                                        showClear
                                                    />
                                            </div> 
                                            :
                                            <p><WITag value={donation?.status}></WITag></p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Transaction ID</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ? 
                                                <div className="edit-donation-field">
                                                <input
                                                    className={`${touched.payment_transaction_id && errors.payment_transaction_id ? 'has-error' : ''}`}
                                                    type="text"
                                                    name="payment_transaction_id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.payment_transaction_id}
                                                    maxLength={320}
                                                />
                                                {/* {errors.payment_transaction_id && touched.payment_transaction_id ? (<small className="error">{errors.payment_transaction_id}</small>) : null} */}
                                            </div> :
                                                donation?.is_imported ? 
                                                <p>{donation?.payment_transaction_id || '-'}</p> :
                                                <a href={navigateBaseTransactionID()} target="_blank" >{ donation?.payment_transaction_id || ''}</a>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Donated date</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_date && errors.payment_date ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_date"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_date}
                                                    />
                                                </div>
                                                :
                                                <p>{formDate(donation?.payment_date)}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Payment received date</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_received_date && errors.payment_received_date ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_received_date"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_received_date}
                                                    />
                                                </div>
                                                :
                                                <p>{formDate(donation?.payment_received_date)}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Last update date</label>
                                        </div>
                                        <div className="col-md-8">
                                            <p>{ formDate(donation?.updated_at)}</p>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Donation type</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ? 
                                                <div className="edit-donation-field">
                                                    <Dropdown
                                                        id="dropdown_donation_type"
                                                        value={values.donation_type}
                                                        options={DONATION_TYPE_LIST}
                                                        onChange={(item) => {
                                                            if(item && item.value) {
                                                                const donationTypeItem: any = DONATION_TYPE_LIST.find(donationType => donationType.code === item.value);
                                                                setFieldValue('donation_type', donationTypeItem.code, false);
                                                            }
                                                            setFieldValue('donation_type', item.value, false);
                                                        }}
                                                        optionLabel="name.de"
                                                        optionValue="code"
                                                        appendTo="self"
                                                        showClear
                                                    />
                                            {/* {errors.donation_type && touched.donation_type ? (<small className="error">{errors.donation_type}</small>) : null} */}
                                            </div> :
                                            <p className="textDecorator">{ renderDonationType() }</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Rhythm</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                isEditDonation ? 
                                                <div className="edit-donation-field">
                                                    <Dropdown
                                                        id="dropdown_rhythm"
                                                        value={values.rhythm}
                                                        options={RHYTHM_LIST}
                                                        onChange={(item) => {
                                                            if(item && item.value) {
                                                                const rhythmItem: any = RHYTHM_LIST.find(rhythm => rhythm.code === item.value);
                                                                setFieldValue('rhythm', rhythmItem.code, false);
                                                            }
                                                            setFieldValue('rhythm', item.value, false);
                                                        }}
                                                        optionLabel="name"
                                                        optionValue="code"
                                                        appendTo="self"
                                                        showClear
                                                    />
                                                {/* {errors.rhythm && touched.rhythm ? (<small className="error">{errors.rhythm}</small>) : null} */}
                                            </div>
                                            :
                                            <p>{ renderRhythm() || 'Once'}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-4">
                                            <label>Parent donation</label>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                parentDonation?.donation_serial ? 
                                                <a href={navigateToParentName()} target="_blank" >{renderParentName()} </a>
                                                :
                                                <p>-</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h5>Donor Information</h5>
                                </div>
                                <div className="donation-detail__information-content">
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Salutation</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <Dropdown
                                                        id="dropdown_salutation"
                                                        value={values.payment_salutation}
                                                        options={salutationList}
                                                        onChange={(item) => {
                                                            if(item && item.value) {
                                                                const salutationItem: any = salutationList.find(salu => salu.key === item.value);
                                                                setFieldValue('payment_salutation', salutationItem.key, false);
                                                            }
                                                            setFieldValue('payment_salutation', item.value, false);
                                                        }}
                                                        optionLabel="value"
                                                        optionValue="key"
                                                        appendTo="self"
                                                        showClear
                                                    />
                                                    {/* {errors.payment_salutation && touched.payment_salutation ? (<small className="error">{errors.payment_salutation}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ renderSalutationName() }</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        isEditDonation ?
                                        <div>
                                            <div className="row content-row">
                                                <div className="col-md-5">
                                                    <label>First name</label>
                                                </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_first_name && errors.payment_first_name ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_first_name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_first_name}
                                                        maxLength={30}
                                                    />
                                                    {/* {errors.payment_first_name && touched.payment_first_name ? (<small className="error">{errors.payment_first_name}</small>) : null} */}
                                                </div>
                                                </div>
                                            </div>
                                            <div className="row content-row">
                                                <div className="col-md-5">
                                                    <label>Last name</label>
                                                </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_last_name && errors.payment_last_name ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_last_name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_last_name}
                                                        maxLength={30}
                                                    />
                                                    {/* {errors.payment_last_name && touched.payment_last_name ? (<small className="error">{errors.payment_last_name}</small>) : null} */}
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <p>{ donation?.payment_first_name  || ''} { donation?.payment_last_name  || ''}</p>
                                            </div>
                                        </div>
                                    }

                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Email</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_email && errors.payment_email ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_email}
                                                        maxLength={320}
                                                    />
                                                    {/* {errors.payment_email && touched.payment_email ? (<small className="error">{errors.payment_email}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ donation?.payment_email || '-'}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Address</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_address && errors.payment_address ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_address"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_address}
                                                        maxLength={100}
                                                    />
                                                    {/* {errors.payment_address && touched.payment_address ? (<small className="error">{errors.payment_address}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ donation?.payment_address || '-'}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Additional address</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_additional_address && errors.payment_additional_address ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_additional_address"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_additional_address}
                                                        maxLength={100}
                                                    />
                                                    {/* {errors.payment_additional_address && touched.payment_additional_address ? (<small className="error">{errors.payment_additional_address}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ donation?.payment_additional_address || '-'}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Postal code</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_post_code && errors.payment_post_code ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_post_code"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_post_code}
                                                        maxLength={10}
                                                    />
                                                    {/* {errors.payment_post_code && touched.payment_post_code ? (<small className="error">{errors.payment_post_code}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ donation?.payment_post_code || '-'}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>City</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_city && errors.payment_city ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_city"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_city}
                                                        maxLength={85}
                                                    />
                                                    {/* {errors.payment_city && touched.payment_city ? (<small className="error">{errors.payment_city}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ donation?.payment_city || '-'}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Country</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <Dropdown
                                                        id="dropdown_countries"
                                                        value={values.payment_country}
                                                        options={countries}
                                                        onChange={(item: any) => {
                                                            setFieldValue("payment_country", item.value, true);
                                                        }}
                                                        optionLabel="name"
                                                        optionValue="ISO2"
                                                        appendTo="self"
                                                        filter
                                                        filterBy="name"
                                                        showClear
                                                    />
                                                    {/* {errors.payment_country && touched.payment_country ? (<small className="error">{errors.payment_country}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ renderCountryName() }</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Donated as company</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <Checkbox 
                                                        name="as_company"
                                                        inputId="binary" 
                                                        checked={values.as_company} 
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            if(!e.value) {
                                                                setFieldValue('payment_company_name', '', false);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <p>{ donation?.as_company ? 'Yes' : 'No' || 'No'}</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        isEditDonation ? 
                                        <AnimateHeight
                                                duration={300}
                                                height={values?.as_company ? `auto` : 0}
                                        >
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Company name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.payment_company_name && errors.payment_company_name ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="payment_company_name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.payment_company_name}
                                                        maxLength={50}
                                                    />
                                                    {/* {errors.payment_company_name && touched.payment_company_name ? (<small className="error">{errors.payment_company_name}</small>) : null} */}
                                                </div>
                                            </div>
                                        </div>
                                        </AnimateHeight>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Company name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <p>{ donation?.payment_company_name || '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h5>Timeline</h5>
                                </div>
                                <div className="donation-detail__information-content">
                                    <Timeline 
                                        value={eventsPayment.filter(e => !!e)} 
                                        className="customized-timeline"
                                        marker={customizedMarker}
                                        content={customizedContent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 donation-item">
                            <div className="donation-detail__information">
                                <div className="donation-detail__information-title">
                                    <h5>Other Information</h5>
                                </div>
                                <div className="donation-detail__information-content">
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Personalize certificate</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <Checkbox 
                                                        inputId="binary" 
                                                        name="personalize_certificate"
                                                        checked={values.personalize_certificate} 
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            if(!e.value) {
                                                                setFieldValue('personalize_certificate_display_name', '', false);
                                                                setFieldValue('personalize_certificate_reason', '', false);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <p>{ donation?.personalize_certificate ? 'Yes' : 'No' || 'No'}</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        isEditDonation ?
                                        <AnimateHeight
                                            duration={300}
                                            height={values?.personalize_certificate ? `auto` : 0}
                                        >
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Certificate name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.personalize_certificate_display_name && errors.personalize_certificate_display_name ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="personalize_certificate_display_name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.personalize_certificate_display_name}
                                                        maxLength={40}
                                                    />
                                                    {/* {errors.personalize_certificate_display_name && touched.personalize_certificate_display_name ? (<small className="error">{errors.personalize_certificate_display_name}</small>) : null} */}
                                                </div>
                                            </div>
                                        </div>
                                        </AnimateHeight>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Certificate name</label>
                                            </div>
                                            <div className="col-md-7">
                                                <p>{ donation?.personalize_certificate_display_name || '-'}</p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        isEditDonation ?
                                        <AnimateHeight
                                            duration={300}
                                            height={values?.personalize_certificate ? `auto` : 0}
                                        >
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Occasion</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="edit-donation-field">
                                                    <input
                                                        className={`${touched.personalize_certificate_reason && errors.personalize_certificate_reason ? 'has-error' : ''}`}
                                                        type="text"
                                                        name="personalize_certificate_reason"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.personalize_certificate_reason}
                                                        maxLength={50}
                                                    />
                                                    {/* {errors.personalize_certificate_reason && touched.personalize_certificate_reason ? (<small className="error">{errors.personalize_certificate_reason}</small>) : null} */}
                                                </div>
                                            </div>
                                        </div>
                                        </AnimateHeight>
                                        :
                                        <div className="row content-row">
                                            <div className="col-md-5">
                                                <label>Occasion</label>
                                            </div>
                                            <div className="col-md-7">
                                                <p>{ donation?.personalize_certificate_reason || '-'}</p>
                                            </div>
                                        </div>
                                    }

                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Subscribe to the newsletter</label>
                                        </div>
                                        <div className="col-md-7">
                                            <p>{ donation?.subscribe_newsletter || 'No'}</p>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Is manually added</label>
                                        </div>
                                        <div className="col-md-7">
                                            <p>{ donation?.is_imported ? 'Yes' : 'No' || 'No'}</p>
                                        </div>
                                    </div>
                                    <div className="row content-row">
                                        <div className="col-md-5">
                                            <label>Note</label>
                                        </div>
                                        <div className="col-md-7">
                                            {
                                                isEditDonation ?
                                                <div className="edit-donation-field">
                                                    <InputTextarea 
                                                        className={`${touched.note && errors.note ? 'has-error' : ''}`}
                                                        name="note"
                                                        value={values.note}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        rows={5} 
                                                        autoResize 
                                                    />
                                                    {/* {errors.note && touched.note ? (<small className="error">{errors.note}</small>) : null} */}
                                                </div>
                                                :
                                                <p>{ donation ? donation?.notes?.length > 0 ? donation?.notes[0].message : '-' : '-'}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <GenerateCertificateDonationDialogComponent
                visible={isShowCreateCertificateDialog}
                onHide={() => setIsShowCreateCertificateDialog(false)}
                donation={donation}
            />
             </form>
            )}   
            </Formik>
        </div>
      </div>
       )}
    </Layout>
  );
};

export default EditDonationDetail;
