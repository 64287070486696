import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Layout from "../../../components_v2/common/layout/layout";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { CampaignManagerAPI } from "../../../services";
import { Toast } from "primereact/toast";
import MenuDot from "../../../components_v2/common/menu-dot/menu-dot";
import { formatDateByFormatBodyTemplate } from "../../common/column-template-table/column-template";
import _ from "lodash";
import { Sidebar } from "primereact/sidebar";
import { CampaignCouponStatus, TypeCoupons } from "../../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import useAuth from "../../../context/useAuth";
import { Button } from "primereact/button";
import { ReactComponent as PlusSvg } from "../../../assets/images/icons/add-plus.svg";
import WiStatus from "../../../components_v2/common/wi-status/wi-status";
import { STATUS_LIST } from "../../../components_v2/utils/utils";
import CreateCouponForm from "./components/create-coupon-form";
import DownloadReceiptForm from "./components/download-receipt-form";
import { env } from "../../../environment";
import { Link } from "react-router-dom";

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    status: string[];
    campaign_id: string[];
  };
  order: [];
};

const CouponManager = () => {
  const [coupons, setCoupons] = useState<any[]>([
    {
      uuid: uuidv4(),
      coupon: "CHRISTMAS2024",
      campaignId: "e2140bf5-7c34-4fdb-8c7b-33213b4fe074",
      couponType: "off-once",
      start_date: new Date("2023-12-01"),
      end_date: new Date("2023-12-31"),
      budget: 10000,
      limit: 500,
      couponStatus: CampaignCouponStatus[0].key,
      currency: "eur",
      receiptStatus: "Not Yet Issued",
    },
    {
      uuid: uuidv4(),
      coupon: "CHRISTMAS2025",
      campaignId: "e2140bf5-7c34-4fdb-8c7b-33213b4fe074",
      couponType: "off-once",
      start_date: new Date("2023-12-01"),
      end_date: new Date("2023-12-31"),
      budget: 10000,
      limit: 500,
      couponStatus: CampaignCouponStatus[0].key,
      currency: "eur",
      receiptStatus: "Not Yet Issued",
    },
    {
      uuid: uuidv4(),
      coupon: "CHRISTMAS2026",
      campaignId: "e2140bf5-7c34-4fdb-8c7b-33213b4fe074",
      couponType: "off-once",
      start_date: new Date("2023-12-01"),
      end_date: new Date("2023-12-31"),
      budget: 10000,
      limit: 500,
      couponStatus: CampaignCouponStatus[1].key,
      currency: "eur",
      receiptStatus: "Not Yet Issued",
    },
  ]);
  const { t } = useTranslation("language", { keyPrefix: "coupons_manager" });
  const { auth } = useAuth();

  const { t: globalTrans } = useTranslation("language");
  const { t: errorTrans } = useTranslation("language", { keyPrefix: "errors" });
  useState(false);
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 20,
  });

  const [coupon, setCoupon] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isDownload, setIsDownLoad] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const fetchAreasData = async () => {
    setIsLoading(true);
    const [campaignsRes] = await Promise.all([
      CampaignManagerAPI.getAllCampaigns(),
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (
      campaignsRes &&
      campaignsRes.status === 200 &&
      campaignsRes.data.records?.length > 0
    ) {
      setCampaigns(
        _.sortBy(campaignsRes.data.records, (item) =>
          item.name?.de?.toLowerCase()
        )
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAreasData();
  }, []);

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    setIsDownLoad(false);
    if (coupon) {
      setCoupon(null);
    }
  };

  const toggleCouponStatus = (coupon: any, status: any) => {
    let newCoupons = [...coupons];
    newCoupons.forEach((item: any) => {
      if (item.coupon === coupon.coupon) {
        item.couponStatus = status;
      }
    });
    setCoupons(newCoupons);
  };

  const addCoupon = (newCoupon: any) => {
    setCoupons([...coupons, { ...newCoupon, uuid: uuidv4() }]);
  };

  const editCoupon = (editedCoupon: any) => {
    const updatedCoupons = coupons.map((coupon) =>
      coupon.uuid === editedCoupon.uuid ? editedCoupon : coupon
    );
    setCoupons(updatedCoupons);
    setCoupon(null);
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t("txt_edit"),
        icon: "wi-edit",
        command: (e: any) => {
          setCoupon(rowData);
          setIsShowSidebar(true);
        },
      },
      {
        label: t("txt_download_receipt"),
        icon: "wi-download",
        command: (e: any) => {
          setIsDownLoad(true);
          setIsShowSidebar(true);
        },
      },
      {
        label: "Activate",
        icon: "wi-active",
        command: (e: any) => {
          toggleCouponStatus(rowData, "active");
        },
      },
      {
        label: "Deactivate",
        icon: "wi-inactive",
        command: (e: any) => {
          toggleCouponStatus(rowData, "inactive");
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const buildCampaignCouponStatusColumnTemplate = (
    rowData: any,
    column: any
  ) => {
    const status = _.isString(rowData[column.field])
      ? rowData[column.field]
      : rowData[column.field]
      ? "active"
      : "inactive";
    const statusItem = STATUS_LIST.find((item: any) => item.code === status);
    return <WiStatus item={statusItem}></WiStatus>;
  };

  const tableColumns = [
    { field: "coupon", header: t("txt_coupon"), width: "15%", align: "left" },
    {
      field: "campaignId",
      header: t("txt_campaign"),
      width: "15%",
      align: "left",
      bodyTemplate: (rowData: any) => {
        const campaign = campaigns.find(
          (c: any) => c.uuid === rowData.campaignId
        );

        return (
          <Link
            className="table-ref-link-cell"
            to={`/campaigns/${rowData.campaignId}`}
          >
            {campaign?.name?.de}
          </Link>
        );
      },
    },
    {
      field: "couponType",
      header: t("txt_type"),
      width: "10%",
      align: "left",
      bodyTemplate: (rowData: any) => {
        const couponType = TypeCoupons[0];
        if (!couponType) return rowData.couponType;
        return couponType.name;
      },
    },

    {
      field: "couponStatus",
      header: `${t("txt_status")}`,
      width: "10%",
      align: "center",
      bodyTemplate: buildCampaignCouponStatusColumnTemplate,
    },
    {
      field: "start_date",
      header: t("txt_start_date"),
      width: "10%",
      align: "left",
      bodyTemplate: formatDateByFormatBodyTemplate("DD.MM.YYYY"),
    },
    {
      field: "end_date",
      header: t("txt_end_date"),
      width: "10%",
      align: "left",
      bodyTemplate: formatDateByFormatBodyTemplate("DD.MM.YYYY"),
    },
    {
      field: "budget",
      header: t("txt_total_budget"),
      width: "15%",
      align: "left",
      bodyTemplate: (rowData: any) => rowData.budget.toLocaleString("de-DE"),
    },
    {
      field: "limit",
      header: t("txt_limit"),
      width: "10%",
      align: "left",
      bodyTemplate: (rowData: any) => rowData.limit.toLocaleString("de-DE"),
    },
    {
      field: "currency",
      header: t("txt_currency"),
      width: "10%",
      align: "left",
    },
    {
      field: "receiptStatus",
      header: t("txt_receipt_status"),
      width: "10%",
      align: "left",
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  return (
    <Layout title={t("txt_title")}>
      <Toast ref={toast} />
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            {/* <WISearchField
                  icon={"pi pi-search"}
                  placeholder={t("txt_search")}
                  setSearch={(value: any) => setSearch(value.global.value)}
                  enterSearch={onSearchHistory}
                /> */}
            {/* <div className="filter-btn">
                  <div className="filter-btn-content p-10 ml-16">
                    <FilterSvg />
                  </div>
                </div> */}
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t("txt_btn_add")}
            // hidden={!permissions.canCreatePartner}
            onClick={(e: any) => {
              setCoupon(null);
              setIsShowSidebar(true);
            }}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={coupons}
          emptyMessage={t("txt_no_records")}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column
            frozen
            alignFrozen="right"
            style={{ width: "64px" }}
            body={buildMenu}
          ></Column>
        </DataTable>
        {/* <WIPaginator
              first={first}
              rows={rows}
              totalRecords={partners.totalPage}
              onPageChange={onBasicPageChange}
            /> */}
      </div>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: "600px" }}
        onHide={() => onHideSideBar()}
      >
        <div className="sidebar-content">
          {isDownload ? (
            <>
              <div className="headline pt-24 pb-24">
                <h6 className="m-0">Download Receipt</h6>
              </div>
              <DownloadReceiptForm
                campaigns={campaigns}
                toast={toast}
                onHide={() => onHideSideBar()}
                coupon={coupon}
                addCoupon={addCoupon}
                editCoupon={editCoupon}
                coupons={coupons}
              />
            </>
          ) : (
            <>
              <div className="headline pt-24 pb-24">
                <h6 className="m-0">
                  {coupon ? "Update coupon" : "Add a new coupon"}
                </h6>
              </div>
              <CreateCouponForm
                campaigns={campaigns}
                toast={toast}
                onHide={() => onHideSideBar()}
                coupon={coupon}
                addCoupon={addCoupon}
                editCoupon={editCoupon}
                coupons={coupons}
              />
            </>
          )}
        </div>
      </Sidebar>
    </Layout>
  );
};

export default CouponManager;
