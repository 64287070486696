import { Tree } from "primereact/tree";
import "./directory.scss";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MediaFolderManagerAPI } from "../../../../../services";
import WIConfirmDialog from "../../../../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog";
import CreateDirectoryForm from "../create-directory/create-diretory";
import { ReactComponent as FolderSvg } from '../../../../../assets/images/icons/folder.svg';
import { useTranslation } from "react-i18next";
import { ReactComponent as WarningSvg } from '../../../../../assets/images/icons/warning-symbol.svg';
import { useLayoutV2 } from "../../../../../context/LayoutProvider";
import { Sidebar } from "primereact/sidebar";

const Directory = forwardRef((props: any, ref) => {
  const { fetchMediaFolder, updateSelectedFolder, data, refresh, isAllNodes } = props;
  const [expandedKeys, setExpandedKeys] = useState({});
  const allNodes = data.nodes;
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>();
  const [directory, setDirectory] = useState<any>(null);
  const [isShowCreateDirectory, setIsShowCreateDirectory] = useState(false)
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'media_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();

  useImperativeHandle(ref, () => {
    return {
      expandAll,
      collapseAll,
      addFolder,
      deleteFolder
    }
  })

  const expandAll = () => {
    let _expandedKeys = {};
    for (let node of data.nodes) {
      expandedNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandedNode = (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandedNode(child, _expandedKeys);
      }
    }
  };

  const collapseAll = () => {
    setExpandedKeys({});
  };

  const onNodeSelect = (nodeSelect: any) => {
    setSelectedNode(nodeSelect);
    updateSelectedFolder(nodeSelect);
    refresh();
  };

  const onNodeUnselect = (node: any) => {
  };

  const addFolder = () => {
    setDirectory(null);
    setIsShowCreateDirectory(true);
  };

  const deleteFolder = () => {
    if (selectedNode?.node.data === "Root" || selectedNode?.node.data === "Public Media" || !selectedNode) {
      return;
    }
    setIsShowDeleteDialog(true);
    setDirectory(selectedNode);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setDirectory(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteConfirm = async () => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      const response = await MediaFolderManagerAPI.deleteMediaFolderV2(directory.node.uuid);
      setSelectedNode(null);
      if (response && response.status === 200 && response.data.result) {
        updateSelectedFolder(null);
        onShowDeleteDialog(false);
        fetchMediaFolder();
        refresh();
        setSelectedNodeKey("root");
        setSuccessProgress(t('txt_delete_folder_success'));
      } else {
        throw new Error('txt_delete_folder_fail');
      }
    } catch (err: any) {
      setErrorProgress(errorTrans(err.message || 'txt_delete_folder_fail'));
    }
  };

  const nodeTemplate = (node: any, options: any) => {
    let label = <>{node.label}</>;
    if (node.data === "Root") label = <b>{label}</b>

    return (
      <span className={`${options.className}`} >
        <FolderSvg className="icon-svg mr-8"></FolderSvg> <span>{label}</span>
      </span>
    );
  };

  useEffect(() => {
    if (!selectedNodeKey) {
      let expandedKey: any = { ...expandedKeys };

      if (expandedKey["root"]) delete expandedKey["root"];
      else expandedKey["root"] = true;

      setExpandedKeys(expandedKey);
      setSelectedNodeKey("root");
      setSelectedNode(allNodes);
    }
  }, [selectedNodeKey]);

  return (
    <>
      <div className="directory-container">
        {/* <div className="tree-panel-header mb-1 mt-1">
          <i
            title="Expand Folder"
            className="fa-solid fa-square-plus mr-4"
            style={{ fontSize: "1.2em" }}
            onClick={expandAll}
          />
          <i
            title="Collapse Folder"
            className="fa-solid fa-square-minus mr-4"
            style={{ fontSize: "1.2em" }}
            onClick={collapseAll}
          />
          <i
            title="Add Folder"
            className="fa-solid fa-folder-plus mr-4"
            style={{ fontSize: "1.2em", visibility: isAddFolderVisibility ? "visible" : "collapse" }}
            onClick={(e: any) => addFolder(e)}
          />
          <i
            title="Delete Folder"
            className="fa-solid fa-trash"
            style={{ fontSize: "1.2em", visibility: isDeleteFolderVisibility ? "visible" : "collapse" }}
            onClick={(e: any) => deleteFolder(e)}
          />
        </div> */}
        <Tree
          value={allNodes}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          selectionMode="single"
          selectionKeys={selectedNodeKey}
          onSelectionChange={(e) => {
            setSelectedNodeKey(e.value);
          }}
          onSelect={onNodeSelect}
          onUnselect={onNodeUnselect}
          nodeTemplate={nodeTemplate}
        />

        <WIConfirmDialog
          maskClassName="top-mask-dialog"
          visible={isShowDeleteDialog}
          onHide={() => onShowDeleteDialog(false)}
          onConfirm={onDeleteConfirm}
          confirmLabel={t('txt_confirm')}
          hideLabel={t('txt_cancel')}
          message={
            <>
              <div className="text-center mb-16"><b>{t('txt_delete_folder_item')}</b></div>
              <div className="text-center"> {t('txt_delete_folder_description')} </div>
            </>
          }
          icon={<WarningSvg className="icon-svg"></WarningSvg>}
        />
        <Sidebar
          className="wi-sidebar-v2 p-sidebar-md sidebar-right"
          style={{ width: '600px' }}
          position="right"
          visible={isShowCreateDirectory}
          onHide={() => setIsShowCreateDirectory(false)}
        >
          <div className="sidebar-content h-100 d-flex flex-column">
            <div className="headline pt-24 pb-32">
              <h6>{t('txt_upload_folder')}</h6>
            </div>
            <CreateDirectoryForm
              onHide={() => setIsShowCreateDirectory(false)}
              fetchMediaFolder={fetchMediaFolder}
              isAllNodes={isAllNodes}
              data={{
                allNodes,
                directory,
                selectedNode,
                expandedKeys,
              }}
            />
          </div>
        </Sidebar>
      </div>
    </>
  );
});

export default Directory;
