import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  WIFormDropdownV2,
  WIFormInputTextV2,
} from "../../../../components_v2/common/form-fields";
import {
  CampaignCouponStatus,
  CurrencyCoupons,
  TypeCoupons,
} from "../../../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import _ from "lodash";
import { Calendar } from "primereact/calendar";

const DownloadReceiptForm = (props: any) => {
  const { onHide, coupon } = props;
  const formikRef = useRef<any>(null);
  const { t } = useTranslation("language", { keyPrefix: "partner_details" });
  const onSubmit = async () => {};

  const COUPON_DEFAULT_VALUE = {
    uuid: uuidv4(),
    coupon: "",
    campaignId: "",
    couponType: TypeCoupons[0].code,
    receiptStatus: "",
    couponStatus: CampaignCouponStatus[0].key,
    start_date: moment().isValid() ? moment().toDate() : new Date(),
    end_date: moment().isValid() ? moment().toDate() : new Date(),
    currency: CurrencyCoupons[0].code,
    budget: 0,
    limit: 0,
  };

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required("This field is required."),
    campaignId: Yup.string().required("This field is required."),
    currency: Yup.string().required("This field is required."),
    couponStatus: Yup.string().required("This field is required."),
    limit: Yup.string().required("This field is required."),
    budget: Yup.string().required("This field is required."),
    start_date: Yup.date().required("This field is required."),
    end_date: Yup.date()
      .required("This field is required.")
      .min(Yup.ref("start_date"), "End date must be later than start date"),
  });

  const initialFormValues = () => {
    let initialCoupon = COUPON_DEFAULT_VALUE;
    if (coupon) {
      initialCoupon = _.pick(coupon, [
        "uuid",
        "coupon",
        "campaignId",
        "couponType",
        "receiptStatus",
        "couponStatus",
        "currency",
        "budget",
        "limit",
        "start_date",
        "end_date",
      ]);
      initialCoupon.start_date = moment(coupon.start_date).toDate();
      initialCoupon.end_date = moment(coupon.end_date).toDate();
    }

    return initialCoupon;
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={initialFormValues()}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        //console.log("values >>>", values);
      }}
    >
      {({ values, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          <div className="row-content">
            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2
                  title="Name"
                  name="coupon"
                  isRequired={true}
                  onChange={(e: any) => {
                    const regex = /^[a-zA-Z0-9]*$/;
                    if (regex.test(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormDropdownV2
                  name="campaignId"
                  title="Country"
                  isRequired={true}
                  optionLabel="name.de"
                  optionValue="uuid"
                  appendTo="self"
                  //options={campaigns}
                />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2
                  title="City"
                  name="budget"
                  isRequired={true}
                />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2
                  title="Address"
                  name="limit"
                  isRequired={true}
                />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2
                  title="Postal code"
                  name="limit"
                  isRequired={true}
                />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-6">
                <div className="element-form">
                  <label className="label mb-12">
                    Donation date <span className="required-label ml-4">*</span>
                  </label>
                  <Calendar
                    id="basic"
                    name="start_date"
                    panelClassName="expire-calendar"
                    value={values.start_date}
                    onBlur={handleBlur}
                    onChange={(item) => {
                      setFieldValue("start_date", item.value, false);
                    }}
                    dateFormat="dd.mm.yy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="element-form">
                  <label className="label mb-12">
                    Receipt date <span className="required-label ml-4">*</span>
                  </label>
                  <Calendar
                    id="basic"
                    name="end_date"
                    panelClassName="expire-calendar"
                    value={values.end_date}
                    onBlur={handleBlur}
                    onChange={(item) => {
                      setFieldValue("end_date", item.value, false);
                    }}
                    dateFormat="dd.mm.yy"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <Button
                    className="wi-danger-button-v2 mr-16 h48 flex-1"
                    type="submit"
                    label={`${t("txt_cancel")}`}
                    onClick={() => onHide()}
                  />
                  <Button
                    className="wi-primary-button-v2 ml-16 h48 flex-1"
                    type="submit"
                    label={`${t("txt_continue")}`}
                    onClick={() => onSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default DownloadReceiptForm;
