import * as React from "react";
import { useEffect, useState } from "react";
import "./subscription-filter-component.scss";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import CalendarFilterField from "../../../components_v2/common/calendar-filter-field/calendar-filter-field";
import FilterField from "../../../components_v2/common/filter-field/filter-field";
import { SUBSCRIPTION_RHYTHM_LIST, SUBSCRIPTION_STATUS_CODE, SUBSCRIPTION_STATUS_LIST } from "../../../components_v2/utils/utils";

const SubscriptionFilterComponent = (props: any) => {
  const { fields, filterValues, onRemove, onChange, setSearch, onSubmit } = props;
  const [values, setValues] = useState<Map<string, string[]>>();
  const [isDirty, setDirty] = useState<boolean>(false);
  const { t } = useTranslation('language', { keyPrefix: 'subscription_manager' });

  const getFields = () => {
    const resetValues = new Map<string, string[]>();
    fields.forEach((item: string) => {
      resetValues.set(item, []);
    });
    setValues(resetValues);
  };

  useEffect(() => {
    getFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      if (k === "collection_date" && filterValues.where.from_collection_date && filterValues.where.to_collection_date) {
        filter.set(k, [filterValues.where.from_collection_date, filterValues.where.to_collection_date]);
      }
      else if (k === "start_date" && filterValues.where.from_start_date && filterValues.where.to_start_date) {
        filter.set(k, [filterValues.where.from_start_date, filterValues.where.to_start_date]);
      }
      else if (k === "finished_date" && filterValues.where.from_finished_date && filterValues.where.to_finished_date) {
        filter.set(k, [filterValues.where.from_finished_date, filterValues.where.to_finished_date]);
      }
      else {
        filter.set(k, filterValues.where[k]);
      }
    });
    setValues(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch("");
    await onRemove();
  };

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
  };

  return (
    <div className="filter-wrapper-v2">
      <div className="filter-form-container">
        <div className="filter-elements mb-16">
          <FilterField
            name="rhythm"
            label={t('txt_rhythm')}
            className="wi-filter-option-v2"
            pannelClassName="overlaypanel-filter-v2"
            selectedItems={values?.get("rhythm") || []}
            options={SUBSCRIPTION_RHYTHM_LIST}
            optionLabel={"name"}
            optionValue={"code"}
            visible={values?.has("rhythm") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="filter-elements mb-16">
          <FilterField
            name="status"
            label={t('txt_status')}
            className="wi-filter-option-v2"
            pannelClassName="overlaypanel-filter-v2"
            selectedItems={values?.get("status") || []}
            options={SUBSCRIPTION_STATUS_LIST.filter(c => c.code !== SUBSCRIPTION_STATUS_CODE.PENDING)}
            optionLabel={"label"}
            optionValue={"code"}
            visible={values?.has("status") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="filter-elements mb-16">
          <CalendarFilterField
            name="collection_date"
            label={t('txt_collection_date')}
            pannelClassName="top-mask-dialog"
            selectedItems={values?.get("collection_date") || []}
            visible={values?.has("collection_date") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="filter-elements mb-16">
          <CalendarFilterField
            name="start_date"
            label={t('txt_start_date')}
            pannelClassName="top-mask-dialog"
            selectedItems={values?.get("start_date") || []}
            visible={values?.has("start_date") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="filter-elements mb-16">
          <CalendarFilterField
            name="finished_date"
            label={t('txt_finished_date')}
            pannelClassName="top-mask-dialog"
            selectedItems={values?.get("finished_date") || []}
            visible={values?.has("finished_date") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-start">
                <Button
                    className="wi-danger-button-v2 mr-16 h48 flex-1"
                    type="submit"
                    label={`${t('txt_clear_filter')}`}
                    onClick={() => onRemoveFilters()}
                />
                <Button
                    className="wi-primary-button-v2 ml-16 h48 flex-1"
                    type="submit"
                    label={`${t('txt_apply')}`}
                    onClick={() => onSubmit(values, fields)}
                />
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default SubscriptionFilterComponent;
